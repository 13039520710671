export const validateE164Format = (phoneNumber) => !phoneNumber.match(/^\+[1-9]\d{1,14}$/);

export const regexpEscaping = (string) => {
  const specialCharacters = '[]{}()\\^$.|?*+';
  const fixedSearchTerm = string.split('').map((char) => {
    if (specialCharacters.includes(char)) {
      return `\\${char}`;
    }
    return char;
  });

  return fixedSearchTerm.join('');
};

export const regexPhoneNumbers = (phone) => !!phone.match(/^\+33[1-9 ][0-9 ]{1,60}$/);

export const regexIntuneDeviceName = (deviceName) =>
  !!deviceName.match(/^(?=.*[a-zA-Z-])[a-zA-Z\d-]{1,15}$/);

export const regexAddress = (officeLocation) =>
  !!officeLocation.match(/^[#.'0-9a-zA-Z\s,-]{1,64}$/);

export const regexName = (name) =>
  !!name.match(
    /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð '-]{1,64}$/
  );

export const regexEmail = (email) => {
  const test = !!email.match(
    /^[A-Za-z0-9'_!#^~-]+([A-Za-z0-9'._!#^~-]*[A-Za-z0-9'_!#^~-]+)*@([A-Za-z0-9]+\.)+[A-Za-z]+$/
  );
  return test;
};

export const regexUserPrincipalName = (email) => {
  if (!regexEmail(email) || email.length > 64) {
    return false;
  }
  return true;
};

export const regexPassword = (password, userName) =>
  !!password.match(
    /^(?=.*\d)(?=.*[a-zA-Z])(?=.*[@#$%^&*\-_!+=[\]{}|\\:’,.?/`~"();<> ])[a-zA-Z\d@#$%^&*\-_!+=[\]{}|\\:’,.?/`~"();<> ]{8,24}$/
  ) && !password.includes(userName);

export const regexUserMail = (mail) => {
  if (mail.length > 64 || !regexEmail(mail)) {
    return false;
  }

  return true;
};

export const regexList = (item, list) => {
  const regexp = new RegExp(`^${item}$`);

  return list.some((element) => element.match(regexp));
};

export const azureColumnsRules = {
  businessPhones: {
    rule: regexPhoneNumbers,
    ruleMessageKey: 'validateNumbers'
  },
  displayName: {
    rule: regexName,
    ruleMessageKey: 'validateName'
  },
  givenName: {
    rule: regexName,
    ruleMessageKey: 'validateName'
  },
  jobTitle: {
    rule: regexName,
    ruleMessageKey: 'validateName'
  },
  mail: { rule: regexUserMail, ruleMessageKey: 'validateMail' },
  mailNickname: {
    rule: regexName,
    ruleMessageKey: 'validateName'
  },
  password: {
    rule: regexPassword,
    ruleMessageKey: 'validatePassword'
  },
  mobilePhone: {
    rule: regexPhoneNumbers,
    ruleMessageKey: 'validateNumbers'
  },
  officeLocation: {
    rule: regexAddress,
    ruleMessageKey: 'validateAddress'
  },
  surname: { rule: regexName, ruleMessageKey: 'validateName' },
  userPrincipalName: {
    rule: regexUserPrincipalName,
    ruleMessageKey: 'validateUserPrincipalName'
  },
  preferredLanguage: {
    rule: regexList,
    ruleMessageKey: 'validateLanguage'
  },
  domain: {
    rule: regexList,
    ruleMessageKey: 'validateDomain'
  },
  department: {
    rule: regexList,
    ruleMessageKey: 'validateService'
  },
  manager: {
    rule: regexList,
    ruleMessageKey: 'validateManager'
  },
  usageLocation: {
    rule: regexList,
    ruleMessageKey: 'validateUsageLocation'
  }
};
