import { keyframes } from '@emotion/react';
import { styled } from '@mui/material/styles';
import { TablePagination } from '@mui/material';
import MUIDataTable from 'mui-datatables';

export const stylePageWrapper = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  contain: 'inline-size'
};

export const StyledMUIDataTable = styled(MUIDataTable)({
  '& .tss-5jmwmz-MUIDataTableFooter-root': {
    display: 'none'
  },
  '& .tss-1vsygk-MUIDataTableFilterList-root': {
    display: 'none'
  }
});

export const styleGridUsers = {
  display: { xs: 'flex' },
  flexDirection: 'column',
  alignItems: 'center',
  width: '100%',
  '@media (min-width:900px)': {
    maxWidth: '100%'
  },
  paddingLeft: '2%',
  paddingRight: '2%'
};
export const stylePointer = {
  cursor: 'pointer'
};

export const styleMarginBottom = {
  marginBottom: '15px'
};

export const styleTypographyUsers = (theme) => ({
  fontSize: '25px',
  borderRadius: '5px',
  marginLeft: '8px',
  height: '25px',
  color: theme.palette.colorMainGradient
});

export const styleTypographyBigger = (theme) => ({
  fontSize: '35px',
  borderRadius: '5px',
  marginLeft: '8px',
  height: '20px',
  weight: 'bold',
  color: theme.palette.colorMainGradient
});

export const StyledTablePagination = styled(TablePagination)(({ theme }) => ({
  [`&.${'test'}`]: {
    [`& .MuiToolbar-root`]: {
      display: 'grid',
      gridTemplateColumns: 'auto auto auto 1fr auto',
      gridTemplateRows: 'auto',
      alignItems: 'center'
    },
    [`& .MuiButtonBase-root`]: {
      fontSize: '3rem',
      borderRadius: '12px',
      border: `1px solid ${theme.palette.colorSecondaryLight}`,
      backgroundColor: theme.palette.colorSecondaryLight,
      padding: '8px'
    },
    [`& .MuiTablePagination-selectLabel`]: {
      gridColumn: '1'
    },

    [`& .MuiTablePagination-select`]: {
      gridColumn: '2',
      gridColumnGap: '3px'
    },
    [`& .MuiTablePagination-displayedRows`]: {
      gridColumn: '3'
    },

    [`& .MuiTablePagination-actions`]: {
      gridColumn: '5'
    }
  }
}));

export const styleTypographyMobileUsers = (theme) => ({
  fontSize: '17px',
  marginLeft: '10px',
  marginTop: '10px',
  color: theme.palette.colorMainGradient
});

export const styleTypographyMobileTitleUsers = (theme) => ({
  fontSize: '30px',
  position: 'relative',
  top: '15px',
  left: '10px',
  borderRadius: '5px',
  marginLeft: '8px',
  height: '40px',
  color: theme.palette.colorMainGradient
});

export const styleTypographyMobileUnderTitleUsers = (theme) => ({
  fontSize: '13px',
  position: 'relative',
  top: '5px',
  borderRadius: '5px',
  marginLeft: '8px',
  height: '40px',
  color: theme.palette.colorSoftBlack
});

export const styleTypographyButtonsType = {
  fontSize: '13px',
  position: 'relative',
  color: '#666666'
};

export const styleDivider = {
  marginLeft: '10px',
  marginRight: '10px'
};

export const styleTypographyNameItemListMobileUsers = (theme) => ({
  fontSize: '17px',
  position: 'relative',
  marginTop: '13px',
  marginBottom: '13px',
  alignItem: 'center',
  gridColumn: '2',
  color: theme.palette.colorMainGradient
});

export const styleTypographyAccountItemListMobileUsers = (theme) => ({
  fontSize: '17px',
  position: 'relative',
  marginTop: '13px',
  left: '15px',
  marginBottom: '13px',
  alignItem: 'center',
  color: theme.palette.colorSoftBlack,
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  width: '80%'
});

export const styleTypographyMoreInfoLinkItemListMobileUsers = {
  position: 'relative',
  right: '10px',
  gridColumn: '4'
};

export const styleTypographyMoreInfoItemListMobileUsers = (theme) => ({
  fontSize: '17px',
  position: 'relative',
  marginTop: '13px',
  marginBottom: '13px',
  right: '10px',
  marginLeft: '8px',
  color: theme.palette.colorMainSelected
});

export const styleTypographyTableButtonMobileUsers = (theme) => ({
  cursor: 'pointer',
  fontSize: '18px',
  textDecoration: 'underline',
  position: 'relative',
  top: '15px',
  left: '10px',
  borderRadius: '5px',
  marginLeft: '8px',
  height: '40px',
  color: theme.palette.colorSoftBlack
});

export const styleTypographyTableMobileUsers = (theme) => ({
  fontSize: '17px',
  borderRadius: '5px',
  marginLeft: '8px',
  height: '40px',
  padding: '8px',
  position: 'relative',
  top: '10px',
  right: '10px',
  color: theme.palette.colorMainGradient
});

export const styleSearchContainerMobile = {
  paddingBottom: '2em',
  paddingTop: '2em',
  gridGap: '20px',
  marginLeft: '20px'
};

export const styleButtonContainerMobile = {
  display: 'flex',
  paddingBottom: '1em',
  paddingTop: '1em',
  gridGap: '20px',
  marginLeft: '20px'
};

export const styleActionIconMobileUsersAzure = (theme) => ({
  fontSize: '2rem',
  color: theme.palette.colorMain
});

export const styleActionIconMobileUsers = (theme) => ({
  fontSize: '2.5rem',
  color: theme.palette.colorMain
});

export const styleActionIconButtonMobileUsers = (theme) => ({
  fontSize: '3rem',
  borderRadius: '20px',
  border: `1px solid ${theme.palette.colorGray}`,
  backgroundColor: theme.palette.colorMainLight,
  padding: '8px'
});

export const styleActionIconButtonMobileUsersAzure = (theme) => ({
  fontSize: '2rem',
  borderRadius: '20px',
  border: `1px solid ${theme.palette.colorGray}`,
  backgroundColor: theme.palette.colorMainLight,
  padding: '8px',
  '&:disabled': {
    backgroundColor: theme.palette.colorGray
  }
});

export const styleActionBackIconButtonMobileUsers = (theme) => ({
  fontSize: '3rem',
  borderRadius: '20px',
  border: `1px solid ${theme.palette.colorGray}`,
  backgroundColor: theme.palette.colorMainLight,
  padding: '8px',
  position: 'relative',
  top: '10px',
  right: '10px'
});

export const styleActionRefreshIconButtonMobileUsers = (theme) => ({
  color: theme.palette.colorMain,
  borderRadius: '30px',
  border: `1px solid ${theme.palette.colorGray}`,
  backgroundColor: theme.palette.colorSecondaryLight,
  padding: '8px',
  position: 'fixed',
  zIndex: 1,
  bottom: '20px',
  right: '20px',
  '&:hover': {
    backgroundColor: theme.palette.colorSecondaryLight
  },
  '&:disabled': {
    backgroundColor: theme.palette.colorGray
  }
});

export const styleMobileDrawerUsers = (theme) => ({
  backgroundColor: theme.palette.colorSecondaryLight,
  width: {
    xs: '100%'
  },
  borderBottom: `1px solid ${theme.palette.colorGray}`,
  marginTop: '0px'
});

export const styleMobileListUsers = () => ({
  paddingTop: '10px',
  overflowY: 'auto',
  flex: '1',
  width: '100%',
  gridGap: '25px'
});

export const styleMobileItemListUsers = (theme) => ({
  borderRadius: '10px 10px 10px 10px',
  maxHeight: '200px',
  boxShadow: `0 3px 10px ${theme.palette.colorSecondaryLight}`,
  width: '100%'
});

export const styleMobileTopItemListUsers = (theme) => ({
  backgroundColor: theme.palette.colorSecondaryLight,
  width: '100%',
  borderRadius: '10px 10px 0% 0%',
  alignItems: 'center'
});

export const styleMobileBotItemListUsers = (theme) => ({
  display: 'flex',
  backgroundColor: theme.palette.colorMainLight,
  width: '100%',
  borderRadius: '0% 0% 10px 10px',
  justifyContent: 'space-between',
  alignItems: 'center'
});

export const styleMobilePaginationUsers = (theme) => ({
  width: {
    xs: '100%'
  },
  backgroundColor: theme.palette.colorMainLight
});

export const styleTableContainerUsers = {
  borderRadius: '4px',
  overflow: 'auto',
  width: '100%'
};

export const styleSelect = {
  width: '140px',
  '.MuiDialog-paperWidthSm': {
    height: '50%',
    padding: '8px',
    overflow: 'hidden'
  },
  th: {
    textAlign: 'center'
  }
};

export const styleRefreshButton = (theme) => ({
  marginLeft: 'auto',
  borderRadius: '10px',
  backgroundColor: theme.palette.colorMainSelected,
  '&:hover': {
    backgroundColor: theme.palette.colorMainSelected,
    transform: 'scale(1.05)'
  },
  '&.Mui-disabled': {
    backgroundColor: theme.palette.colorGray
  }
});

export const styleSearchBar = {
  padding: '15px',
  direction: 'row',
  justifyContent: { xs: 'center', sm: 'flex-start' },
  '@media (min-width:900px)': {
    maxWidth: '100%'
  },
  marginBottom: { xs: '15px', lg: '0px' }
};

export const styleSearchBarMobile = (theme) => ({
  padding: '10px 10px 10px 0px',
  backgroundColor: theme.palette.colorSecondaryLight,
  borderRadius: '20px',
  ml: '1',
  direction: 'row',
  '& .MuiInput-input': {
    marginLeft: '10px'
  },
  border: `1px solid ${theme.palette.colorSecondaryLight}`,
  justifyContent: { xs: 'center', sm: 'flex-start' },
  '@media (min-width:900px)': {
    maxWidth: '100%'
  }
});

export const styleAzureSearchBar = {
  flex: 1
};

export const styleSearchGrid = {
  alignItems: 'center',
  marginBottom: '10px'
};

export const styleSearchInfo = {
  margin: '10px',
  fontSize: '30px'
};

export const typographyPaginationDesktop = (theme) => ({
  color: theme.palette.colorSoftBlack,
  fontSize: '0.9rem',
  marginRight: '10px'
});

export const styleToolbar = (theme) => ({
  '& .MuiToolbar-root': {
    flexWrap: 'wrap'
  },
  [`& .MuiButtonBase-root`]: {
    fontSize: '0.8rem',
    borderRadius: '12px',
    border: `1px solid ${theme.palette.colorMainLight}`,
    color: theme.palette.colorBlack,
    backgroundColor: theme.palette.colorSecondaryLight,
    padding: '8px',
    '&:hover': {
      transform: 'scale(1.05)'
    },
    '&:disabled': {
      backgroundColor: theme.palette.colorGray,
      color: theme.palette.colorMainLight
    }
  },
  [`& .MuiTablePagination-selectLabel`]: {
    color: theme.palette.colorSoftBlack
  },
  [`& .MuiTablePagination-displayedRows`]: {
    color: theme.palette.colorSoftBlack
  },
  [`& .css-16c50h-MuiInputBase-root-MuiTablePagination-select`]: {
    marginRight: '10px'
  },
  color: theme.palette.colorBlack
});

export const styleToolbarMobile = (theme) => ({
  '& .MuiToolbar-root': {
    justifyContent: 'space-evenly',
    textAlign: 'center'
  },
  [`& .MuiButtonBase-root`]: {
    fontSize: '0.8rem',
    borderRadius: '12px',
    border: `1px solid ${theme.palette.colorMainLight}`,
    color: theme.palette.colorBlack,
    backgroundColor: theme.palette.colorSecondaryLight,
    padding: '8px',
    '&:hover': {
      transform: 'scale(1.05)'
    },
    '&:disabled': {
      backgroundColor: theme.palette.colorGray,
      color: theme.palette.colorMainLight
    }
  },
  [`& .MuiTablePagination-selectLabel`]: {
    color: theme.palette.colorSoftBlack
  },
  [`& .MuiTablePagination-displayedRows`]: {
    color: theme.palette.colorSoftBlack,
    overflow: 'auto'
  },
  [`& .css-16c50h-MuiInputBase-root-MuiTablePagination-select`]: {
    marginRight: '5px'
  },
  [`& .css-78c6dr-MuiToolbar-root-MuiTablePagination-toolbar .MuiTablePagination-actions`]: {
    marginLeft: '10px',
    marginRight: '5px'
  },
  color: theme.palette.colorBlack
});

export const styledPaginationDesktop = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-evenly'
};

export const styleChipStatus = (theme, successStatus) => ({
  position: 'relative',
  right: '10px',
  borderRadius: '10px',
  backgroundColor: successStatus ? theme.palette.successSecondary : theme.palette.errorSecondary,
  color: successStatus ? theme.palette.successMain : theme.palette.errorMain
});

export const styleChipColorStatus = (theme, rowValueName, classification) => ({
  position: 'relative',
  right: '10px',
  borderRadius: '10px',
  backgroundColor: classification[rowValueName]
    ? classification[rowValueName].backgroundColor
    : classification?.default?.backgroundColor,
  color: classification[rowValueName]
    ? classification[rowValueName].fontColor
    : classification?.default?.fontColor
});

export const styleChipSynchro = (theme, synchroStatus) => ({
  position: 'relative',
  right: '10px',
  borderRadius: '10px',
  backgroundColor:
    synchroStatus === 'synchronized' ? theme.palette.successSecondary : theme.palette.warnSecondary,
  color: synchroStatus === 'synchronized' ? theme.palette.successMain : theme.palette.warnMain
});

export const styleChipStatusGroup = (theme) => ({
  position: 'relative',
  right: '10px',
  borderRadius: '10px',
  backgroundColor: theme.palette.neutralSecondary,
  color: theme.palette.neutralMain
});

export const styleChipStatusGroupDescription = (theme) => ({
  position: 'relative',
  marginRight: '10px',
  left: '5px',
  marginBottom: '10px',
  borderRadius: '10px',
  backgroundColor: theme.palette.neutralSecondary,
  color: theme.palette.neutralMain
});

export const styleButtonMobilePagination = (theme) => ({
  '& .MuiButtonBase-root': {
    fontSize: '3rem',
    borderRadius: '20px',
    border: `2px solid ${theme.palette.colorGray}`,
    background: theme.palette.colorSecondaryLight,
    padding: '8px'
  }
});

const spin = keyframes`
  from, 0 to {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

export const styleRefreshButtonLoading = (theme) => ({
  animation: `${spin} 1s ease infinite`,
  backgroundColor: theme.palette.colorGray,
  color: theme.palette.colorMainLight,
  borderRadius: '50%'
});

export const styleActionIconMobileUsersLoading = (theme) => ({
  fontSize: '2.5rem',
  color: theme.palette.colorSoftBlack,
  animation: `${spin} 1s ease infinite`
});

export const styleColumnUsers = {
  maxWidth: '150px',
  minWidth: '150px',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis'
};

export const styleRowUserSelected = (theme) => ({
  '&:hover': {
    backgroundColor: theme.palette.colorMainLightHover
  },
  '&.Mui-selected': {
    backgroundColor: theme.palette.colorMainLightHover,
    '&:hover': {
      backgroundColor: theme.palette.colorMainLight
    }
  },
  '&:nth-of-type(even)': {
    // backgroundColor: theme.palette.colorTableRow.main,
    '&:hover': {
      backgroundColor: theme.palette.colorMainLightHover
    },
    '&.Mui-selected': {
      backgroundColor: theme.palette.colorMainLightHover,
      '&:hover': {
        // backgroundColor: theme.palette.colorTableRow.main
      }
    }
  },
  '&.Mui-checked': {
    color: theme.palette.colorMain
  }
});
export const styleCheckbox = (theme) => ({
  '&.Mui-checked': {
    color: theme.palette.colorMain
  }
});
export const styleTableHead = () => ({
  fontWeight: 'bold',
  textOverflow: 'ellipsis'
});

export const styleRefreshWrapper = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-end'
};

export const styleNameStyle = {
  textAlign: 'center'
};

export const styleInnerCell = {
  borderRightStyle: 'none !important',
  borderLeftStyle: 'none !important'
};

export const styleCell = {
  textAlign: 'center',
  borderRightStyle: 'none !important',
  borderLeftStyle: 'none !important'
};

export const styleInnerRow = {
  display: 'grid',
  marginLeft: 'auto',
  marginRight: 'auto'
};

export const styleRefreshTypo = (theme) => ({
  marginLeft: '10px',
  color: theme.palette.colorMainLight
});

export const styleJiraSearchfield = (theme) => ({
  '&:after ': {
    borderBottom: `2px solid ${theme.palette.colorMain}`
  }
});

export const styleJiraRefreshButton = (theme, isLoading) => ({
  color: isLoading ? theme.palette.colorSoftBlack : theme.palette.colorMain,
  animation: isLoading ? `${spin} 1s ease infinite` : 'none'
});

export const styleTableSearchInput = (theme) => ({
  '&:after ': {
    borderBottom: `2px solid ${theme.palette.colorMain}`
  }
});

export const styleLoadingIcon = (theme) => ({
  color: theme.palette.colorMain
});

export const styleFormControl = (theme) => ({
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: theme.palette.colorGray,
      borderRadius: '10px'
    },
    '&:hover fieldset': {
      borderColor: theme.palette.colorMainSelected,
      borderRadius: '10px'
    },
    '&.Mui-focused fieldset': {
      borderColor: theme.palette.colorMainSelected,
      borderRadius: '10px'
    },
    '& .MuiOutlinedInput-input.MuiSelect-select': {
      overflow: 'visible'
    }
  },
  '& .css-efymfo-MuiFormControl-root': {
    paddingRight: '10px'
  }
});
