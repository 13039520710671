const styleButton = (theme) => ({
  justifyContent: 'center',
  display: 'flex',
  alignItems: 'center',
  paddingTop: '18px',
  paddingBottom: '18px',
  textTransform: 'none',
  borderRadius: '10px',
  fontWeight: '550',
  textDecoration: 'none',
  width: { xs: '160px', sm: '130px', md: '170px' },
  height: '42px',
  marginTop: '5px',
  marginRight: '5px',
  marginLeft: '5px',
  color: theme.palette.colorMainLight,
  backgroundColor: theme.palette.colorMainSelected,
  transition: 'all 0.5s',
  '&:hover': {
    background: theme.palette.colorMainSelected,
    transform: 'scale(0.95, 0.95)'
  },
  '&:focus': {
    outline: 'none'
  },
  '&:disabled': {
    background: theme.palette.colorMainLight,
    border: `1px solid ${theme.palette.colorGray}`,
    color: theme.palette.colorSecondaryLight
  }
});

export default styleButton;
