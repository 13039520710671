import React from 'react';
import { useParams } from 'react-router-dom';
import Login from '../views/pages/Login/Login';
import HomePage from '../views/pages/HomePage';
import ListUser from '../views/pages/Users/ListUser';
import SMS from '../views/pages/SMS';
import Settings from '../views/pages/Settings';
import Mail from '../views/pages/Mails';
import AzureUsers from '../views/pages/Users/AzureUsers';
import User from '../views/pages/Users/User';
import LoginBoard from '../views/pages/Login/LoginBoard';
import AzureLicense from '../views/pages/Licenses/AzureLicense';
import AzureLicenses from '../views/pages/Licenses/AzureLicenses';
import LicenseNotif from '../views/pages/Licenses/LicenseNotif';
import DevicesJira from '../views/pages/Devices/DevicesJira';
import DeviceJira from '../views/pages/Devices/DeviceJira';
import JiraUser from '../views/pages/Users/JiraUser';
import DeviceIntune from '../views/pages/Devices/DeviceIntune';
import DevicesIntune from '../views/pages/Devices/DevicesIntune';
import JiraUsers from '../views/pages/Users/JiraUsers';
import Profile from '../views/pages/Profile';
import Error from '../views/pages/Error/Error';
import AdUsers from '../views/pages/Users/AdUsers2';
import AdUser from '../views/pages/Users/AdUser';
import SynchroADAzureDetails from '../views/pages/Users/SynchroADAzureDetail';
import SynchroAdAzure from '../views/pages/Users/SynchroADAzure';
import Providers from '../views/pages/BackOffice/Providers/Providers';
import AzureGroups from '../views/pages/BackOffice/AzureGroups/AzureGroups';
import AllAppList from '../views/pages/BackOffice/AppList/AppList';
import AuthGroups from '../views/pages/BackOffice/AuthGroups/AuthGroups';
import ActionsHistory from '../views/pages/ActionsHistory';
import PermissionInHistory from '../views/pages/BackOffice/History/History';
import BoondManagerResource from '../views/pages/Users/BoondManagerResource';
import BoondManagerResources from '../views/pages/Users/BoondManagerResources';
import ActionsDetails from '../views/pages/ActionsDetails';
import Parameters from '../views/pages/BackOffice/Parameters/Parameters';

const isLoginBoardDisplayed = process.env.REACT_APP_IS_LOGINBOARD_DISPLAYED === 'true';
const appListPublicRoutes = [
  {
    path: '/',
    label: 'login',
    component: isLoginBoardDisplayed ? LoginBoard : Login
  },
  {
    path: '/login',
    label: 'login',
    component: Login
  },
  {
    path: '/admin',
    label: 'admin',
    component: Login
  },
  {
    path: '/backOffice',
    label: 'backOffice',
    component: Login
  }
];

const privateRoutes = [
  {
    path: '/',
    label: 'home',
    component: HomePage
  },
  {
    path: '/home',
    label: 'home',
    component: HomePage
  },
  {
    path: '/settings',
    label: 'settings',
    component: Settings
  },
  {
    path: '/profile',
    label: 'profile',
    component: Profile
  },
  {
    path: '/licenseNotif',
    label: 'licenseNotif',
    component: LicenseNotif
  },
  {
    path: '/mails',
    label: 'mails',
    component: Mail
  },
  {
    path: '/azureUsers',
    label: 'azureUsers',
    component: AzureUsers
  },
  {
    path: '/jiraUsers',
    label: 'jiraUsers',
    component: JiraUsers
  },
  {
    path: '/jiraUsers/:key',
    label: 'jiraUser',
    component: JiraUser
  },
  {
    path: '/users',
    label: 'users',
    component: ListUser
  },
  {
    path: '/azureUsers/:id',
    label: 'user',
    component: User
  },
  {
    path: '/adUsers',
    label: 'adUsers',
    component: AdUsers
  },
  {
    path: '/synchroAdAzure',
    label: 'synchroAdAzure',
    component: SynchroAdAzure
  },
  {
    path: '/synchroAdAzure/:DNAzureId',
    label: 'synchroAdAzure',
    component: SynchroADAzureDetails
  },
  {
    path: '/adUsers/:DN',
    label: 'adUser',
    component: function ADUserFromDn() {
      const { DN } = useParams();
      return <AdUser DN={DN} />;
    }
  },
  {
    path: '/azureLicenses',
    label: 'azureLicenses',
    component: AzureLicenses
  },
  {
    path: '/azureLicenses/:id',
    label: 'AzureLicense',
    component: AzureLicense
  },
  {
    path: '/boondManagerResources',
    label: 'boondManagerResources',
    component: BoondManagerResources
  },
  {
    path: '/boondManagerResources/:id',
    label: 'boondManagerResource',
    component: BoondManagerResource
  },
  {
    path: '/devicesJira',
    label: 'devicesJira',
    component: DevicesJira
  },
  {
    path: '/devicesJira/:id',
    label: 'deviceJira',
    component: DeviceJira
  },
  {
    path: '/devicesIntune',
    label: 'devicesIntune',
    component: DevicesIntune
  },
  {
    path: '/devicesIntune/:id',
    label: 'deviceIntune',
    component: DeviceIntune
  },
  {
    path: '/sms',
    label: 'sms',
    component: SMS
  },
  {
    path: '/actionsHistory',
    label: 'actionsHistory',
    component: ActionsHistory
  },
  {
    path: '/actionsHistory/:idDetails',
    label: 'actionsDetails',
    component: ActionsDetails
  }
];

const rootRoutes = [
  {
    path: '/providers',
    label: 'providers',
    component: Providers
  },
  {
    path: '/azureGroups',
    label: 'azureGroup',
    component: AzureGroups
  },
  {
    path: '/appList',
    label: 'appList',
    component: AllAppList
  },
  {
    path: '/AuthGroups',
    label: 'authGroups',
    component: AuthGroups
  },
  {
    path: '/History',
    label: 'history',
    component: PermissionInHistory
  },
  {
    path: '/Parameters',
    label: 'parameters',
    component: Parameters
  }
];

const AppList = {
  errors: {
    path: '/',
    header: 'SYNERYX',
    thirdLink: '',
    errorRoutes: [
      {
        path: '*',
        component: Error
      },
      {
        path: '/error/:id',
        label: 'error',
        component: Error
      }
    ]
  },
  public: {
    path: '/',
    header: 'SYNERYX',
    thirdLink: '',
    routes: appListPublicRoutes
  },
  private: {
    path: '/',
    header: 'SYNERYX',
    thirdLink: '',
    routes: privateRoutes
  },
  backOffice: {
    path: '/',
    header: 'SYNERYX',
    thirdLink: '',
    routes: rootRoutes
  }
};

export default AppList;
