import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import AzureColumnsAdminService from '../../services/admin/AzureColumnsAdminService';
import AzureColumnsSuperAdminService from '../../services/superAdmin/AzureColumnsSuperAdminService';

export const initialState = {
  azureColumns: [],
  isLoading: false,
  status: 'fulfilled'
};

export const getAllAzureColumns = createAsyncThunk('getAzureColumns/list', async () => {
  const res = await AzureColumnsAdminService.getAzureColumns();
  return res.data;
});

export const updateAllAzureColumns = createAsyncThunk(
  'updateAzureColumns/list',
  async (azureColumns) => {
    const res = await AzureColumnsSuperAdminService.updateAllAzureColumns(azureColumns);
    return res.data;
  }
);

export const azureColumnsSlice = createSlice({
  name: 'azureColumns',
  initialState,
  reducers: {
    resetAzureColumn: () => {
      return initialState;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllAzureColumns.pending, (state) => {
        state.status = 'pending';
        state.isLoading = true;
      })
      .addCase(getAllAzureColumns.fulfilled, (state, action) => {
        state.status = 'fulfilled';
        state.isLoading = false;
        state.azureColumns = action.payload.azureColumns;
      })
      .addCase(getAllAzureColumns.rejected, (state) => {
        state.status = 'rejected';
        state.isLoading = false;
      });

    builder
      .addCase(updateAllAzureColumns.pending, (state) => {
        state.status = 'pending';
        state.isLoading = true;
      })
      .addCase(updateAllAzureColumns.fulfilled, (state) => {
        state.status = 'fulfilled';
        state.isLoading = false;
      })
      .addCase(updateAllAzureColumns.rejected, (state) => {
        state.status = 'rejected';
        state.isLoading = false;
      });
  }
});

export const { resetAzureColumn } = azureColumnsSlice.actions;

export const selectAzureColumns = (state) => state.azureColumns.azureColumns;
export const selectAzureColumnsCreation = (state) =>
  state.azureColumns.azureColumns.filter(({ isInCreation }) => isInCreation);
export const selectAzureColumnsEdit = (state) =>
  state.azureColumns.azureColumns.filter(({ isInEdit }) => isInEdit);

export const selectAzureColumnsDisplayed = (state) =>
  state.azureColumns.azureColumns.filter((azureColumn) => azureColumn.isDisplayed);

export default azureColumnsSlice.reducer;
