import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ThemeProvider } from '@mui/material';
import AppList from './AppList';
import PrivateRoute from '../components/redirection/PrivateRoute';
import PublicRoute from '../components/redirection/PublicRoute';
import { selectIsLogin, selectGroup } from '../redux/slices/userConnected';
import MainPage from '../components/layout/MainPage';
import ApiResponseHandlerReturn from '../components/notification/handlerNotification';
import theme, { themeAdmin } from '../assets/theme/MUItheme';
import RedirectionRoute from '../components/redirection/RedirectionRoute';
import RootRoute from '../components/redirection/RootRoute';

export default function App() {
  const { errorRoutes } = AppList.errors;
  const routesPrivate = AppList.private.routes;
  const routesPublic = AppList.public.routes;
  const routesRoot = AppList.backOffice.routes;

  const isLogin = useSelector(selectIsLogin);
  const isBackOffice = localStorage.getItem('isBackOffice') === 'true';
  const group = useSelector(selectGroup);

  return (
    <ThemeProvider theme={group.name !== 'user' ? themeAdmin : theme}>
      <Router>
        <RedirectionRoute isLogin={isLogin} />
        <ApiResponseHandlerReturn />
        <Routes>
          {errorRoutes.map(function component(route) {
            return (
              <Route
                key={route?.path}
                path={route?.path}
                element={
                  <div>
                    <route.component />
                  </div>
                }
              />
            );
          })}
          <Route
            exact
            path="/"
            element={<PublicRoute isLogin={isLogin} isBackOffice={isBackOffice} />}
          >
            {routesPublic.map(function component(route) {
              return <Route key={route?.path} path={route?.path} element={<route.component />} />;
            })}
          </Route>
          <Route
            exact
            path="/"
            element={<PrivateRoute isLogin={isLogin} isBackOffice={isBackOffice} />}
          >
            {routesPrivate.map(function component(route) {
              return (
                <Route
                  key={route?.path}
                  path={route?.path}
                  element={
                    <div>
                      <MainPage ChildComponent={route.component} />
                    </div>
                  }
                />
              );
            })}
          </Route>
          <Route
            exact
            path="/"
            element={
              <RootRoute
                isLogin={isLogin}
                isRoot={
                  group.name !== 'user' || group.name !== 'admin' || group.name !== 'superAdmin'
                }
                isBackOffice={isBackOffice}
              />
            }
          >
            {routesRoot.map(function component(route) {
              return (
                <Route
                  key={route?.path}
                  path={route?.path}
                  element={
                    <div>
                      <MainPage ChildComponent={route.component} />
                    </div>
                  }
                />
              );
            })}
          </Route>
        </Routes>
      </Router>
    </ThemeProvider>
  );
}
