import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Grid, Divider, Typography, Link, FormControl, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import { useTheme } from '@emotion/react';
import { useDispatch, useSelector } from 'react-redux';
import { getDateFormatted } from '../utils/Date/DateCompare';
import {
  styleCommentBox,
  styleFlexResponsive,
  stylePaddingBottomTop,
  StyleGridSize,
  styleBorderLeft,
  styleCommentBody,
  styleLink,
  styleMarginTop,
  styleMarginTopBot
} from '../assets/style/components/Comments';
import styleScrollbar from '../assets/style/utils/utils';
import CustomizedButton from './buttons/CustomizedButton';
import { addJiraDeviceComment } from '../redux/slices/JiraDevices';
import hasPermissions from '../utils/Permissions/permissions';
import { selectPermissions } from '../redux/slices/userConnected';

export default function Comments({ data, deviceId }) {
  const { t } = useTranslation();
  const theme = useTheme();
  const commentList = React.createRef();
  const dispatch = useDispatch();

  const [newComment, setNewComment] = useState('');

  const permissions = useSelector(selectPermissions);

  const isUpdated = (date1, date2) => {
    if (new Date(date1).getTime() >= new Date(date2).getTime()) return false;
    return true;
  };

  const scrollToBottom = () => {
    commentList.current?.scrollIntoView({ behavior: 'smooth' });
  };
  const handleClick = (e) => {
    e.preventDefault();
    dispatch(addJiraDeviceComment({ newComment, deviceId }));
  };

  useEffect(() => {
    scrollToBottom();
  }, []);

  return (
    <Grid sx={styleMarginTopBot}>
      <Grid sx={[StyleGridSize, styleScrollbar]}>
        {data.map((comment, index) => (
          <Grid
            key={comment.id}
            sx={[styleCommentBox, index % 2 === 0 ? null : styleBorderLeft(theme)]}
            ref={commentList}
          >
            <Grid>
              <Grid
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                sx={stylePaddingBottomTop}
              >
                <Link
                  data-testid={`${comment.author.name}DisplayUserLink`}
                  component={RouterLink}
                  to={`/jiraUsers/${comment.author.key}`}
                  underline="hover"
                  sx={styleLink(theme)}
                >
                  {comment.author.displayName}
                </Link>
                <Grid sx={styleFlexResponsive}>
                  <Typography>
                    &ensp;
                    {!isUpdated(comment.created, comment.updated)
                      ? t('createdComment')
                      : t('updatedComment')}{' '}
                  </Typography>
                  <Typography data-testid={`${comment.id}date`}>
                    {' '}
                    &ensp;{getDateFormatted(comment.created)}
                  </Typography>
                </Grid>
              </Grid>
              <Divider />
              <Grid sx={stylePaddingBottomTop}>
                <Typography sx={styleCommentBody} data-testid={`${comment.id}text`}>
                  {comment.body}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        ))}
      </Grid>
      {hasPermissions(permissions, 'ADD_JIRA_DEVICE_COMMENT') ? (
        <FormControl fullWidth sx={styleMarginTop}>
          <TextField
            data-testid="AddCommentInput"
            type="text"
            onChange={(e) => setNewComment(e.target.value)}
            fullWidth
            multiline
            placeholder={t('writeComment')}
          />
          <Grid display="flex" justifyContent="flex-end">
            <CustomizedButton
              onClick={handleClick}
              text={t('send')}
              datatestid="ButtonSendNewComment"
            />
          </Grid>
        </FormControl>
      ) : null}
    </Grid>
  );
}
Comments.propTypes = {
  data: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string)).isRequired,
  deviceId: PropTypes.string.isRequired
};
