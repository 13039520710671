import axios from 'axios';
import API_URL from '../../constants';

const AzureGroupsService = {
  getAzureGroups() {
    return axios.get(`${API_URL}/azureGroups`);
  },
  getAzureGroupById(id) {
    return axios.get(`${API_URL}/azureGroups/${id}`);
  },
  updateAzureGroups(modifiedAzureGroup) {
    return axios.put(`${API_URL}/azureGroups/${modifiedAzureGroup.id}`, modifiedAzureGroup);
  },
  deleteAzureGroups(id) {
    return axios.delete(`${API_URL}/azureGroups/${id}`);
  },
  addAzureGroups(body) {
    return axios.post(`${API_URL}/azureGroups`, body);
  },
  getDomains() {
    return axios.get(`${API_URL}/azureGroups/domains`);
  },
  getAzureGroupsGraph() {
    return axios.get(`${API_URL}/users/azure/groups`);
  }
};

export default AzureGroupsService;
