import React, { useState } from 'react';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';
import { Grid, TextField } from '@mui/material';
import PropTypes from 'prop-types';

export default function CheckPassword({
  errorValidation,
  helperTextCheck,
  labelCheck,
  typeTextField,
  setNewPassword
}) {
  const [type, setType] = useState(false);

  const handleChangeType = () => {
    setType(!type);
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  return (
    <Grid display="flex" alignItems="flex-start" sx={{ marginTop: '5px' }}>
      <TextField
        error={errorValidation}
        id="Check"
        type={type ? 'text' : typeTextField}
        helperText={helperTextCheck}
        data-testid={`${typeTextField}Field`}
        variant="standard"
        sx={{ width: '100%' }}
        label={labelCheck}
        onChange={(e) => setNewPassword(e.target.value)}
      />
      <IconButton
        onClick={handleChangeType}
        onMouseDown={handleMouseDownPassword}
        sx={{ padding: '0px', paddingTop: '1px' }}
      >
        {type ? <VisibilityOff /> : <Visibility />}
      </IconButton>
    </Grid>
  );
}

CheckPassword.defaultProps = {
  errorValidation: false,
  helperTextCheck: '',
  labelCheck: '',
  typeTextField: 'text',
  setNewPassword: () => null
};
CheckPassword.propTypes = {
  errorValidation: PropTypes.bool,
  helperTextCheck: PropTypes.string,
  labelCheck: PropTypes.string,
  typeTextField: PropTypes.string,
  setNewPassword: PropTypes.func
};
