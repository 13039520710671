import React from 'react';
import {
  Paper,
  TableRow,
  TableCell,
  Table,
  TableBody,
  TableContainer,
  Dialog,
  DialogContent,
  DialogActions,
  Typography
} from '@mui/material';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { deleteAdUser } from '../../redux/slices/AdUsers';
import CustomizedButton from '../buttons/CustomizedButton';
import styleScrollbar from '../../assets/style/utils/utils';

export default function AdDeleteUserPopup({ popup, closePopup, selectedUsers, resetSelectedRows }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleClick = async () => {
    setTimeout(async () => {
      try {
        const response = await dispatch(deleteAdUser(selectedUsers));
        if (response?.payload?.success === true) {
          resetSelectedRows();
          closePopup();
        }
      } catch (error) {
        console.error(error);
      }
    });
  };
  return (
    <Dialog
      open={popup}
      onClose={closePopup}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      data-testid="deleteAdUserPopup"
      PaperProps={{ sx: styleScrollbar }}
    >
      <DialogContent sx={styleScrollbar}>
        <TableContainer component={Paper}>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell align="center">
                  <Typography>{`${t('confirmDeleteAdUsers')}`}</Typography>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
      <DialogActions>
        <CustomizedButton onClick={closePopup} text={t('cancel')} datatestid="closePopup" />
        <CustomizedButton
          onClick={handleClick}
          text={t('delUser')}
          datatestid="ButtondeleteAdUser"
        />
      </DialogActions>
    </Dialog>
  );
}

AdDeleteUserPopup.propTypes = {
  popup: PropTypes.bool.isRequired,
  closePopup: PropTypes.func.isRequired,
  selectedUsers: PropTypes.arrayOf(PropTypes.string).isRequired,
  resetSelectedRows: PropTypes.func.isRequired
};
