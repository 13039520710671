export const formatDevicesIntuneColumns = (state) => {
  if (!state.devicesIntuneColumns.length) {
    const tmpColumns = Object.keys(state.devices[0]);
    tmpColumns.forEach((column) => {
      if (column !== 'id' && column !== 'userId' && column !== 'userPrincipalName')
        state.devicesIntuneColumns.push(column);
    });
  }
};

export const formatDeviceIntuneColumns = (state) => {
  if (!state.deviceIntuneColumns.length && state.selectedDevice.information) {
    const tmpColumns = Object.keys(state.selectedDevice.information);
    tmpColumns.forEach((column) => {
      if (column !== 'id' && column !== 'userId' && column !== 'deviceActionResults')
        state.deviceIntuneColumns.push(column);
    });
  }
};

export const formatDeviceIntuneActionsColumns = (state) => {
  if (
    !state.deviceIntuneActionsColumns.length &&
    state.selectedDevice.information.deviceActionResults[0]
  ) {
    const tmpColumns = Object.keys(state.selectedDevice.information.deviceActionResults[0]);
    tmpColumns.forEach((column) => {
      if (column === 'lastUpdatedDateTime')
        state.deviceIntuneActionsColumns.push({ name: column, key: column, type: 'date' });
      else state.deviceIntuneActionsColumns.push({ name: column, key: column, type: 'string' });
    });
  }
};

export const formatJiraUserColumns = (state) => {
  if (!state.columns.length) {
    const tmpColumns = Object.keys(state.jiraUserList[0]);
    tmpColumns.forEach((column) => {
      if (column !== 'deleted') {
        state.columns.push(column);
      }
    });
  }
};

export const formatBoondResourceColumns = (state) => {
  if (!state.columns.length) {
    const tmpColumns = Object.keys(state.boondResources[0]);
    tmpColumns.forEach((column) => {
      state.columns.push(column);
    });
  }
};
