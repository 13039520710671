import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  getDevices,
  refreshIntuneDevices,
  selectIsLoading,
  selectDevices,
  resetIntuneDeviceState,
  selectDeviceUpdatedAt,
  selectDevicesIntuneColumns,
  selectIsTableLoaded
} from '../../../redux/slices/IntuneDevices';

import { formatColumnsForTable } from '../../../components/datalist/ComponentsInTable';
import ItemsList from '../ItemsList';

export default function DevicesIntune() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [searchTerm, setSearchTerm] = useState('');
  // eslint-disable-next-line no-unused-vars
  const [tableSelectedRows, setTableSelectedRows] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalItems, setTotalItems] = useState(0);
  const devicesColumns = useSelector(selectDevicesIntuneColumns);
  const allDevices = useSelector(selectDevices(''));
  const isDevicesLoading = useSelector(selectIsLoading);
  const updatedTime = useSelector(selectDeviceUpdatedAt);
  const isTableLoaded = useSelector(selectIsTableLoaded);
  const [selectedIndexRows, setSelectedIndexRows] = useState([]);

  const resetSelectedRows = () => {
    setTableSelectedRows([]);
    setSelectedIndexRows([]);
  };

  const setTableSelectedRowsRealData = (rows) => {
    const copyRows = [...rows];

    setSelectedIndexRows(copyRows);
    const tempRows = copyRows.map(
      (rowIndex) => devicesColumns[rowIndex === null ? 0 : rowIndex]?.id
    );
    setTableSelectedRows(tempRows);
  };

  useEffect(() => {
    dispatch(getDevices());
    return function cleanup() {
      dispatch(resetIntuneDeviceState());
    };
  }, [dispatch]);

  const handleChangePage = (next, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
  };
  const handleSearchTerm = (e) => {
    const { value } = e.target;
    setSearchTerm(() => value);
    setPage(0);
  };
  const handleRefresh = async () => {
    setPage(0);
    resetSelectedRows();
    dispatch(refreshIntuneDevices());
  };

  const searchBar = {
    isDisplayable: true,
    disabled: false
  };

  const optionsKeys = {
    titleNameKey: 'displayName',
    secondaryTitleKey: 'deviceOwnership',
    statusKey: ''
  };

  const columnOptions = [
    {
      name: 'displayName',
      type: 'LinkInTable',
      linkOptions: { linkColumn: 'displayName', linkTo: 'deviceId' },
      display: true
    },
    { name: 'deviceOwnership', display: true, filter: true },
    { name: 'deviceId', display: true }
  ];
  const formatedColumns = formatColumnsForTable(devicesColumns, allDevices, columnOptions, t);
  const desktopConfig = {
    actions: {
      handleRefresh,
      handleSearchTerm
    },

    buttons: [],
    searchBar,

    data: {
      tableSelectedRows,
      setTableSelectedRows,
      setTableSelectedRowsRealData,
      items: allDevices
    },

    loading: {
      itemsListIsLoading: isDevicesLoading,
      isTableDisplayable: isTableLoaded
    },

    tables: {
      optionsKeys,
      formatedColumns,
      date: updatedTime,
      totalItems,
      page,
      setPage,
      handleChangePage,
      handleChangeRowsPerPage,
      rowsPerPage,
      selectedIndexRows,
      setTotalItems
    },

    search: {
      searchTerm,
      setSearchTerm
    },

    text: {
      titleText: t('devicesIntune'),
      bigTitleText: t('manageDevices'),
      selectedItemsText: t('selectedDevices'),
      tagId: 'DevicesIntune'
    },

    isDisableCheckBox: true,
    searchBarPlaceOlder: t('searchDevices')
  };
  return (
    <Grid>
      <ItemsList config={desktopConfig} />
    </Grid>
  );
}
