import axios from 'axios';
import API_URL from '../../constants';

const AzureColumnsSuperAdminService = {
  updateAllAzureColumns(azureColumns) {
    return axios.patch(`${API_URL}/azureColumns`, { azureColumns });
  }
};

export default AzureColumnsSuperAdminService;
