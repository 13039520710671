import * as React from 'react';
import { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import {
  Grid,
  Link,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography
} from '@mui/material/';
import MuiDrawer from '@mui/material/Drawer';
import PropTypes from 'prop-types';

import { useSelector } from 'react-redux';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  styleOpenedMixin,
  styleClosedMixin,
  classes,
  StyleSideBarWrapper
} from '../../assets/style/components/layout/SideBar';
import SideBarList from './SideBarList';
import styleScrollBar from '../../assets/style/utils/utils';

import {
  selectEmail,
  selectName,
  selectPermissions,
  selectPhoto
} from '../../redux/slices/userConnected';
import { selectNotReadCounter } from '../../redux/slices/Messages';
import SubMenuUserList from './SideBar/SubMenuList';
import hasPermissions from '../../utils/Permissions/permissions';
import SubMenuAccountList from './SideBar/SubMenuAccountList';
import SubMenuBackOffice from './SideBar/SubMenuBackOffice';
import { formatParameter, getParametersNoConnected } from '../../utils/parametersPublic';

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: '240px',
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...styleOpenedMixin(theme),
      '& .MuiDrawer-paper': styleOpenedMixin(theme)
    }),
    ...(!open && {
      ...styleClosedMixin(theme),
      '& .MuiDrawer-paper': styleClosedMixin(theme)
    })
  })
);

export default function SideBar({
  isOpenSideBar,
  style,
  openSubMenuId,
  openReductedSubMenuId,
  setOpenSubMenuId,
  setOpenReductedSubMenuId
}) {
  const { t } = useTranslation();
  const [parametersNotConnected, setParametersNotConnected] = useState([]);
  const [appTitleFirstLetter, setAppTitleFirstLetter] = useState('');
  const [appTitle, setAppTitle] = useState('');
  const handleClick = (id) => {
    if (isOpenSideBar) {
      return openSubMenuId === id ? setOpenSubMenuId('none') : setOpenSubMenuId(id);
    }
    setOpenSubMenuId('none');
    return openReductedSubMenuId === id
      ? setOpenReductedSubMenuId('none')
      : setOpenReductedSubMenuId(id);
  };
  const email = useSelector(selectEmail);
  const name = useSelector(selectName);
  const userPhoto = useSelector(selectPhoto);

  const permissions = useSelector(selectPermissions);
  const notReadCounter = useSelector(selectNotReadCounter);
  const subMenuListByPerm = SubMenuUserList(notReadCounter).reduce((array, element) => {
    if (element.child) {
      const childSubMenuListByPerm = element.child.filter((childElement) =>
        hasPermissions(permissions, childElement.codename)
      );
      if (childSubMenuListByPerm.length > 0) {
        array.push({ ...element, child: childSubMenuListByPerm });
      }
    } else if (hasPermissions(permissions, element.codename)) {
      array.push(element);
    }
    return array;
  }, []);
  const subMenuListProfile = SubMenuAccountList().reduce((array, element) => {
    if (element.child) {
      const childSubMenuListByPerm = element.child.filter((childElement) =>
        hasPermissions(permissions, childElement.codename)
      );
      if (childSubMenuListByPerm.length > 0) {
        array.push({ ...element, child: childSubMenuListByPerm });
      }
    } else if (hasPermissions(permissions, element.codename) || element.codename === 'LOGOUT') {
      array.push(element);
    }
    return array;
  }, []);

  const subMenuListBackOffice = SubMenuBackOffice().reduce((array, element) => {
    if (element.child) {
      const childSubMenuListByPerm = element.child.filter((childElement) =>
        hasPermissions(permissions, childElement.codename)
      );
      if (childSubMenuListByPerm.length > 0) {
        array.push({ ...element, child: childSubMenuListByPerm });
      }
    } else if (hasPermissions(permissions, element.codename)) {
      array.push(element);
    }
    return array;
  }, []);
  const isBackOffice = localStorage.getItem('isBackOffice');
  const currentPathLocation = `/${useLocation().pathname.split('/')[1]}`;

  useEffect(() => {
    const fetchData = async () => {
      await getParametersNoConnected(setParametersNotConnected);
    };
    fetchData();
  }, []);
  useEffect(() => {
    const fetchTitle = async () => {
      const appName = formatParameter(parametersNotConnected, 'appName', 'Syneryx');
      setAppTitle(appName);
      setAppTitleFirstLetter(appName.charAt(0));
    };
    fetchTitle();
  }, [parametersNotConnected]);
  return (
    <Drawer variant="permanent" open={isOpenSideBar} sx={style} PaperProps={{ sx: styleScrollBar }}>
      <StyleSideBarWrapper
        container
        className={`${classes.sideBarWrapper} ${
          isOpenSideBar ? classes.sideBarOpen : classes.sideBarClosed
        }`}
      >
        <Grid>
          {isOpenSideBar ? (
            <Typography className={classes.sideBarTitle}>{appTitle}</Typography>
          ) : (
            <Typography className={classes.sideBarLogo}>{appTitleFirstLetter}</Typography>
          )}
          <Typography className={classes.sideBarMenuText}>MENU</Typography>
          <SideBarList
            subMenuList={!isBackOffice ? subMenuListByPerm : subMenuListBackOffice}
            isOpenSideBar={isOpenSideBar}
            openSubMenuId={openSubMenuId}
            openReductedSubMenuId={openReductedSubMenuId}
            setOpenSubMenuId={setOpenSubMenuId}
            setOpenReductedSubMenuId={setOpenReductedSubMenuId}
          />
        </Grid>
        <Grid className={classes.sideBarAccountOptionsWrapper}>
          <Typography className={classes.sideBarAccountText}>{t('account')}</Typography>
          <SideBarList
            subMenuList={subMenuListProfile}
            isOpenSideBar={isOpenSideBar}
            openSubMenuId={openSubMenuId}
            openReductedSubMenuId={openReductedSubMenuId}
            setOpenSubMenuId={setOpenSubMenuId}
            setOpenReductedSubMenuId={setOpenReductedSubMenuId}
          />
          <ListItem
            disablePadding
            className={`${classes.sideBarListItem} ${classes.sideBarListItemProfile}`}
            onClick={() => handleClick('profile')}
            selected={currentPathLocation === '/profile'}
          >
            <Link
              component={RouterLink}
              to="/profile"
              data-testid="SideBarLinkProfile"
              className={classes.sideBarLink}
            >
              <ListItemButton className={classes.sideBarButtonList} id="basic-button">
                <ListItemIcon>
                  <img
                    alt="hello"
                    height="50px"
                    style={{ marginLeft: '10px', borderRadius: '8px' }}
                    src={userPhoto}
                  />
                </ListItemIcon>
                <Grid container className={classes.sideBarClosedNone}>
                  <Grid item xs={12}>
                    <ListItemText primary={name} className={classes.sideBarProfileText} />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      sx={{ width: '100%', textOverflow: 'ellipsis', overflow: 'hidden' }}
                      className={classes.sideBarProfileText}
                    >
                      {email}
                    </Typography>
                  </Grid>
                </Grid>
              </ListItemButton>
            </Link>
          </ListItem>
        </Grid>
      </StyleSideBarWrapper>
    </Drawer>
  );
}

SideBar.propTypes = {
  isOpenSideBar: PropTypes.bool.isRequired,
  style: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.objectOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.objectOf(PropTypes.string)])
    )
  ]).isRequired,
  openSubMenuId: PropTypes.string.isRequired,
  openReductedSubMenuId: PropTypes.string.isRequired,
  setOpenSubMenuId: PropTypes.func.isRequired,
  setOpenReductedSubMenuId: PropTypes.func.isRequired
};
