import axios from 'axios';
import API_URL from '../../constants';
import socket from '../../socket/socket';

const JiraDevicesService = {
  getOneJiraDevice(id) {
    return axios.get(`${API_URL}/devices/jira/${id}`);
  },
  addJiraDeviceComment(comment, id) {
    return axios.post(`${API_URL}/devices/jira/comment/${id}`, { newComment: comment });
  },
  assignReporterToJiraDevice(key, body) {
    return axios.put(`${API_URL}/devices/jira/update/reporter/${key}`, body);
  },
  findJiraDevices() {
    return socket.emit('jira-device:find', 'true');
  },
  refreshJiraDevices() {
    return socket.emit('jira-device:refresh', 'true');
  }
};

export default JiraDevicesService;
