import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogActions,
  FormControl,
  Grid,
  TextField,
  Autocomplete,
  Box,
  Typography
} from '@mui/material';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { styleDialogActions } from '../../assets/style/views/AzurADUserPopup';
import CustomizedButton from '../buttons/CustomizedButton';
import styleScrollbar from '../../assets/style/utils/utils';
import CustomFormField from '../form/CustomFormField';
import BrowseOU from '../adUser/BrowseOU';
import { getDC, getGroupsAd, selectADForForm } from '../../redux/slices/AdUsers';
import { styleTitleHeadPageDescript, styledTitle } from '../../assets/style/views/User';

export default function LdapADUserPopup({
  popup,
  closePopup,
  user,
  updateAdUser,
  updateAdUserGroups,
  isStepChangeOU,
  setIsStepChangeOU,
  adGroups
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isSubmit, setIsSubmit] = useState(false);
  const AD = useSelector(selectADForForm);
  const defaultOU = user.distinguishedName.substring(user.distinguishedName.indexOf(',') + 1);
  const [selectedOU, setSelectedOU] = useState(defaultOU);
  const navigate = useNavigate();
  const [mGroups, setMGroups] = useState([]);
  const [modifiedUser, setModifiedUser] = useState({});
  const [filteredGroups, setFilteredGroups] = useState([]);

  const filterGroups = () => {
    const filtered = mGroups.filter((mGroup) =>
      adGroups.some((adGroup) => adGroup.dn === mGroup.dn)
    );
    setFilteredGroups(filtered);
  };

  useEffect(() => {
    if (!isStepChangeOU) filterGroups();
  }, [adGroups]);

  useEffect(() => {
    if (popup === true)
      setModifiedUser({
        ...user
      });
  }, [popup]);

  useEffect(() => {
    dispatch(getDC({ isForForm: true }));
    dispatch(getGroupsAd());
  }, [dispatch]);

  useEffect(() => {
    setMGroups(user?.memberOf?.length ? user.memberOf : []);
  }, [user]);

  const handleModifiedUser = (modifiedAdUser) => {
    const newModifiedUser = { ...modifiedAdUser };
    newModifiedUser.name = newModifiedUser.cn;
    setModifiedUser(newModifiedUser);
  };

  const handleSelectGroups = (event, value) => {
    setFilteredGroups(value);
  };

  const handleClick = async () => {
    if (!isStepChangeOU) setIsStepChangeOU(true);
    else {
      setIsSubmit(true);
      await updateAdUserGroups(
        filteredGroups.map((obj) => obj.dn),
        mGroups.map((obj) => obj.dn)
      );
      await updateAdUser({
        ...modifiedUser,
        userPrincipalName: modifiedUser.mail,
        distinguishedName: `CN=${modifiedUser.cn},${selectedOU}`,
        memberOf: filteredGroups
      });

      navigate(`/adUsers/CN=${modifiedUser.cn},${selectedOU}`);
    }
  };

  const isOptionEqualToValue = (option, value) => option.dn === value.dn;
  const [adDomain, setAdDomain] = useState(process.env.REACT_APP_DEFAULT_DOMAIN);
  const propertyAD = [
    { columnName: 'cn', input: 'TEXTFIELD', isEditable: true, isNullable: false },
    {
      columnName: 'mail',
      input: 'SEMICOMPLETE',
      isEditable: true,
      isNullable: false,
      domain: adDomain,
      listAllDomain: [process.env.REACT_APP_DEFAULT_DOMAIN]
    }
  ];

  return (
    <Dialog
      open={popup}
      onClose={closePopup}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      data-testid="ADUserPopUp"
      PaperProps={{ sx: styleScrollbar }}
    >
      <DialogContent sx={styleScrollbar}>
        <Box sx={styledTitle}>
          <Typography sx={styleTitleHeadPageDescript}>{t('editUser')}</Typography>
        </Box>
        <FormControl
          sx={(theme) => ({ '& label.Mui-focused': { color: theme.palette.colorBlack }, gap: 2 })}
        >
          {!isStepChangeOU &&
            propertyAD &&
            propertyAD.map(
              ({ columnName, input, isEditable, isNullable, domain, listAllDomain = null }) => (
                <Grid key={columnName}>
                  <CustomFormField
                    columnName={columnName}
                    input={input}
                    isEditable={isEditable}
                    isNullable={isNullable}
                    valueObject={modifiedUser}
                    setValueObject={handleModifiedUser}
                    listAllDomain={listAllDomain}
                    setDomain={setAdDomain}
                    domain={domain}
                    error={() => {}}
                    isSubmit={isSubmit}
                    isAd
                  />
                </Grid>
              )
            )}
          {!isStepChangeOU && (
            <Grid>
              <Box sx={styledTitle}>
                <Typography sx={styleTitleHeadPageDescript}>{t('editGroups')}</Typography>
              </Box>
              <Autocomplete
                multiple
                limitTags={2}
                sx={{ width: 500 }}
                id="ad-groups"
                data-testid="ad-groups"
                options={adGroups}
                getOptionLabel={({ name }) => name}
                isOptionEqualToValue={isOptionEqualToValue}
                value={filteredGroups}
                onChange={handleSelectGroups}
                renderOption={(props, option) => (
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  <Box component="li" data-testid={`ad-groups-option-${option.name}`} {...props}>
                    {option.name}
                  </Box>
                )}
                renderInput={(params) => {
                  return (
                    <TextField
                      // eslint-disable-next-line react/jsx-props-no-spreading
                      {...params}
                      variant="standard"
                      placeholder={t('groups')}
                    />
                  );
                }}
              />
            </Grid>
          )}
          {isStepChangeOU ? (
            <Grid>
              <BrowseOU AD={AD} setSelectedOU={setSelectedOU} isSelectDNUser={false} isForForm />
            </Grid>
          ) : null}
        </FormControl>
      </DialogContent>
      <DialogActions sx={styleDialogActions}>
        {isStepChangeOU ? (
          <Grid container direction="row">
            <Grid item xs={12}>
              <TextField sx={{ width: '100%' }} type="text" value={selectedOU} variant="standard" />
            </Grid>
          </Grid>
        ) : null}
        <CustomizedButton datatestid="cancelEditAdUser" onClick={closePopup} text={t('cancel')} />
        <CustomizedButton datatestid="saveEditAdUser" text={t('save')} onClick={handleClick} />
      </DialogActions>
    </Dialog>
  );
}

LdapADUserPopup.propTypes = {
  popup: PropTypes.bool.isRequired,
  closePopup: PropTypes.func.isRequired,
  user: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.bool,
      PropTypes.number,
      PropTypes.arrayOf(
        PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.objectOf(
            PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)])
          )
        ])
      ),
      PropTypes.objectOf(
        PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.bool,
          PropTypes.number,
          PropTypes.arrayOf(
            PropTypes.oneOfType([
              PropTypes.string,
              PropTypes.objectOf(
                PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)])
              )
            ])
          )
        ])
      )
    ])
  ).isRequired,
  updateAdUser: PropTypes.func.isRequired,
  updateAdUserGroups: PropTypes.func.isRequired,
  isStepChangeOU: PropTypes.bool.isRequired,
  setIsStepChangeOU: PropTypes.func.isRequired,
  adGroups: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      dn: PropTypes.string.isRequired
    })
  ).isRequired
};
