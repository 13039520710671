import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Box, useMediaQuery, useTheme, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Chat from '../../components/SMS/Chat';
import {
  conversationList,
  resetMessageState,
  setMessages,
  setSelectedConversation,
  updateConversationList
} from '../../redux/slices/Messages';
import socket from '../../socket/socket';
import Dashboard from '../../components/SMS/Dashboard';
import { findAzureUsers, resetAzureUserState } from '../../redux/slices/AzureUsersList';
import {
  styleMainContainer,
  styleTypographyProfile,
  styleHeader,
  styleTitle,
  styleListContainer
} from '../../assets/style/views/SMS';
import ChatList from '../../components/SMS/ChatList';
import styleScrollbar from '../../assets/style/utils/utils';

export default function Sms() {
  const dispatch = useDispatch();
  const theme = useTheme();
  const { t } = useTranslation();
  const isMobile = !useMediaQuery(theme.breakpoints.up('md'));
  const [isChatOpen, setIsChatOpen] = useState(false);
  const [isPhoneNumberExist, setIsPhoneNumberExist] = useState(false);

  useEffect(() => {
    dispatch(findAzureUsers());
    dispatch(conversationList());
    socket.on('RCS-capability:result', (result) => {
      if (result.isActive) {
        const { phoneNumber, phoneNumberId } = result;
        const updateList = {
          text: null,
          createdTime: null,
          readTime: null,
          phoneNumber: {
            id: phoneNumberId,
            name: phoneNumber
          },
          status: {
            id: null,
            name: null
          }
        };
        dispatch(setSelectedConversation({ phoneNumberId, phoneNumber }));
        setIsPhoneNumberExist(result.isActive);
        dispatch(updateConversationList(updateList));
      }
    });
    socket.on('conversation-id:details', (conversation) => {
      dispatch(setMessages(conversation));
    });
    return function cleanup() {
      socket.removeListener('RCS-capability:result');
      socket.removeListener('conversation-id:details');
      dispatch(resetAzureUserState());
      dispatch(resetMessageState());
    };
  }, [dispatch]);

  return (
    <Grid>
      <Box>
        <Grid sx={styleHeader(theme, isMobile)}>
          <Typography sx={styleTypographyProfile}>{t('sms')}</Typography>
          <Typography variant="h4" sx={styleTitle}>
            {t('subtitleSMS')}
          </Typography>
          {((isMobile && !isChatOpen) || !isMobile) && (
            <Dashboard isMobile={isMobile} setIsChatOpen={setIsChatOpen} />
          )}
        </Grid>
      </Box>

      <Grid sx={styleMainContainer}>
        <Box sx={[styleListContainer(isMobile, isChatOpen), styleScrollbar]}>
          <ChatList setIsChatOpen={setIsChatOpen} isChatOpen={isChatOpen} isMobile={isMobile} />
        </Box>
        <Chat
          isPhoneNumberExist={isPhoneNumberExist}
          setIsPhoneNumberExist={setIsPhoneNumberExist}
          isMobile={isMobile}
          isChatOpen={isChatOpen}
          setIsChatOpen={setIsChatOpen}
        />
      </Grid>
    </Grid>
  );
}
