import React from 'react';
import PropTypes from 'prop-types';

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import CustomizedButton from '../buttons/CustomizedButton';

export default function ConsentPopup({ open, handleClose, handleAction, text, actionTitle }) {
  const { t } = useTranslation();
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{actionTitle}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">{text}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <CustomizedButton onClick={handleClose} text={t('cancel')} datatestid="cancelConsent" />
        <CustomizedButton onClick={handleAction} text={t('agree')} datatestid="agreeConsent" />
      </DialogActions>
    </Dialog>
  );
}

ConsentPopup.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleAction: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
  actionTitle: PropTypes.string.isRequired
};
