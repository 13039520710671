import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import DeviceService from '../../services/devices/IntuneDeviceService';
import { getDateTimeFormatted } from '../../utils/Date/DateFunction';
import {
  formatDeviceIntuneActionsColumns,
  formatDeviceIntuneColumns,
  formatDevicesIntuneColumns
} from '../../utils/columnsFormatters';

export const initialState = {
  devices: [],
  isLoading: true,
  updatedAt: '',
  selectedDevice: {
    isLoading: false,
    information: null,
    recoveryKeys: [],
    recoveryKeyDetails: {}
  },
  status: 'fulfilled',
  deviceIntuneColumns: [],
  devicesIntuneColumns: [],
  deviceIntuneActionsColumns: []
};

export const getDevices = createAsyncThunk('devices', async () => {
  DeviceService.getDevices();
});

export const refreshIntuneDevices = createAsyncThunk('refreshIntuneDevices', () => {
  DeviceService.refreshIntuneDevices();
});

export const getOneDeviceById = createAsyncThunk('oneDevice', async (payload) => {
  const res = await DeviceService.getOneDeviceById(payload);
  return res.data;
});
export const getRecoverKeysById = createAsyncThunk('RecoveryKeys', async (payload) => {
  const res = await DeviceService.getRecoverKeysById(payload);
  return res.data;
});
export const getRecoverKeysDetailsById = createAsyncThunk(
  'RecoveryKeysDetails',
  async (payload) => {
    const res = await DeviceService.getRecoverKeyDetailsById(payload);
    return res.data;
  }
);
export const assignUserToIntuneDevice = createAsyncThunk(
  'assignUserToIntuneDevice',
  async (payload) => {
    const res = await DeviceService.assignUserToIntuneDeviceById(payload.deviceId, payload.body);
    return res.data;
  }
);
export const removeUserToIntuneDevice = createAsyncThunk(
  'removeUserToIntuneDevice',
  async (payload) => {
    const res = await DeviceService.removeUserToIntineDeviceById(payload);
    return res.data;
  }
);
export const renameIntuneDeviceName = createAsyncThunk(
  'renameIntuneDeviceName',
  async (payload) => {
    const res = await DeviceService.renameIntuneDeviceName(payload.deviceId, payload.body);
    return res.data;
  }
);
export const rebootIntuneDevice = createAsyncThunk('rebootIntuneDevice', async (payload) => {
  const res = await DeviceService.rebootIntuneDevice(payload);
  return res.data;
});

export const intuneDevicesSlice = createSlice({
  name: 'intuneDevices',
  initialState,
  reducers: {
    addIntuneDevicesToList: (state, action) => {
      state.devices = state.devices.concat(action.payload.devices);
      formatDevicesIntuneColumns(state);
    },
    setIntuneDeviceListUpdatedTime: (state, action) => {
      const dateFormatted = getDateTimeFormatted(Number(action.payload));
      state.updatedAt = dateFormatted;
      state.isLoading = false;
    },
    setAreIntuneDeviceLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    resetIntuneDeviceState: () => {
      return initialState;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getOneDeviceById.pending, (state) => {
        state.status = 'pending';
        state.isLoading = true;
      })
      .addCase(getOneDeviceById.fulfilled, (state, action) => {
        state.status = 'fulfilled';
        state.isLoading = false;
        state.selectedDevice = {
          ...state.selectedDevice,
          information: action.payload.device
        };
        formatDeviceIntuneColumns(state);
        formatDeviceIntuneActionsColumns(state);
      })
      .addCase(getOneDeviceById.rejected, (state) => {
        state.status = 'rejected';
        state.isLoading = false;
      })
      .addCase(getRecoverKeysById.pending, (state) => {
        state.status = 'pending';
        state.isLoading = true;
      })
      .addCase(getRecoverKeysById.fulfilled, (state, action) => {
        let { recoveryKeys } = action.payload;
        recoveryKeys = recoveryKeys.map((key) => {
          let updatedKey = 0;
          switch (key.volumeType) {
            case 1:
              updatedKey = { ...key, volumeType: 'operatingSystemVolume' };
              break;
            case 2:
              updatedKey = { ...key, volumeType: 'fixedDataVolume' };
              break;
            case 3:
              updatedKey = { ...key, volumeType: 'removableDataVolume' };
              break;
            case 4:
              updatedKey = { ...key, volumeType: 'unknownFutureValue' };
              break;
            default:
              updatedKey = key;
              break;
          }
          return updatedKey;
        });
        state.status = 'fulfilled';
        state.isLoading = false;
        state.selectedDevice = {
          ...state.selectedDevice,
          recoveryKeys
        };
      })
      .addCase(getRecoverKeysById.rejected, (state) => {
        state.status = 'rejected';
        state.isLoading = false;
      })
      .addCase(getRecoverKeysDetailsById.rejected, (state) => {
        state.status = 'rejected';
        state.selectedDevice = {
          ...state.selectedDevice,
          isLoading: false
        };
      })
      .addCase(getRecoverKeysDetailsById.pending, (state) => {
        state.status = 'pending';
        state.selectedDevice = {
          ...state.selectedDevice,
          isLoading: true
        };
      })
      .addCase(getRecoverKeysDetailsById.fulfilled, (state, action) => {
        let { recoveryKeyDetails } = action.payload;
        switch (recoveryKeyDetails.volumeType) {
          case 1:
            recoveryKeyDetails = { ...recoveryKeyDetails, volumeType: 'operatingSystemVolume' };
            break;
          case 2:
            recoveryKeyDetails = { ...recoveryKeyDetails, volumeType: 'fixedDataVolume' };
            break;
          case 3:
            recoveryKeyDetails = { ...recoveryKeyDetails, volumeType: 'removableDataVolume' };
            break;
          case 4:
            recoveryKeyDetails = { ...recoveryKeyDetails, volumeType: 'unknownFutureValue' };
            break;
          default:
        }

        state.status = 'fulfilled';
        state.selectedDevice = {
          ...state.selectedDevice,
          isLoading: false,
          recoveryKeyDetails
        };
      })
      .addCase(assignUserToIntuneDevice.pending, (state) => {
        state.status = 'pending';
        state.isLoading = true;
      })
      .addCase(assignUserToIntuneDevice.fulfilled, (state, action) => {
        state.status = 'fulfilled';
        state.isLoading = false;
        state.selectedDevice = {
          ...state.selectedDevice,
          information: { ...state.selectedDevice.information, user: action.payload.user }
        };
      })
      .addCase(assignUserToIntuneDevice.rejected, (state) => {
        state.status = 'rejected';
        state.isLoading = false;
      })
      .addCase(removeUserToIntuneDevice.pending, (state) => {
        state.status = 'pending';
        state.isLoading = true;
      })
      .addCase(removeUserToIntuneDevice.fulfilled, (state) => {
        state.status = 'fulfilled';
        state.isLoading = false;
        state.selectedDevice = {
          ...state.selectedDevice,
          information: { ...state.selectedDevice.information, user: {} }
        };
      })
      .addCase(removeUserToIntuneDevice.rejected, (state) => {
        state.status = 'rejected';
        state.isLoading = false;
      })
      .addCase(renameIntuneDeviceName.pending, (state) => {
        state.status = 'pending';
        state.isLoading = true;
      })
      .addCase(renameIntuneDeviceName.fulfilled, (state, action) => {
        state.status = 'fulfilled';
        state.isLoading = false;
        state.selectedDevice = {
          ...state.selectedDevice,
          information: {
            ...state.selectedDevice.information,
            deviceActionResults: action.payload.deviceActionResults
          }
        };
      })
      .addCase(renameIntuneDeviceName.rejected, (state) => {
        state.status = 'rejected';
        state.isLoading = false;
      })
      .addCase(rebootIntuneDevice.pending, (state) => {
        state.status = 'pending';
        state.isLoading = true;
      })
      .addCase(rebootIntuneDevice.fulfilled, (state, action) => {
        state.status = 'fulfilled';
        state.isLoading = false;
        state.selectedDevice = {
          ...state.selectedDevice,
          information: {
            ...state.selectedDevice.information,
            deviceActionResults: action.payload.deviceActionResults
          }
        };
      })
      .addCase(rebootIntuneDevice.rejected, (state) => {
        state.status = 'rejected';
        state.isLoading = false;
      });
    builder
      .addCase(refreshIntuneDevices.pending, (state) => {
        state.status = 'pending';
        state.isLoading = true;
        state.devices = [];
      })
      .addCase(refreshIntuneDevices.fulfilled, (state) => {
        state.status = 'fulfilled';
      })
      .addCase(refreshIntuneDevices.rejected, (state) => {
        state.status = 'rejected';
        state.isLoading = false;
      });
    builder
      .addCase(getDevices.pending, (state) => {
        state.status = 'pending';
        state.isLoading = true;
        state.devices = [];
      })
      .addCase(getDevices.fulfilled, (state) => {
        state.status = 'fulfilled';
      })
      .addCase(getDevices.rejected, (state) => {
        state.status = 'rejected';
        state.isLoading = false;
      });
  }
});

export const {
  addDevice,
  resetIntuneDeviceState,
  addIntuneDevicesToList,
  setIntuneDeviceListUpdatedTime,
  setAreIntuneDeviceLoading
} = intuneDevicesSlice.actions;

export const selectDeviceUpdatedAt = (state) => state.intuneDevices.updatedAt;

export const selectIsLoading = (state) => state.intuneDevices.isLoading;
export const selectSelectedDevice = (state) => state.intuneDevices.selectedDevice.information;
export const selectRecoveryKeys = (state) => state.intuneDevices.selectedDevice.recoveryKeys;
export const selectRecoveryKeysIsLoading = (state) => state.intuneDevices.selectedDevice.isLoading;
export const selectRecoveryKeysDetails = (state) =>
  state.intuneDevices.selectedDevice.recoveryKeyDetails;
export const selectDevicesIntuneColumns = (state) => state.intuneDevices.devicesIntuneColumns;
export const selectDeviceIntuneColumns = (state) => state.intuneDevices.deviceIntuneColumns;
export const selectDeviceIntuneActionsColumns = (state) =>
  state.intuneDevices.deviceIntuneActionsColumns;

export const selectDevices = (searchTerm) => (state) => {
  if (state.intuneDevices.devices.length > 0 && searchTerm.length > 2) {
    const specialCharacters = '[]{}()\\^$.|?*+';
    const fixedSearchTerm = searchTerm.split('').map((char) => {
      if (specialCharacters.includes(char)) {
        return `\\${char}`;
      }
      return char;
    });
    const searchTermUpdate = fixedSearchTerm.join('');
    const regexp = new RegExp(`${searchTermUpdate}`, 'gi');
    return state.intuneDevices.devices.filter(
      (device) => device.deviceName && device.deviceName.match(regexp)
    );
  }
  return state.intuneDevices.devices;
};

export const selectIsTableLoaded = (state) => {
  if (state.intuneDevices.isLoading || state.intuneDevices.devicesIntuneColumns.length === 0) {
    return false;
  }
  return true;
};

export const selectIsDeviceIntuneLoaded = (state) => {
  if (!state.intuneDevices.isLoading && state.intuneDevices.selectedDevice.information) {
    return true;
  }
  return false;
};
export default intuneDevicesSlice.reducer;
