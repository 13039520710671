export const styleAlignHorizontal = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'space-around',
  padding: '24px',
  height: '500px',
  boxShadow: '0px 4px 72px rgba(0, 0, 0, 0.08)',
  borderRadius: '16px',
  backgroundColor: 'white'
};

export const styleBox = (theme) => ({
  boxShadow: `0px 4px 72px ${theme.palette.colorSecondaryLight}`,
  borderRadius: '16px',
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: theme.palette.colorMainLight,
  height: '100%'
});

export const styleContainer = {
  display: 'flex',
  flexDirection: 'row',
  paddingLeft: '2em'
};

export const styleMobileContainer = {
  display: 'flex',
  flexDirection: 'column',
  paddingLeft: '2em',
  marginRight: '2em'
};

export const styleTypographyProfile = (theme) => ({
  fontSize: '16px',
  borderRadius: '5px',
  fontWeight: '400',
  fontStyle: 'normal',
  height: '40px',
  color: theme.palette.colorBlack
});

export const styleHeader = (theme) => ({
  boxSizing: 'border-box',
  borderBottom: `0.5px solid ${theme.palette.colorGray}`,
  padding: '2em',
  paddingLeft: '4em'
});

export const styleTitle = (theme) => ({
  fontStyle: 'normal',
  fontWeight: '600',
  lineHeight: '29px',
  color: theme.palette.colorSoftBlack,
  padding: '10px'
});

export const styleSelectContainer = (theme) => ({
  marginTop: '1em',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  '& .MuiSelect-select': {
    marginTop: '1em',
    backgroundColor: theme.palette.colorSecondaryLight,
    boxShadow: `0px 4px 8px ${theme.palette.colorSecondaryLight}`,
    borderRadius: '8px'
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none'
  }
});

export const styleGreyText = (theme) => ({
  fontStyle: 'normalize',
  fontSize: '16px',
  lineHeight: '19px',
  color: theme.palette.colorSoftBlack
});
