import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import StandardBox from '../StandardBox';
import InformationList from '../InformationList';
import hasPermissions from '../../utils/Permissions/permissions';
import { selectPermissions } from '../../redux/slices/userConnected';
import {
  selectedUser,
  updateAdUser,
  enableDisableAdUser,
  selectDefaultEnableUserText,
  selectDisableText,
  updateAdUserGroups,
  selectedGroups,
  selectUserText
} from '../../redux/slices/AdUsers';
import LdapADUserPopup from '../popup/LdapADUserPopup';
import PopupResetPassword from '../popup/ResetPassword';
import enableDisabledOU from '../../utils/adUsers';

export default function AdUserDescription() {
  const user = useSelector(selectedUser);
  const dispatch = useDispatch();
  const [displayColumns, setDisplayColumns] = useState([]);
  const { t } = useTranslation();
  const permissions = useSelector(selectPermissions);
  const [popupUpdate, setPopupUpdate] = useState(false);
  const [popupPasswordIsOpen, setPopUPpasswordIsOpen] = useState(false);
  const adGroups = useSelector(selectedGroups(''));

  const formatColumnsArray = (columnsArray) => {
    const filteredArray = columnsArray.filter(
      (column) =>
        column.name !== 'userAccountControl' &&
        column.name !== 'distinguishedName' &&
        column.name !== 'sn' &&
        column.name !== 'name'
    );

    return filteredArray;
  };

  useEffect(() => {
    if (Object.keys(user).length !== 0) {
      const columnsDisplay = Object.keys(user)
        .filter((key) => key !== 'key' && key !== 'memberOf')
        .map((key) => {
          switch (key) {
            case 'isUserEnable':
              return { name: key, key, type: 'icon' };
            default:
              return { name: key, key, type: 'string' };
          }
        });
      setDisplayColumns(formatColumnsArray(columnsDisplay));
    }
  }, [user]);

  const openPopup = () => {
    setPopupUpdate(true);
  };

  const handleEnableDisableADUser = async (distinguishedName, isUserEnable) => {
    await dispatch(enableDisableAdUser({ users: [distinguishedName], isUserEnable }));
  };

  const handleUpdateADUser = async (modifiedUser) => {
    const res = await dispatch(updateAdUser({ modifiedUser, DN: user.distinguishedName }));

    if (res.payload.success) {
      setPopupUpdate(false);
    }
  };

  const handleUpdateADUserGroups = async (modifiedGroups, oldGroups) => {
    const res = await dispatch(
      updateAdUserGroups({
        user: user.distinguishedName,
        groups: modifiedGroups,
        oldGroups
      })
    );

    if (res.payload.success) {
      setPopupUpdate(false);
    }
  };

  const navigate = useNavigate();
  const userText = useSelector(selectUserText);
  const defaultEnableUserText = useSelector(selectDefaultEnableUserText);
  const disableText = useSelector(selectDisableText);

  const [isStepChangeOU, setIsStepChangeOU] = useState(false);

  const listButton = [
    {
      hasPermissions: hasPermissions(permissions, 'SET_AD_USERS'),
      text: user?.isUserEnable ? t('disableUser') : t('enableUser'),
      datatestid: 'ADActionsEnableDisable',
      onClick: async () => {
        await handleEnableDisableADUser(user.distinguishedName, user.isUserEnable);
        const newDn = enableDisabledOU(
          user.distinguishedName,
          userText,
          disableText,
          defaultEnableUserText
        );
        navigate(`/adUsers/${newDn}`);
      }
    },
    {
      hasPermissions: hasPermissions(permissions, 'SET_AD_USERS'),
      text: `${t('editable')}`,
      datatestid: 'ADActionsEdit',
      onClick: () => {
        openPopup();
        setIsStepChangeOU(false);
      }
    },
    {
      hasPermissions: hasPermissions(permissions, 'SET_AD_USERS'),
      text: `${t('password')}`,
      datatestid: 'ADresetPassword',
      onClick: () => {
        setPopUPpasswordIsOpen(true);
      }
    }
  ];

  return (
    <Grid>
      <StandardBox
        title="AdEntity"
        buttons={listButton}
        ChildComponent={<InformationList row={user} columns={displayColumns} />}
      />
      <LdapADUserPopup
        popup={popupUpdate}
        user={user}
        closePopup={() => {
          setPopupUpdate(false);
        }}
        updateAdUser={handleUpdateADUser}
        updateAdUserGroups={handleUpdateADUserGroups}
        isStepChangeOU={isStepChangeOU}
        setIsStepChangeOU={setIsStepChangeOU}
        adGroups={adGroups}
      />
      <PopupResetPassword
        popup={popupPasswordIsOpen}
        closePopup={() => {
          setPopUPpasswordIsOpen(false);
        }}
      />
    </Grid>
  );
}
