import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { TableContainer, Paper, Checkbox, useTheme } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { StyledMUIDataTable, styleTableContainerUsers } from '../../assets/style/views/UserPages';
import styleScrollbar from '../../assets/style/utils/utils';
import identifier from '../../utils/keySelector';
import getMuiTheme from './getMuiTheme';

const CustomCheckbox = (
  rows,
  selectedRows,
  setSelectedRows,
  selectedColumns,
  setSelectedColumns,
  setSelectedAllRows,
  areAllRowsSelected
) =>
  function CustomCheckbox2(props) {
    if ({ ...props }['data-description'] === 'row-select') {
      const index = { ...props }['data-index'];
      const value = rows[index === null ? 0 : index];

      return index < rows.length ? (
        <Checkbox
          sx={(theme) => ({
            '&.Mui-checked': {
              color: `${theme.palette.colorMain} !important`
            }
          })}
          data-testid={`Checkbox${identifier(value)}`}
          onChange={() => setSelectedRows({ ...props }['data-index'])}
          checked={selectedRows.includes({ ...props }['data-index'])}
        />
      ) : null;
    }
    if ({ ...props }['data-description'] === 'table-view-col') {
      return (
        <Checkbox
          sx={(theme) => ({
            '&.Mui-checked': {
              color: `${theme.palette.colorMain} !important`
            }
          })}
          onChange={() => setSelectedColumns({ ...props }.value)}
          checked={
            selectedColumns.filter(({ name }) => name === { ...props }.value)[0].options?.display
          }
        />
      );
    }
    if ({ ...props }['data-description'] === 'row-select-header') {
      return (
        <Checkbox
          sx={(theme) => ({
            '&.Mui-checked': {
              color: `${theme.palette.colorMain} !important`
            }
          })}
          onChange={() => setSelectedAllRows()}
          checked={areAllRowsSelected}
        />
      );
    }
    return null;
  };

export default function DataListMuiTable({
  columns,
  rows,
  setTableSelectedRows,
  disableCheckBox,
  selectedIndexRows,
  searchTerm,
  page,
  rowsPerPage,
  setTotalItems,
  onFilterChange
}) {
  const [columnsMUi, setColumnsMUi] = useState(columns);
  const [selectedMuiRows, setSelectedMuiRows] = useState([]);
  const [number, setNumber] = useState(0);
  const [areAllRowsSelected, setAreAllRowsSelected] = useState(false);
  const muiTableState = {};

  useEffect(() => {
    if (columns.length === columnsMUi.length) {
      const tempColumnsMui = [...columnsMUi];
      const newColumnsMui = tempColumnsMui.map((columnMui, index) => {
        const tempColumnMui = { ...columns[index] };
        tempColumnMui.options.display = columnMui?.options?.display;
        return tempColumnMui;
      });
      setColumnsMUi(newColumnsMui);
    } else {
      setColumnsMUi(columns);
    }
  }, [columns]);

  useEffect(() => {
    setTotalItems(number);
  }, [number]);

  useEffect(() => {
    setSelectedMuiRows(selectedIndexRows);
  }, [selectedIndexRows]);

  const handleColumnViewChange = (column) => {
    const tempColumns = [...columns];
    const tempColumn = { ...columns.filter(({ name }) => name === column)[0] };
    tempColumn.options.display = !tempColumn.options.display;
    tempColumns[tempColumns.findIndex(({ name }) => name === column)] = tempColumn;
    setColumnsMUi(tempColumns);
  };

  const setSelectedRows = (row) => {
    const tempSelectedMuiRows = [...selectedMuiRows];
    if (tempSelectedMuiRows.includes(row)) {
      if (areAllRowsSelected) {
        setAreAllRowsSelected(false);
      }
      tempSelectedMuiRows.splice(tempSelectedMuiRows.indexOf(row), 1);
    } else {
      if (muiTableState.displayedRows.length - 1 === selectedMuiRows.length) {
        setAreAllRowsSelected(true);
      }
      tempSelectedMuiRows.push(row);
    }
    setSelectedMuiRows(tempSelectedMuiRows);
    setTableSelectedRows(tempSelectedMuiRows);
  };

  const setSelectedAllRows = () => {
    if (selectedMuiRows !== muiTableState.displayedRows && !areAllRowsSelected) {
      setSelectedMuiRows(muiTableState.displayedRows);
      setTableSelectedRows(muiTableState.displayedRows);
      setAreAllRowsSelected(true);
    } else {
      setSelectedMuiRows([]);
      setTableSelectedRows([]);
      setAreAllRowsSelected(false);
    }
  };

  const options = {
    filterType: 'dropdown',
    print: false,
    elevation: 8,
    page,
    rowsPerPage,
    searchable: true,
    search: false,
    searchText: searchTerm,
    downloadOptions: {
      filterOptions: { useDisplayedColumnsOnly: true, useDisplayedRowsOnly: true }
    },
    onFilterChange,
    customFooter: (num) => {
      useEffect(() => {
        setNumber(num);
      }, [num]);
    },
    customSearchRender: () => null,
    rowsPerPageOptions: [10, 25, 50],
    draggableColumns: {
      enabled: true,
      transitionTime: 100
    },
    responsive: 'vertical',
    tableBodyMaxHeight: 'calc(100vh - 420px)',
    jumpToPage: true,
    selectableRowsHideCheckboxes: disableCheckBox,
    onTableChange: (action, tableState) => {
      muiTableState.displayedRows = tableState.displayData.map(({ dataIndex }) => {
        if (dataIndex === 0) return null;
        return dataIndex;
      });
    },
    onRowClick: (data, tableData) => {
      if (!disableCheckBox)
        return setSelectedRows(tableData.dataIndex === 0 ? null : tableData.dataIndex);
      return 0;
    }
  };
  const theme = useTheme();
  return (
    <TableContainer component={Paper} sx={[styleTableContainerUsers, styleScrollbar]}>
      <ThemeProvider theme={getMuiTheme(theme)}>
        <StyledMUIDataTable
          data={rows}
          columns={columnsMUi}
          options={options}
          components={{
            Checkbox: CustomCheckbox(
              rows,
              selectedMuiRows,
              setSelectedRows,
              columnsMUi,
              handleColumnViewChange,
              setSelectedAllRows,
              areAllRowsSelected
            )
          }}
        />
      </ThemeProvider>
    </TableContainer>
  );
}

DataListMuiTable.propTypes = {
  rows: PropTypes.arrayOf(
    PropTypes.objectOf(
      PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.objectOf(
          PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.bool,
            PropTypes.arrayOf(
              PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.objectOf(
                  PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)])
                )
              ])
            )
          ])
        ),
        PropTypes.string,
        PropTypes.arrayOf(
          PropTypes.oneOfType([PropTypes.string, PropTypes.objectOf(PropTypes.string)]).isRequired
        ),
        PropTypes.bool,
        PropTypes.number
      ])
    )
  ).isRequired,
  columns: PropTypes.arrayOf(
    PropTypes.objectOf(PropTypes.oneOfType([PropTypes.object, PropTypes.string]))
  ).isRequired,
  setTableSelectedRows: PropTypes.func,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  disableCheckBox: PropTypes.bool,
  selectedIndexRows: PropTypes.arrayOf(PropTypes.number).isRequired,
  searchTerm: PropTypes.string,
  setTotalItems: PropTypes.func.isRequired,
  onFilterChange: PropTypes.func
};

DataListMuiTable.defaultProps = {
  disableCheckBox: false,
  setTableSelectedRows: () => {},
  searchTerm: '',
  onFilterChange: () => {}
};
