import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import BookmarkAddIcon from '@mui/icons-material/BookmarkAdd';
import BookmarkRemoveIcon from '@mui/icons-material/BookmarkRemove';
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';
import UnpublishedIcon from '@mui/icons-material/Unpublished';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import GroupRemoveIcon from '@mui/icons-material/GroupRemove';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import {
  selectUpdateAt,
  selectAzureUsers,
  createAzureUser,
  updateAzureUserAccount,
  getAzureUserAvailableServices,
  requestAzureUserDomains,
  refreshAzureUsers,
  findAzureUsers,
  resetAzureUserState,
  selectedAzureUsersStatus,
  selectIsLoading,
  selectIsTableDisplayable
} from '../../../redux/slices/AzureUsersList';
import {
  isNotif,
  licenseNotif,
  requestAllNotifsPagination
} from '../../../redux/slices/licenseNotification';
import {
  getAllAzureColumns,
  resetAzureColumn,
  selectAzureColumnsDisplayed
} from '../../../redux/slices/AzureColumns';
import AzureADCreateUserPopup from '../../../components/popup/AzureADCreateUserPopup';
import AzureUsersGroupsPopup from '../../../components/popup/AzureUsersGroupsPopup';
import AzureADDeleteUserPopup from '../../../components/popup/AzureADDeleteUserPopup';
import AzureLicensesPopup from '../../../components/popup/AzureLicensesPopup';
import { selectPermissions } from '../../../redux/slices/userConnected';
import {
  getLicensesAzure,
  isRequestLoading,
  resetAzureLicenseState
} from '../../../redux/slices/AzureLicenses';
import errorRedirection from '../../../utils/errorRedirection';
import AzureLicenseNotifPopup from '../../../components/popup/AzureLicenseNotifPopup';
import hasPermissions from '../../../utils/Permissions/permissions';
import { formatColumnsForTable } from '../../../components/datalist/ComponentsInTable';
import { selectAzureGroupsGraph } from '../../../redux/slices/AzureGroups';
import { styleActionIconMobileUsersAzure } from '../../../assets/style/views/UserPages';
import ItemsList from '../ItemsList';

export default function AzureUsers() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState('');
  const [invisible, setInvisible] = useState(false);
  const licenseNotification = useSelector(licenseNotif);
  const isNotification = useSelector(isNotif);
  const azureUsers = useSelector(selectAzureUsers(''));
  const azureGroups = useSelector(selectAzureGroupsGraph);
  const azureColumnsDisplayed = useSelector(selectAzureColumnsDisplayed).map(
    ({ columnName }) => columnName
  );
  const [totalItems, setTotalItems] = useState(0);
  const date = useSelector(selectUpdateAt);

  const [tableSelectedRows, setTableSelectedRows] = useState([]);
  const [selectedIndexRows, setSelectedIndexRows] = useState([]);

  const permissions = useSelector(selectPermissions);
  const isRequestLoad = useSelector(isRequestLoading);
  const navigate = useNavigate();

  useEffect(() => {
    if (isRequestLoad === true) {
      const numberOfLastNotificationWanted = 4;
      dispatch(
        requestAllNotifsPagination({
          page: 0,
          rowsPerPage: numberOfLastNotificationWanted
        })
      );
    }
  }, [isRequestLoad, tableSelectedRows]);

  const dispatchGetAllAzureColumns = async () => {
    try {
      await dispatch(getAllAzureColumns()).unwrap();
    } catch (error) {
      errorRedirection(error.code, navigate);
    }
  };
  const resetSelectedRows = () => {
    setTableSelectedRows([]);
    setSelectedIndexRows([]);
  };

  useEffect(() => {
    if (licenseNotification.length > 1) {
      setInvisible(!invisible);
    }
    dispatchGetAllAzureColumns();
    dispatch(getLicensesAzure());
    dispatch(requestAzureUserDomains());
    dispatch(getAzureUserAvailableServices());
    dispatch(findAzureUsers());
    return function cleanup() {
      dispatch(resetAzureUserState());
      dispatch(resetAzureLicenseState());
      dispatch(resetAzureColumn());
    };
  }, [dispatch]);

  const handleChangePage = (next, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
  };
  const handleRefresh = async () => {
    setPage(0);
    resetSelectedRows();
    dispatch(refreshAzureUsers());
  };
  const handleSearchTerm = (e) => {
    const { value } = e.target;
    setSearchTerm(() => value);
    setPage(0);
  };
  const [usersPopup, setUsersPopup] = useState(false);
  const [licensesPopup, setLicensePopup] = useState(false);
  const [isRemoveLicense, setIsRemoveLicense] = useState(false);
  const [usersGroupsPopup, setUsersGroupsPopup] = useState(false);
  const [isRemoveGroups, setIsRemoveGroups] = useState(false);
  const azureUsersStatus = useSelector(selectedAzureUsersStatus(tableSelectedRows));

  const handleCreateUser = async (createdUser) => {
    const res = await dispatch(createAzureUser(createdUser));
    if (res.payload.success) {
      setUsersPopup(false);
    }
  };

  const updateAccount = async (selectedUser) => {
    await dispatch(updateAzureUserAccount(selectedUser));
  };

  const [popupDel, setPopupDel] = useState(false);

  const setTableSelectedRowsRealData = (rows) => {
    const copyRows = [...rows];

    setSelectedIndexRows(copyRows);
    const tempRows = copyRows.map((rowIndex) => azureUsers[rowIndex === null ? 0 : rowIndex]);
    setTableSelectedRows(tempRows);
  };
  const isDisabled =
    tableSelectedRows.length === 1
      ? false
      : azureUsersStatus.findIndex((elem) => elem.accountEnabled === true) > -1 &&
        azureUsersStatus.findIndex((elem) => elem.accountEnabled === false) > -1;

  const usersListIsLoading = useSelector(selectIsLoading);
  const isTableDisplayable = useSelector(selectIsTableDisplayable);
  const [popupLicenseNotif, setPopupLicenseNotif] = useState(false);

  const closePopupLicenseNotif = () => {
    setPopupLicenseNotif(false);
  };

  const buttons = [
    {
      disabled: false,
      datatestid: 'AzureADCreateUser',
      isDisplayable: hasPermissions(permissions, 'ADD_AZURE_USER'),
      onClick: () => setUsersPopup(true),
      text: t('addUser'),
      icon: <PersonAddAlt1Icon sx={styleActionIconMobileUsersAzure} />
    },
    {
      disabled: tableSelectedRows.length === 0,
      datatestid: 'RemoveAzureLicenses',
      isDisplayable: hasPermissions(permissions, 'SET_REMOVE_AZURE_LICENSES_TO_USER'),
      onClick: () => {
        setIsRemoveLicense(true);
        setLicensePopup(true);
      },
      text: t('removeLicenses'),
      icon: <BookmarkRemoveIcon sx={styleActionIconMobileUsersAzure} />
    },
    {
      disabled: tableSelectedRows.length === 0,
      datatestid: 'AddAzureLicenses',
      isDisplayable: hasPermissions(permissions, 'SET_AZURE_LICENSES_TO_USER'),
      onClick: () => {
        setIsRemoveLicense(false);
        setLicensePopup(true);
      },
      text: t('addLicenses'),
      icon: <BookmarkAddIcon sx={styleActionIconMobileUsersAzure} />
    },
    {
      disabled: tableSelectedRows.length === 0 || isDisabled,
      datatestid: 'AzureADUpdateStatus',
      isDisplayable: hasPermissions(permissions, 'SET_DISABLED_AZURE_USER'),
      onClick: async () => {
        await updateAccount(tableSelectedRows.map(({ id }) => id));
        resetSelectedRows();
      },
      text: t(
        `${
          azureUsersStatus[0]?.accountEnabled === true ? `disablUserAD` : `enableAccountAzureUser`
        }`
      ),
      icon:
        azureUsersStatus[0]?.accountEnabled === false ? (
          <PublishedWithChangesIcon sx={styleActionIconMobileUsersAzure} />
        ) : (
          <UnpublishedIcon sx={styleActionIconMobileUsersAzure} />
        )
    },
    {
      disabled: tableSelectedRows.length === 0,
      datatestid: 'AzureADDeleteUser',
      isDisplayable: hasPermissions(permissions, 'DELETE_AZURE_USERS'),
      onClick: () => setPopupDel(true),
      text: t('delUser'),
      icon: <DeleteForeverIcon sx={styleActionIconMobileUsersAzure} />
    },
    {
      disabled: tableSelectedRows.length === 0,
      datatestid: 'AzureAssignGroups',
      isDisplayable: hasPermissions(permissions, 'SET_AZURE_GROUPS_TO_USER'),
      onClick: () => {
        setIsRemoveGroups(false);
        setUsersGroupsPopup(true);
      },
      text: t('assignAzureGroups'),
      icon: <GroupAddIcon sx={styleActionIconMobileUsersAzure} />
    },
    {
      disabled: tableSelectedRows.length === 0,
      datatestid: 'AzureRemoveGroups',
      isDisplayable: hasPermissions(permissions, 'SET_REMOVE_AZURE_GROUPS_TO_USER'),
      onClick: () => {
        setIsRemoveGroups(true);
        setUsersGroupsPopup(true);
      },
      text: t('removeAzureGroups'),
      icon: <GroupRemoveIcon sx={styleActionIconMobileUsersAzure} />
    }
    // {
    //   disabled: false,
    //   datatestid: 'AzureGetLicenseNotification',
    //   isDisplayable:
    //     (hasPermissions(permissions, 'SET_AZURE_LICENSES_TO_USER') ||
    //       hasPermissions(permissions, 'SET_REMOVE_AZURE_LICENSES_TO_USER')) &&
    //     licenseNotification &&
    //     licenseNotification.length >= 1 &&
    //     isNotification > 0,
    //   onClick: openPopupLicenseNotif,
    //   text: t('notification')
    // }
  ];

  const searchBar = {
    isDisplayable: true,
    disabled: false
  };

  const optionsKeys = {
    titleNameKey: 'displayName',
    secondaryTitleKey: 'userPrincipalName',
    statusKey: 'accountEnabled'
  };

  const listAccountEnabled = ['Inactif', 'Actif', 'Tous'];
  const columnOptions = [
    {
      name: 'displayName',
      type: 'LinkInTable',
      linkOptions: { linkColumn: 'displayName', linkTo: 'id' },
      display: true
    },
    {
      name: 'accountEnabled',
      type: 'CheckedIconInTable',
      display: true,
      filter: true,
      filterListOptions: listAccountEnabled
    },
    { name: 'userPrincipalName', display: true },
    { name: 'mail', display: true },
    {
      name: 'memberOf',
      type: 'ChipList',
      display: true,
      filter: true,
      filterListOptions: azureGroups?.map((group) => ({ name: group.displayName }))
    }
  ];

  const formatedColumns = formatColumnsForTable(
    azureColumnsDisplayed,
    azureUsers,
    columnOptions,
    t
  );

  const desktopConfig = {
    actions: {
      handleRefresh,
      handleSearchTerm
    },

    buttons,
    searchBar,

    data: {
      tableSelectedRows,
      setTableSelectedRows,
      setTableSelectedRowsRealData,
      items: azureUsers
    },

    loading: {
      itemsListIsLoading: usersListIsLoading,
      isTableDisplayable
    },

    tables: {
      optionsKeys,
      formatedColumns,
      date,
      totalItems,
      page,
      setPage,
      handleChangePage,
      handleChangeRowsPerPage,
      rowsPerPage,
      selectedIndexRows,
      setTotalItems
    },

    search: {
      searchTerm,
      setSearchTerm
    },

    text: {
      titleText: t('azureUsers'),
      bigTitleText: t('manageUsers'),
      selectedItemsText: t('selectedUsers'),
      tagId: 'AzureUsers'
    },
    searchBarPlaceOlder: t('searchUser')
  };

  return (
    <Grid>
      {usersPopup ? (
        <AzureADCreateUserPopup
          popup={usersPopup}
          azureUsers={azureUsers}
          closePopup={() => setUsersPopup(false)}
          createAzureUser={handleCreateUser}
        />
      ) : null}

      <AzureUsersGroupsPopup
        selectedUsers={tableSelectedRows.map(({ id }) => id)}
        isRemove={isRemoveGroups}
        popup={usersGroupsPopup}
        closePopup={() => setUsersGroupsPopup(false)}
        resetSelectedRows={resetSelectedRows}
      />
      <AzureADDeleteUserPopup
        popup={popupDel}
        closePopup={() => setPopupDel(false)}
        selectedAzureUsers={tableSelectedRows.map(({ id }) => id)}
        clearSelectedAzureUser={resetSelectedRows}
      />
      <AzureLicensesPopup
        popup={licensesPopup}
        closePopup={() => setLicensePopup(false)}
        selectedUsers={tableSelectedRows.map(({ id }) => id)}
        isRemove={isRemoveLicense}
        resetSelectedRows={resetSelectedRows}
      />
      {licenseNotification && isNotification > 0 && licenseNotification.length >= 1 && (
        <AzureLicenseNotifPopup popup={popupLicenseNotif} closePopup={closePopupLicenseNotif} />
      )}
      <ItemsList config={desktopConfig} />
    </Grid>
  );
}
