import { createTheme } from '@mui/material';
import palette from './palette';
import paletteAdmin from './paletteAdmin';

export const themeAdmin = createTheme({
  palette: paletteAdmin
});

const theme = createTheme({
  palette
});

export default theme;
