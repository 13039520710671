import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Grid } from '@mui/material';
import {
  selectJiraDevices,
  selectIsLoading,
  selectUpdateAt,
  selectJiraDevicesColumns,
  findJiraDevices,
  refreshJiraDevices,
  resetJiraDeviceState,
  selectIsTableDisplayable
} from '../../../redux/slices/JiraDevices';
import { formatColumnsForTable } from '../../../components/datalist/ComponentsInTable';
import ItemsList from '../ItemsList';

export default function DevicesJira() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [tableSelectedRows, setTableSelectedRows] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalItems, setTotalItems] = useState(0);
  const [searchTerm, setSearchTerm] = useState('');

  const allJiraDevices = useSelector(selectJiraDevices(''));
  const jiraDevicesListIsLoading = useSelector(selectIsLoading);
  const updatedDate = useSelector(selectUpdateAt);
  const jiraDevicesColumns = useSelector(selectJiraDevicesColumns).map(({ key }) => key);
  const isTableDisplayable = useSelector(selectIsTableDisplayable);
  const [selectedIndexRows, setSelectedIndexRows] = useState([]);

  const resetSelectedRows = () => {
    setTableSelectedRows([]);
    setSelectedIndexRows([]);
  };

  const setTableSelectedRowsRealData = (rows) => {
    const copyRows = [...rows];

    setSelectedIndexRows(copyRows);
    const tempRows = copyRows.map(
      (rowIndex) => jiraDevicesColumns[rowIndex === null ? 0 : rowIndex]
    );
    setTableSelectedRows(tempRows);
  };

  useEffect(() => {
    dispatch(findJiraDevices());
    return function cleanup() {
      dispatch(resetJiraDeviceState());
    };
  }, [dispatch]);

  const handleChangePage = (next, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
  };

  const handleSearchTerm = (e) => {
    const { value } = e.target;
    setSearchTerm(() => value);
    setPage(0);
  };
  const handleRefresh = async () => {
    setPage(0);
    resetSelectedRows();
    dispatch(refreshJiraDevices());
  };

  const optionsKeys = {
    titleNameKey: 'name',
    secondaryTitleKey: 'issuetype',
    statusKey: 'status'
  };

  const searchBar = {
    isDisplayable: true,
    disabled: false
  };

  const columnOptions = [
    {
      name: 'name',
      type: 'LinkInTable',
      linkOptions: { linkColumn: 'name', linkTo: 'id' },
      display: true
    },
    { name: 'issuetype', display: true, filter: true },
    { name: 'project', display: true, filter: true },
    { name: 'status', display: true, filter: true }
  ];
  const formatedColumns = formatColumnsForTable(
    jiraDevicesColumns,
    allJiraDevices,
    columnOptions,
    t
  );
  const desktopConfig = {
    actions: {
      handleRefresh,
      handleSearchTerm
    },

    buttons: [],
    searchBar,

    data: {
      tableSelectedRows,
      setTableSelectedRows,
      setTableSelectedRowsRealData,
      items: allJiraDevices
    },

    loading: {
      itemsListIsLoading: jiraDevicesListIsLoading,
      isTableDisplayable
    },

    tables: {
      optionsKeys,
      formatedColumns,
      date: updatedDate,
      totalItems,
      page,
      setPage,
      handleChangePage,
      handleChangeRowsPerPage,
      rowsPerPage,
      setTotalItems,
      selectedIndexRows
    },

    search: {
      searchTerm,
      setSearchTerm
    },

    text: {
      titleText: t('devicesJira'),
      bigTitleText: t('manageDevices'),
      selectedItemsText: t('selectedDevices'),
      tagId: 'JiraDevices'
    },

    isDisableCheckBox: true,
    searchBarPlaceOlder: t('searchDevices')
  };

  return (
    <Grid>
      <ItemsList config={desktopConfig} />
    </Grid>
  );
}
