import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  TextField,
  Grid,
  Select,
  MenuItem,
  FormHelperText,
  FormLabel,
  Autocomplete,
  Checkbox
} from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';

import { useTheme } from '@emotion/react';
import { useTranslation } from 'react-i18next';

import { width100, styleHelperText } from '../../assets/style/views/AzurADUserPopup';
import { styleCheckbox } from '../../assets/style/views/UserPages';
import styleScrollbar from '../../assets/style/utils/utils';

export default function CustomFormField({
  columnName,
  input,
  isEditable,
  isNullable,
  valueObject,
  setValueObject,
  error,
  isSubmit,
  ruleMessageKey,
  domain,
  setDomain,
  listAllDomain,
  findArgsForRegexByColumnName,
  isAd
}) {
  const theme = useTheme();
  const { t } = useTranslation();
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [blurField, setBlurField] = useState(false);

  const handleDisplayError = () =>
    (blurField || isSubmit) && error(columnName, isNullable, isEditable);

  const helperText = () => {
    if (blurField || isSubmit) {
      if (!isNullable && !valueObject[columnName]) {
        return t('fieldNull');
      }
      if (error(columnName, isNullable, isEditable)) {
        return t(ruleMessageKey);
      }
    }
    return '';
  };

  const handleChange = (columnNameField, newValue = '') => {
    const newValueObject = { ...valueObject };

    if (columnNameField === 'domain') {
      setDomain(newValue);
      if (input === 'SEMICOMPLETE') {
        const mailWhitoutDomain = newValueObject.mail.split('@')[0];
        newValueObject.mail = `${mailWhitoutDomain}@${newValue}`;
      }
    } else if (input === 'SEMICOMPLETE' && columnNameField === 'mail') {
      const currentDomain = newValueObject.mail.split('@')[1];
      newValueObject.mail = `${newValue}@${currentDomain}`;
    } else {
      newValueObject[columnNameField] = newValue;
    }
    if (newValueObject.surname?.length > 0 && newValueObject.givenName?.length > 0) {
      newValueObject.userPrincipalName = `${newValueObject.givenName}.${newValueObject.surname}`;
      if (newValueObject?.displayName)
        newValueObject.displayName = `${newValueObject.givenName} ${newValueObject.surname}`;
      if (newValueObject?.mailNickName)
        newValueObject.mailNickName = `${newValueObject.givenName}.${newValueObject.surname}`;
    }
    setValueObject(newValueObject);
  };

  return (
    <Grid
      container
      sx={{
        display: 'flex',
        justifyContent: 'center'
      }}
    >
      <Grid item xs={9}>
        <FormLabel>{isAd && columnName === 'mail' ? 'Login' : t(columnName)}</FormLabel>
      </Grid>
      {(columnName === 'userPrincipalName' && !isAd) || input === 'SEMICOMPLETE' ? (
        <Grid container item xs={9}>
          <Grid item xs={6}>
            <TextField
              sx={width100}
              value={
                valueObject[columnName]
                  ? valueObject[columnName]
                      ?.split('@')[0]
                      .normalize('NFD')
                      .replace(/[\u0300-\u036f' ]/g, '')
                      .toLowerCase()
                  : ''
              }
              error={handleDisplayError()}
              data-testid={`${columnName}Field`}
              onChange={(event) => handleChange(columnName, event.target.value)}
              onBlur={() => setBlurField(true)}
              variant="standard"
              disabled={!isEditable}
            />
          </Grid>
          <Grid item xs={6}>
            <Select
              id="select"
              labelId="select-domain"
              value={domain}
              sx={width100}
              defaultValue={listAllDomain[0]}
              onChange={(event) => {
                handleChange('domain', event.target.value);
              }}
              data-testid="select-domain"
              variant="standard"
              MenuProps={{
                PaperProps: {
                  sx: styleScrollbar
                }
              }}
            >
              {listAllDomain.map((element) => (
                <MenuItem value={element} key={element} data-testid={element}>
                  {`@${element}`}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item xs={12}>
            <FormHelperText sx={styleHelperText}>{helperText()}</FormHelperText>
          </Grid>
        </Grid>
      ) : null}
      {input === 'TEXTFIELD' || (columnName === 'userPrincipalName' && isAd) ? (
        <Grid item xs={9}>
          <TextField
            type="text"
            sx={width100}
            value={valueObject[columnName] ? valueObject[columnName] : ''}
            error={handleDisplayError()}
            helperText={helperText()}
            data-testid={`${columnName}Field`}
            onChange={(event) => handleChange(columnName, event.target.value)}
            onBlur={() => setBlurField(true)}
            variant="standard"
            disabled={!isEditable}
          />
        </Grid>
      ) : null}
      {input === 'PASSWORD' ? (
        <Grid item xs={9}>
          <form>
            <TextField
              name="new_password"
              autoComplete="on"
              type={isPasswordVisible ? 'text' : 'password'}
              sx={width100}
              error={handleDisplayError()}
              helperText={helperText()}
              data-testid={`${columnName}Field`}
              onChange={(event) => handleChange(columnName, event.target.value)}
              onBlur={() => setBlurField(true)}
              variant="standard"
              disabled={!isEditable}
              InputProps={{
                endAdornment: (
                  <IconButton
                    onClick={() => {
                      setIsPasswordVisible(!isPasswordVisible);
                    }}
                  >
                    {isPasswordVisible ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                )
              }}
            />
          </form>
        </Grid>
      ) : null}
      {input === 'AUTOCOMPLETE' ? (
        <Grid item xs={9}>
          <Autocomplete
            disablePortal
            disableClearable
            id={`combo-box-demo-${columnName}`}
            options={findArgsForRegexByColumnName(columnName)}
            sx={{ width: '100%' }}
            value={valueObject[columnName] ? valueObject[columnName] : ''}
            onChange={(event, newValue) => handleChange(columnName, newValue)}
            onBlur={() => setBlurField(true)}
            data-testid={`select-${columnName}`}
            ListboxProps={{ sx: styleScrollbar(theme) }}
            isOptionEqualToValue={(option, value) => option.value === value.value}
            disabled={!isEditable}
            renderInput={(params) => (
              <TextField
                error={handleDisplayError()}
                helperText={helperText()}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...params}
              />
            )}
          />
        </Grid>
      ) : null}
      {input === 'CHECKBOX' ? (
        <Grid item xs={9}>
          <Checkbox
            checked={valueObject[columnName] || false}
            sx={styleCheckbox(theme)}
            defaultValue={valueObject[columnName]}
            disabled={!isEditable}
            data-testid={`${columnName}Checkbox`}
            onClick={() =>
              handleChange(columnName, valueObject[columnName] ? !valueObject[columnName] : true)
            }
          />
        </Grid>
      ) : null}
    </Grid>
  );
}

CustomFormField.propTypes = {
  columnName: PropTypes.string.isRequired,
  input: PropTypes.string.isRequired,
  isEditable: PropTypes.bool.isRequired,
  isNullable: PropTypes.bool.isRequired,
  valueObject: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.bool,
      PropTypes.number,
      PropTypes.arrayOf(
        PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.objectOf(
            PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)])
          )
        ])
      ),
      PropTypes.objectOf(
        PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.arrayOf(PropTypes.string)])
      )
    ])
  ).isRequired,
  setValueObject: PropTypes.func.isRequired,
  error: PropTypes.func.isRequired,
  isSubmit: PropTypes.bool.isRequired,
  ruleMessageKey: PropTypes.string,
  domain: PropTypes.string,
  setDomain: PropTypes.func,
  listAllDomain: PropTypes.arrayOf(PropTypes.string),
  findArgsForRegexByColumnName: PropTypes.func,
  isAd: PropTypes.bool,
  password: PropTypes.string
};

CustomFormField.defaultProps = {
  ruleMessageKey: '',
  domain: '',
  setDomain: null,
  listAllDomain: null,
  findArgsForRegexByColumnName: null,
  isAd: false,
  password: ''
};
