export const API_VERSION = process.env.REACT_APP_API_VERSION;
export const BACK_URL = `${window.location.protocol}//${window.location.hostname}/`;
const API_URL = `${BACK_URL}${API_VERSION}`;
export default API_URL;

export const permissionNames = [
  'GET_AZURE_USERS',
  'ADD_AZURE_USER',
  'SET_AZURE_USER',
  'SET_DISABLED_AZURE_USER',
  'SET_RESET_PASSWORD_AZURE_USER',
  'GET_REQUESTS',
  'GET_REQUESTS_IN_PROGRESS',
  'GET_YOUR_REQUESTS',
  'GET_MAILS',
  'GET_INTUNE_DEVICES',
  'GET_ONE_INTUNE_DEVICE',
  'GET_YOUR_INTUNE_DEVICE',
  'SET_INTUNE_DEVICE_TO_USER',
  'SET_INTUNE_DEVICE',
  'GET_INTUNE_DEVICE_KEY',
  'GET_SMS',
  'ADD_SMS',
  'GET_AZURE_LICENSES',
  'SET_AZURE_LICENSES_TO_USER',
  'SET_REMOVE_AZURE_LICENSES_TO_USER',
  'SET_AZURE_COLUMNS_DISPLAY',
  'GET_DASHBOARD',
  'GET_SETTINGS',
  'DELETE_AZURE_USERS',
  'GET_JIRA_DEVICES',
  'GET_ONE_JIRA_DEVICE',
  'GET_JIRA_USERS',
  'GET_ONE_JIRA_USER',
  'GET_JIRA_APP_ROLES',
  'GET_SYNERYX_USERS',
  'ADD_JIRA_DEVICE_COMMENT',
  'GET_JIRA_DEVICE_COMMENT',
  'SET_JIRA_DEVICE_REPORTER',
  'SET_JIRA_USER_ENABLE',
  'REBOOT_INTUNE_DEVICE',
  'GET_JIRA_GROUPS',
  'SET_JIRA_GROUPS_TO_USER',
  'SET_REMOVE_JIRA_GROUPS_TO_USER',
  'DELETE_AD_USERS',
  'SET_AD_USERS',
  'ADD_AD_USERS',
  'ASSIGN_GROUPS_AD_USERS',
  'DELETE_GROUPS_AD_USERS',
  'EDIT_GROUPS_AD_USERS',
  'GET_AD_DISABLE_TEXT',
  'GET_AD_USERS',
  'SET_AZURE_GROUPS_TO_USER',
  'SET_REMOVE_AZURE_GROUPS_TO_USER',
  'GET_AZURE_COLUMNS_DISPLAY',
  'GET_AZURE_USER_GROUPS',
  'GET_ONE_AZURE_USER',
  'SET_PRIVILEGE',
  'GET_USER_DOMAINS',
  'GET_USER_SERVICES',
  'GET_AZURE_NOTIFICATIONS',
  'GET_ONE_AZURE_NOTIFICATION',
  'SET_AZURE_NOTIFICATION',
  'GET_AZURE_FILIA_LABEL',
  'BACK_OFFICE',
  'GET_ACTIONS_HISTORY',
  'GET_STATUS'
];
export const providerNames = [
  'ACTIVE_DIRECTORY',
  'AZURE_ACTIVE_DIRECTORY',
  'BOONDMANAGER',
  'INTUNE',
  'JIRA',
  'RCS_GOOGLE',
  'AZURE_FRONT'
];
export const statusNames = [
  'notDelivered',
  'delivered',
  'read',
  'sent',
  'received',
  'inProgress',
  'done',
  'rejected'
];
