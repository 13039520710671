import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Toolbar, Box, CardMedia, IconButton, Badge, Typography } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { useSelector } from 'react-redux';
import {
  styleHeader,
  styleSideBarBtn,
  styleImgFit
} from '../../assets/style/components/layout/Header';
import { styleCenter, styleWidth, styleIconSize } from '../../assets/theme/utils';
import logo from '../../assets/images/logo_white_pegasus.png';
import { selectNotReadCounter } from '../../redux/slices/Messages';
import SocketListener from '../../socket/MainSocketListener';
import { getParametersNoConnected, formatParameter } from '../../utils/parametersPublic';
import { BACK_URL } from '../../constants';

export default function Header({ toggleDrawer, isOpenMobileSideBar }) {
  const notifCount = useSelector(selectNotReadCounter);
  const [parametersNotConnected, setParametersNotConnected] = useState([]);
  const [logoBack, setLogoBack] = useState('');
  const [appTitle, setAppTitle] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      await getParametersNoConnected(setParametersNotConnected);
    };
    fetchData();
  }, []);
  useEffect(() => {
    const fetchLogo = async () => {
      const appName = formatParameter(parametersNotConnected, 'appName', 'Syneryx');
      const appLogo = formatParameter(
        parametersNotConnected,
        'appLogo',
        logo,
        (logoImage) => `${BACK_URL}api/images/${logoImage}`
      );
      setLogoBack(appLogo);
      setAppTitle(appName);
    };
    fetchLogo();
  }, [parametersNotConnected]);

  return (
    <Toolbar sx={styleHeader}>
      <SocketListener />
      <Box sx={styleSideBarBtn}>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={toggleDrawer(!isOpenMobileSideBar)}
        >
          {notifCount ? (
            <Badge badgeContent={notifCount} color="warning">
              <MenuIcon sx={styleIconSize(30)} />
            </Badge>
          ) : (
            <MenuIcon sx={styleIconSize(30)} />
          )}
        </IconButton>
      </Box>
      <Box sx={styleWidth(180)}>
        <CardMedia sx={styleImgFit} component="img" image={logoBack} alt="Logo_appli" />
      </Box>
      <Box sx={styleCenter}>
        <Box sx={styleSideBarBtn} />
        <Typography
          sx={(theme) => ({
            color: theme.palette.colorMainLight,
            fontWeight: '400',
            fontSize: '25px',
            fontFamily: 'system-ui'
          })}
        >
          {appTitle}
        </Typography>
      </Box>
    </Toolbar>
  );
}

Header.propTypes = {
  toggleDrawer: PropTypes.func.isRequired,
  isOpenMobileSideBar: PropTypes.bool.isRequired
};
