import { Grid } from '@mui/material';
import { styled } from '@mui/material/styles';

export const styleOpenedMixin = () => ({
  width: '240px',
  overflowX: 'hidden',
  borderRight: 'none'
});

export const styleIconChevron = (theme) => ({
  color: theme.palette.colorMainDark,
  outline: 'solid',
  outlineColor: theme.palette.colorMainDark,
  background: theme.palette.colorMainLight,
  '&:hover': { background: theme.palette.colorMainLight }
});

export const styleClosedMixin = () => ({
  overflowX: 'hidden',
  width: '120px'
});

export const styleDrawer = (theme) => ({
  background: theme.palette.colorMainGradient,
  height: '100%'
});

export const styleDrawerDesktop = {
  display: { xs: 'none', sm: 'flex' },
  position: 'relative',
  zIndex: '1'
};

export const styleDrawerHeader = (open) => ({
  position: 'fixed',
  zIndex: '1000',
  left: open ? '217px' : '97px',
  top: '60vh'
});

export const styleIcon = (theme) => ({
  color: theme.palette.colorMainLight
});

export const classes = {
  sideBarWrapper: 'sideBarWrapper',
  sideBarList: 'sideBarList',
  sideBarTitle: 'sideBarTitle',
  sideBarLogo: 'sideBarLogo',
  sideBarOpen: 'sideBarOpen',
  sideBarClosed: 'sideBarClosed',
  sideBarMenuText: 'sideBarMenuText',
  sideBarAccountOptionsWrapper: 'sideBarAccountOptionsWrapper',
  sideBarAccountText: 'sideBarAccountText',
  sideBarListItem: 'sideBarListItem',
  sideBarListItemProfile: 'sideBarListItemProfile',
  sideBarLink: 'sideBarLink',
  sideBarButtonList: 'sideBarButtonList',
  sideBarProfileText: 'sideBarProfileText',
  sideBarItemWithChild: 'sideBarItemWithChild',
  sideBarListItemIcon: 'sideBarListItemIcon',
  sideBarListItemText: 'sideBarListItemText',
  sideBarChevronItem: 'sideBarChevronItem',
  sideBarCustomizedListItem: 'sideBarCustomizedListItem',
  sideBarCustomizedListItemChild: 'sideBarCustomizedListItemChild',
  sideBarCustomizedListItemParent: 'sideBarCustomizedListItemParent',
  sideBarCustomizedListItemButton: 'sideBarCustomizedListItemButton',
  sideBarCustomizedListItemIcon: 'sideBarCustomizedListItemIcon',
  sideBarCustomizedListItemPoint: 'sideBarCustomizedListItemPoint',
  sideBarCustomizedListItemText: 'sideBarCustomizedListItemText',
  sideBarBoxItem: 'sideBarBoxItem',
  sideBarBoxItemWithChild: 'sideBarBoxItemWithChild',
  sideBarMobile: 'sideBarMobile',
  styleLink: 'styleLink',
  sideBarClosedNone: 'sideBarClosedNone',
  sideBarMobileBox: 'sideBarMobileBox',
  sideBarMobileListItem: 'sideBarMobileListItem',
  sideBarMobileLink: 'sideBarMobileLink',
  sideBarMobileListButton: 'sideBarMobileListButton',
  sideBarMobileListIcon: 'sideBarMobileListIcon',
  sideBarMobileListItemText: 'sideBarMobileListItemText',
  sideBarMobileListItemTypo: 'sideBarMobileListItemTypo'
};

export const StyleSideBarWrapper = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    [`& .MuiDrawer-paper .${classes.sideBarCustomizedListItemIcon}`]: {
      color: 'red'
    }
  },
  [`&.${classes.sideBarWrapper}`]: {
    background: theme.palette.colorMainGradient,
    height: '100%',
    overflowY: 'auto',
    overflowX: 'hidden',
    '::-webkit-scrollbar': {
      width: '8px',
      height: '8px'
    },
    '::-webkit-scrollbar-track': {
      background: theme.palette.colorMainHover,
      borderRadius: '5px'
    },
    '::-webkit-scrollbar-thumb': {
      background: theme.palette.colorMainDark,
      borderRadius: '5px'
    },
    '::-webkit-scrollbar-thumb:hover': {
      background: theme.palette.colorMain
    },
    '::-webkit-scrollbar-corner': {
      background: 'transparent'
    },
    [`& .${classes.sideBarList}`]: {
      marginLeft: '15px',
      marginRight: '15px'
    },
    [`& .${classes.sideBarTitle}`]: {
      margin: '28px',
      marginLeft: '32px',
      color: theme.palette.colorMainLight,
      fontWeight: '900',
      lineHeight: '40px',
      fontSize: '39px',
      fontFamily: 'system-ui'
    },
    [`& .${classes.sideBarLogo}`]: {
      marginTop: '18px',
      marginBottom: '18px',
      marginLeft: '30px',
      display: 'flex',
      justifyContent: 'center',
      color: theme.palette.colorMainDark,
      background: theme.palette.colorMainLight,
      borderRadius: '50%',
      padding: '10px',
      fontWeight: '900',
      lineHeight: '40px',
      fontSize: '39px',
      width: '39px',
      fontFamily: 'system-ui'
    },
    [`& .${classes.sideBarMenuText}`]: {
      color: theme.palette.colorMainLight,
      lineHeight: '40px',
      fontSize: '14px',
      fontFamily: 'inherit'
    },
    [`& .${classes.sideBarAccountOptionsWrapper}`]: {
      display: 'flex',
      width: '100%',
      flexDirection: 'column',
      justifyContent: 'end'
    },
    [`& .${classes.sideBarAccountText}`]: {
      marginLeft: '32px',
      color: theme.palette.colorMainLight,
      lineHeight: '40px',
      fontSize: '14px',
      fontFamily: 'inherit'
    },
    [`& .${classes.sideBarListItem}`]: {
      marginTop: '10px',
      borderRadius: '8px',
      '&:hover': {
        background: theme.palette.colorMainHover
      },
      '&.Mui-selected': {
        backgroundColor: theme.palette.colorMainSelected
      }
    },
    [`& .${classes.sideBarListItemProfile}`]: {
      background: theme.palette.colorMainHover,
      borderRadius: '0px'
    },
    [`& .${classes.sideBarLink}`]: {
      textDecoration: 'none',
      color: theme.palette.colorMainLight
    },
    [`& .${classes.sideBarButtonList}`]: {
      paddingTop: '16px',
      paddingBottom: '16px',
      noWrap: true
    },
    [`& .${classes.sideBarProfileText}`]: {
      maxWidth: '110px',
      height: '19px',
      fontFamily: 'Nunito',
      fontStyle: 'normal',
      fontWeight: '500',
      fontSize: '14px',
      lineHeight: '19px',
      color: theme.palette.colorMainLight,
      marginLeft: '10px'
    },
    [`& .${classes.sideBarItemWithChild}`]: {
      marginTop: '10px',
      borderRadius: '8px',
      '&:hover': {
        background: theme.palette.colorMainHover
      },
      '&.Mui-selected': {
        backgroundColor: theme.palette.colorMainSelected
      }
    },
    [`& .${classes.sideBarListItemIcon}`]: {
      color: theme.palette.colorMainLight
    },
    [`& .${classes.sideBarListItemText}`]: {
      maxWidth: '110px',
      height: '19px',
      fontFamily: 'Nunito',
      fontStyle: 'normal',
      fontWeight: '500',
      fontSize: '14px',
      lineHeight: '19px',
      color: theme.palette.colorMainLight
    },
    [`& .${classes.sideBarChevronItem}`]: {
      color: theme.palette.colorMainLight
    },
    [`& .${classes.sideBarCustomizedListItem}`]: {
      marginTop: '10px',
      borderRadius: '8px',
      transition: 'outline 0.5s'
    },
    [`& .${classes.sideBarCustomizedListItemChild}`]: {
      [`& .${classes.sideBarCustomizedListItemPoint}`]: {
        color: theme.palette.colorGray
      },
      '&:hover': {
        background: 'transparent',
        outline: `1px solid ${theme.palette.colorMainLight}`,
        [`& .${classes.sideBarCustomizedListItemPoint}`]: {
          color: theme.palette.colorMainLight
        }
      },
      '&.Mui-selected': {
        backgroundColor: 'transparent',
        outline: `1px solid ${theme.palette.colorMainLight}`,
        [`& .${classes.sideBarCustomizedListItemPoint}`]: {
          color: theme.palette.colorMainLight
        }
      }
    },
    [`& .${classes.sideBarCustomizedListItemParent}`]: {
      '&:hover': {
        background: theme.palette.colorMainHover,
        outline: undefined
      },
      '&.Mui-selected': {
        backgroundColor: theme.palette.colorMainSelected,
        outline: undefined
      }
    },
    [`& .${classes.sideBarCustomizedListItemButton}`]: {
      paddingTop: '16px',
      paddingBottom: '16px'
    },
    [`& .${classes.sideBarCustomizedListItemIcon}`]: {
      color: theme.palette.colorMainLight
    },
    [`& .${classes.sideBarCustomizedListItemText}`]: {
      height: '19px',
      fontFamily: 'Nunito',
      fontStyle: 'normal',
      fontWeight: '500',
      fontSize: '14px',
      lineHeight: '19px',
      color: theme.palette.colorMainLight,
      textOverflow: 'ellipsis'
    },
    [`& .${classes.sideBarBoxItem}`]: {
      marginTop: '10px'
    },
    [`& .${classes.sideBarBoxItemWithChild}`]: {
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center'
    },
    [`& .${classes.sideBarLink}`]: {
      textDecoration: 'none',
      width: '100%',
      color: theme.palette.colorMainLight
    }
  },
  [`&.${classes.sideBarClosed}`]: {
    [`& .${classes.sideBarMenuText}`]: {
      display: 'flex',
      justifyContent: 'center'
    },
    [`& .${classes.sideBarListItem}`]: {
      display: 'flex',
      justifyContent: 'center'
    },
    [`& .${classes.sideBarButtonList}`]: {
      justifyContent: 'center'
    },
    [`& .${classes.sideBarProfileText}`]: {
      display: 'none'
    },
    [`& .${classes.sideBarListItemIcon}`]: {
      justifyContent: 'center'
    },
    [`& .${classes.sideBarListItemText}`]: {
      display: 'none'
    },
    [`& .${classes.sideBarChevronItem}`]: {
      display: 'none'
    },
    [`& .${classes.sideBarCustomizedListItem}`]: {
      display: 'flex',
      justifyContent: 'center'
    },
    [`& .${classes.sideBarCustomizedListItemButton}`]: {
      justifyContent: 'center'
    },
    [`& .${classes.sideBarCustomizedListItemIcon}`]: {
      justifyContent: 'center'
    },
    [`& .${classes.sideBarCustomizedListItemText}`]: {
      display: 'none'
    },
    [`& .${classes.sideBarClosedNone}`]: {
      display: 'none'
    }
  },
  [`&.${classes.sideBarOpen}`]: {
    [`& .${classes.sideBarMenuText}`]: {
      marginLeft: '32px'
    },
    [`& .${classes.sideBarListItem}`]: {
      display: 'block'
    },
    [`& .${classes.sideBarCustomizedListItem}`]: {
      display: 'block',
      justifyContent: undefined
    },
    [`& .${classes.sideBarCustomizedListItemButton}`]: {
      width: '210px'
    }
  },
  [`&.${classes.sideBarMobile}`]: {
    [`& .${classes.sideBarList}`]: {
      margin: '0px',
      paddingBottom: '0px'
    },
    [`& .${classes.sideBarListItem}`]: {
      marginTop: '0px',
      borderRadius: '0px'
    },
    [`& .${classes.sideBarCustomizedListItem}`]: {
      marginTop: '0px',
      borderRadius: '0px'
    },
    [`& .${classes.sideBarItemWithChild}`]: { marginTop: '0px' },
    [`& .${classes.sideBarButtonList}`]: {
      paddingTop: '16px',
      paddingBottom: '16px',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between'
    },
    [`& .${classes.sideBarCustomizedListItemButton}`]: {
      width: '100%'
    },
    [`& .${classes.sideBarBoxItem}`]: { marginTop: '0px' },
    [`& .${classes.sideBarCustomizedListItemText}`]: {
      width: '100%'
    },
    [`& .${classes.sideBarMobileListItem}`]: {
      marginTop: '10px',
      display: 'block',
      '&:hover': {
        background: theme.palette.colorMainHover
      },
      '&.Mui-selected': {
        backgroundColor: theme.palette.colorMainSelected
      },
      background: theme.palette.colorMainHover,
      borderRadius: '0px'
    },
    [`& .${classes.sideBarMobileLink}`]: {
      textDecoration: 'none',
      width: '100%',
      color: theme.palette.colorMainLight
    },
    [`& .${classes.sideBarMobileListIcon}`]: {
      color: theme.palette.colorMainLight
    },
    [`& .${classes.sideBarMobileListItemText}`]: {
      maxWidth: '110px',
      height: '19px',
      fontFamily: 'Nunito',
      fontStyle: 'normal',
      fontWeight: '500',
      fontSize: '14px',
      lineHeight: '19px',
      color: theme.palette.colorMainLight,
      marginLeft: '10px'
    },
    [`& .${classes.sideBarMobileListItemTypo}`]: {
      maxWidth: '110px',
      height: '19px',
      fontFamily: 'Nunito',
      fontStyle: 'normal',
      fontWeight: '500',
      lineHeight: '19px',
      color: theme.palette.colorMainLight,
      marginLeft: '10px',
      fontSize: '15px'
    },
    [`& .${classes.sideBarMobileListButton}`]: {
      paddingTop: '16px',
      paddingBottom: '16px'
    }
  }
}));
