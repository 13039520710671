import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { addNotification } from '../redux/slices/notificationSlice';
import socket from './socket';
import hasPermissions from '../utils/Permissions/permissions';
import delay from '../utils/delay';
import { setUsersListUpdateAt, addUsersToList } from '../redux/slices/AzureUsersList';
import { addJiraUsersToList, setJiraUserListUpdatedTime } from '../redux/slices/JiraUsers';
import {
  addIntuneDevicesToList,
  setIntuneDeviceListUpdatedTime
} from '../redux/slices/IntuneDevices';
import {
  addJiraDevicesToList,
  setJiraDevicesListUpdateAt,
  setIsLoading,
  setJiraDevicesColumns
} from '../redux/slices/JiraDevices';
import {
  selectPickedConversation,
  setConversationList,
  setIsTyping,
  updateConversationList,
  updateSelectedMessages,
  setIsRead,
  setNotReadCounter,
  findNotReadMessages
} from '../redux/slices/Messages';
import { selectPermissions } from '../redux/slices/userConnected';
import { addAdUsersToList, fillAdColumns, setAdUsersListUpdateAt } from '../redux/slices/AdUsers';
import {
  addBoondResourcesToList,
  setBoondResourcesListUpdatedTime
} from '../redux/slices/BoondManagerResources';

export default function SocketListener() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const permissions = useSelector(selectPermissions);

  const selectedConversation = useSelector(selectPickedConversation);
  const location = useLocation();

  useEffect(() => {
    const token = localStorage.getItem('token');
    const dataSession = localStorage.getItem('dataSession');
    socket.removeAllListeners();
    socket.connect();
    socket.auth = { token, dataSession };

    socket.on('connect_error', () => {
      dispatch(addNotification({ status: 'error', key: 'networkError' }));
    });

    socket.on('socket_error', (error) => {
      dispatch(addNotification({ status: 'error', key: error }));
    });

    socket.on('permission:denied', () => {
      navigate(`/error/403`);
    });
    return function cleanup() {
      socket.disconnect();
    };
  }, [dispatch]);

  useEffect(() => {
    if (permissions && hasPermissions(permissions, 'GET_AZURE_USERS')) {
      socket.on('azure-users:list', (users) => {
        dispatch(addUsersToList({ users }));
      });
      socket.on('azure-users:updateAt', (usersAzureUpdateAt) => {
        dispatch(setUsersListUpdateAt(usersAzureUpdateAt));
      });
    }

    if (permissions && hasPermissions(permissions, 'GET_BOONDMANAGER_RESOURCES')) {
      socket.on('boond-resources:list', (users) => {
        dispatch(addBoondResourcesToList({ users }));
      });
      socket.on('boond-resources:updateAt', (boondResourcesUpdatedAt) => {
        dispatch(setBoondResourcesListUpdatedTime(boondResourcesUpdatedAt));
      });
    }

    if (permissions && hasPermissions(permissions, 'GET_AD_USERS')) {
      socket.on('ad-users:columns', (columns) => {
        dispatch(fillAdColumns(columns));
      });

      socket.on('ad-users:list', (users) => {
        dispatch(addAdUsersToList({ users }));
      });
      socket.on('ad-users:updateAt', (usersAzureUpdateAt) => {
        dispatch(setAdUsersListUpdateAt(usersAzureUpdateAt));
      });
    }

    if (permissions && hasPermissions(permissions, 'GET_JIRA_DEVICES')) {
      socket.on('jira-device:list', (issues) => {
        dispatch(addJiraDevicesToList({ issues }));
      });
      socket.on('jira-device:updateAt', (jiraDevicesListUpdateAt) => {
        dispatch(setJiraDevicesListUpdateAt(jiraDevicesListUpdateAt));
        dispatch(setIsLoading(false));
      });
      socket.on('jira-device:columns', (JiraDevicesColumns) => {
        dispatch(setJiraDevicesColumns(JiraDevicesColumns));
        dispatch(setIsLoading(false));
      });
    }

    if (permissions && hasPermissions(permissions, 'GET_JIRA_USERS')) {
      socket.on('jira-users:list', (users) => {
        dispatch(addJiraUsersToList({ users }));
      });
      socket.on('jira-users:updateAt', (jiraUserListUpdateTime) => {
        dispatch(setJiraUserListUpdatedTime(jiraUserListUpdateTime));
      });
    }

    if (permissions && hasPermissions(permissions, 'GET_INTUNE_DEVICES')) {
      socket.on('azure-devices:list', (devices) => {
        dispatch(addIntuneDevicesToList({ devices }));
      });
      socket.on('azure-devices:updateAt', (jiraUserListUpdateTime) => {
        dispatch(setIntuneDeviceListUpdatedTime(jiraUserListUpdateTime));
      });
    }

    dispatch(findNotReadMessages());
    socket.on('find-unread-messages:count', (count) => {
      dispatch(setNotReadCounter(count));
    });
    socket.on('conversations-list:send', (list) => {
      dispatch(setConversationList(list));
    });

    return function cleanup() {
      socket.removeListener('jira-users:updateAt');
      socket.removeListener('jira-users:list');
      socket.removeListener('jira-device:list');
      socket.removeListener('jira-device:updateAt');
      socket.removeListener('jira-device:columns');
      socket.removeListener('conversations-list:send');
      socket.removeListener('azure-users:updateAt');
      socket.removeListener('azure-users:list');
      socket.removeListener('azure-devices:list');
      socket.removeListener('azure-devices:updateAt');
      socket.removeListener('ad-users:list');
      socket.removeListener('ad-users:updateAt');
      socket.removeListener('boond-resources:list');
      socket.removeListener('boond-resources:updateAt');
    };
  }, [permissions, dispatch]);

  useEffect(() => {
    const tenantId = 1;

    if (permissions && hasPermissions(permissions, 'GET_SMS')) {
      socket.on(`${tenantId}:find-unread-messages:count`, (unreadCount) => {
        dispatch(setNotReadCounter(unreadCount));
      });
      // TO DO SIP-760
      if (location.pathname === '/SMS') {
        socket.on(`${tenantId}:message:received`, (message) => {
          dispatch(updateConversationList(message));
          if (message.phoneNumber.id === selectedConversation.phoneNumberId) {
            dispatch(updateSelectedMessages(message));
            if (message?.readTime) {
              dispatch(setIsRead());
            }
          }
        });
        socket.on(`${tenantId}:message:isTyping`, (details) => {
          if (details.phoneNumber.id === selectedConversation.phoneNumberId) {
            dispatch(setIsTyping(details.isTyping));
            delay(20000).then(() => dispatch(setIsTyping(false)));
          }
        });
      }
    }
    return function cleanup() {
      socket.removeListener(`${tenantId}:find-unread-messages:count`);
      socket.removeListener(`${tenantId}:message:received`);
      socket.removeListener(`${tenantId}:message:isTyping`);
    };
  }, [selectedConversation, permissions, dispatch]);

  return <> </>;
}
