import { combineReducers } from 'redux';
import userConnected from './userConnected';
import usersList from './usersList';
import notificationSlice from './notificationSlice';
import azureUsersList from './AzureUsersList';
import licenseNotifSlice from './licenseNotification';
import azureColumnsList from './AzureColumns';
import messageSlice from './Messages';
import azureLicensesList from './AzureLicenses';
import jiraDeviceSlice from './JiraDevices';
import intuneDeviceSlice from './IntuneDevices';
import jiraUserSlice from './JiraUsers';
import adUserSlice from './AdUsers';
import providerSlice from './Providers';
import azureGroupSlice from './AzureGroups';
import appList from './AppList';
import authGroupsSlice from './AuthGroups';
import actionsHistory from './ActionsHistory';
import permissionHistory from './PermissionsHistory';
import boondResourceSlice from './BoondManagerResources';
import parameterList from './Parameters';

const appReducer = combineReducers({
  user: userConnected,
  users: usersList,
  notifications: notificationSlice,
  azureUsers: azureUsersList,
  azureLicenses: azureLicensesList,
  azureColumns: azureColumnsList,
  messages: messageSlice,
  licenseNotif: licenseNotifSlice,
  jiraDevices: jiraDeviceSlice,
  intuneDevices: intuneDeviceSlice,
  jiraUsers: jiraUserSlice,
  adUsers: adUserSlice,
  providers: providerSlice,
  azureGroups: azureGroupSlice,
  appLists: appList,
  authGroups: authGroupsSlice,
  actions: actionsHistory,
  permissionsHistory: permissionHistory,
  boondResources: boondResourceSlice,
  parameters: parameterList
});

const rootReducer = (state, action) => {
  if (action.type === 'user/logout') {
    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};

export default rootReducer;
