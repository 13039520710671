import axios from 'axios';
import API_URL from '../../constants';

const UsersSuperAdminService = {
  updateAzureUser(modifiedUser) {
    return axios.patch(`${API_URL}/users/azure/${modifiedUser.id}`, modifiedUser);
  },
  createAzureUser(createdUser) {
    return axios.post(`${API_URL}/users/azure`, createdUser);
  },
  changePasswordAzureUser(idUser, changePassword) {
    return axios.post(`${API_URL}/users/azure/changePassword/${idUser}/${changePassword}`);
  },
  updateAzureUserAccount(usersId) {
    return axios.patch(`${API_URL}/users/azure/updateAccount`, usersId);
  },
  deleteAzureUser(deletedUser) {
    return axios.delete(`${API_URL}/users/azure`, { data: deletedUser });
  },
  deleteAdUser(users) {
    return axios.delete(`${API_URL}/users/ad`, { data: { users } });
  },
  assignGroupsAdUser(users, groups) {
    return axios.put(`${API_URL}/users/ad/groups`, { users, groups });
  },
  deleteGroupsAdUser(users, groups) {
    return axios.put(`${API_URL}/users/ad/removeGroups`, { users, groups });
  },
  assignJiraGroups(data) {
    return axios.post(`${API_URL}/jira/groups/assign`, data);
  },
  removeJiraGroups(data) {
    return axios.delete(`${API_URL}/jira/groups/remove`, { data });
  },
  resetUserPassword({ DN, body }) {
    return axios.put(`${API_URL}/users/ad/resetPassword`, { DN, resetBody: body });
  }
};

export default UsersSuperAdminService;
