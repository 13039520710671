import axios from 'axios';
import API_URL from '../../constants';

const AuthService = {
  loginProfile(profile) {
    return axios.post(`${API_URL}/auth/login`, profile);
  },
  loginADProfile(profile) {
    return axios.post(`${API_URL}/auth/ad/login`, profile);
  },
  loginAzure(token) {
    return axios.post(`${API_URL}/auth/microsoft/login`, token);
  },
  getAzureFrontAuth() {
    return axios.get(`${API_URL}/auth/microsoft/frontAuth`);
  },
  getAuthProviders() {
    return axios.get(`${API_URL}/auth/providers`);
  }
};

export default AuthService;
