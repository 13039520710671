import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Grid,
  InputAdornment,
  InputLabel,
  TextField,
  Typography,
  useTheme
} from '@mui/material';
import { Search } from '@mui/icons-material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useTranslation } from 'react-i18next';
import {
  styleHeader,
  styleHeaderSearchAndRefresh,
  styleHeaderTitleAndButton,
  styleTypographyHeader,
  styleTypographyHeaderBigger
} from '../assets/style/components/HeaderPage';
import CustomizedButton from './buttons/CustomizedButton';
import {
  styleButtonArrowBack,
  styleButtonIconArrowBack,
  styleHeaderArrowBack
} from '../assets/style/components/StandardBox';

export default function HeaderPage({ headerPageConfig }) {
  const { t } = useTranslation();
  const { titleText } = headerPageConfig.text;
  const { bigTitleText } = headerPageConfig.text;
  const { searchBarPlaceOlder } = headerPageConfig;
  const { tagId } = headerPageConfig.text;

  const { buttons } = headerPageConfig;
  const { searchBar = { isDisplayable: false, disabled: false } } = headerPageConfig || {};
  const { itemsListIsLoading = false } = headerPageConfig.loading || {};
  const { handleSearchTerm = () => null } = headerPageConfig.actions || {};
  const { buttonReturn = { isDisplayable: false } } = headerPageConfig || {};
  const { isPositionFixed } = headerPageConfig;

  const { isSlowSearch = false } = headerPageConfig || {};
  const [searchTermLocal, setSearchTermLocal] = useState('');
  const [currentSearch, setCurrentSearch] = useState('');
  const theme = useTheme();
  return (
    <Grid container sx={styleHeader(theme, isPositionFixed)}>
      <Grid item xs={9} sx={styleHeaderTitleAndButton}>
        <Typography sx={styleTypographyHeader} data-testid="titleHeadPage">
          {titleText}
        </Typography>
        <Typography sx={styleTypographyHeaderBigger} data-testid="titleDisplayName">
          {bigTitleText}
        </Typography>
        {buttons && buttons.length > 0 && (
          <Grid container direction="row" marginTop={{ xs: '7px', sm: '12px', md: '17px' }}>
            {buttons.map(({ isDisplayable, disabled, datatestid, onClick, text }) =>
              isDisplayable ? (
                <CustomizedButton
                  key={datatestid}
                  variant="contained"
                  disabled={disabled}
                  datatestid={datatestid}
                  onClick={onClick}
                  text={text}
                />
              ) : null
            )}
          </Grid>
        )}
      </Grid>
      <Grid container item xs={3} sx={styleHeaderSearchAndRefresh}>
        {searchBar.isDisplayable ? (
          <Grid>
            {isSlowSearch && currentSearch.length ? (
              <InputLabel
                sx={{
                  width: '100%',
                  justifyContent: 'center',
                  display: 'flex',
                  fontSize: '15px',
                  fontWeight: 'bold'
                }}
              >
                {`${t('currentSearch')} ${currentSearch}`}
              </InputLabel>
            ) : null}
            <TextField
              disabled={itemsListIsLoading}
              id={`${tagId}SearchBar`}
              type="text"
              name="searchBar"
              placeholder={searchBarPlaceOlder}
              onChange={(e) => {
                if (!isSlowSearch) handleSearchTerm(e);
                setSearchTermLocal(e.target.value);
              }}
              data-testid={`search${tagId}`}
              sx={{
                '& .MuiInputBase-input': { paddingTop: '8px', paddingBottom: '8px' },
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: theme.palette.colorSecondaryLight,
                    borderRadius: '10px'
                  },
                  '&:hover fieldset': {
                    borderColor: theme.palette.colorSoftBlack,
                    borderRadius: '10px'
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: theme.palette.colorMainSelected,
                    borderRadius: '10px'
                  }
                }
              }}
              InputProps={{
                endAdornment: (
                  <Button
                    sx={{
                      width: '38px',
                      height: '38px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      padding: '1px',
                      background: 'none',
                      border: 'none',
                      '&:hover': { background: 'none' }
                    }}
                    type="button"
                    onClick={() => {
                      if (isSlowSearch) {
                        handleSearchTerm(searchTermLocal);
                        setCurrentSearch(searchTermLocal);
                      }
                    }}
                    disabled={!isSlowSearch}
                  >
                    <InputAdornment position="end">
                      <Search
                        style={{ color: theme.palette.colorMain, width: '1.5em', height: '1.5em' }}
                      />
                    </InputAdornment>
                  </Button>
                ),
                type: 'search'
              }}
            />
          </Grid>
        ) : null}
        {buttonReturn.isDisplayable ? (
          <Grid sx={styleHeaderArrowBack}>
            <Button
              sx={styleButtonArrowBack}
              aria-label="ArrowBack"
              onClick={buttonReturn.onClick}
              startIcon={<ArrowBackIcon sx={styleButtonIconArrowBack} />}
            >
              Retour
            </Button>
          </Grid>
        ) : null}
      </Grid>
    </Grid>
  );
}

HeaderPage.propTypes = {
  headerPageConfig: PropTypes.shape({
    text: PropTypes.shape({
      titleText: PropTypes.string,
      bigTitleText: PropTypes.string,
      selectedItemsText: PropTypes.string,
      tagId: PropTypes.string
    }),
    searchBarPlaceOlder: PropTypes.string,
    buttons: PropTypes.arrayOf(
      PropTypes.shape({
        text: PropTypes.string,
        onClick: PropTypes.func,
        type: PropTypes.string,
        datatestid: PropTypes.string.isRequired,
        disabled: PropTypes.bool,
        isDisplayable: PropTypes.bool
      })
    ),
    searchBar: PropTypes.shape({
      isDisplayable: PropTypes.bool,
      disabled: PropTypes.bool
    }),
    actions: PropTypes.shape({
      handleSearchTerm: PropTypes.func
    }),
    loading: PropTypes.shape({
      itemsListIsLoading: PropTypes.bool
    }),
    buttonReturn: PropTypes.shape({
      isDisplayable: PropTypes.bool,
      onClick: PropTypes.func
    }),
    isPositionFixed: PropTypes.bool
  })
};
HeaderPage.defaultProps = {
  headerPageConfig: {
    text: {
      titleText: '',
      bigTitleText: '',
      selectedItemsText: '',
      tagId: ''
    },
    searchBarPlaceOlder: '',
    buttons: [],
    searchBar: {
      isDisplayable: false,
      disabled: false
    },
    actions: {
      handleSearchTerm: () => {}
    },
    loading: {
      itemsListIsLoading: false
    },
    buttonReturn: {
      isDisplayable: false,
      onClick: () => {}
    },
    isPositionFixed: false
  }
};
