import axios from 'axios';
import API_URL from '../../constants';

const AzureColumnsAdminService = {
  getAzureColumns() {
    return axios.get(`${API_URL}/azureColumns`);
  }
};

export default AzureColumnsAdminService;
