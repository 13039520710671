import axios from 'axios';
import API_URL from '../../constants';

const AuthGroupService = {
  getAuthGroups() {
    return axios.get(`${API_URL}/authGroups`);
  },

  createAuthGroup: (name, providerId, organizationType, providerMappingId) => {
    return axios.post(`${API_URL}/authGroups`, {
      name,
      providerId,
      organizationType,
      providerMappingId
    });
  },

  deleteAuthGroups(id) {
    return axios.delete(`${API_URL}/authGroups/${id}`);
  },

  getAllPermissions() {
    return axios.get(`${API_URL}/allPermissions`);
  },

  getPermissionsByidAuthGroup: (idAuthGroup) => {
    return axios.get(`${API_URL}/authPermissions/${idAuthGroup}`);
  },

  createAuthPermissions: (authGroupId, permissionIds) => {
    return axios.post(`${API_URL}/authGroupPermissions`, {
      authGroupId,
      permissionIds
    });
  },

  deleteAuthPermissions: (authGroupId, permissionIds) => {
    return axios.delete(`${API_URL}/authGroupPermissions`, {
      data: {
        authGroupId,
        permissionIds
      }
    });
  }
};

export default AuthGroupService;
