import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  loginAzure,
  azurePrivilegeUp,
  azurePrivilegeDown
} from '../../services/authentication/AzureAD/authAzure';
import { deleteAzureUser } from './AzureUsersList';
import ProfileService from '../../services/profile/ProfileService';
import AuthService from '../../services/authentication/AuthService';

export const initialState = {
  status: 'fulfilled',
  isLogin: !!localStorage.getItem('token'),
  statusError: null,
  profile: {
    status: 'fulfilled',
    id: null,
    firstName: null,
    lastName: null,
    email: null,
    group: { id: null, name: null },
    azureLicenses: [],
    photo: null
  },
  permissions: []
};

export const login = createAsyncThunk('users/login', async (profile) => {
  const res = await AuthService.loginProfile(profile);
  return res.data;
});

export const loginAD = createAsyncThunk('users/loginAD', async (profile) => {
  const res = await AuthService.loginADProfile(profile);
  return res.data;
});

export const getProfile = createAsyncThunk('users/profile', async () => {
  const res = await ProfileService.getUserProfile();
  return res.data;
});

export const getPhoto = createAsyncThunk('user/photo', async () => {
  const res = await ProfileService.getUserPhoto();
  const arrayBufferView = new Uint8Array(res.data.photo.data);
  const blob = new Blob([arrayBufferView], { type: 'image/jpeg' });
  const dataURL = URL.createObjectURL(blob);
  return dataURL;
});

export const getPermissions = createAsyncThunk('users/permissions', async () => {
  const res = await ProfileService.getUserPermissions();
  return res.data;
});

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    logout: (state) => {
      state.isLogin = false;
    },
    error403: (state) => {
      state.statusError = '403';
    },
    error404: (state) => {
      state.statusError = '404';
    },
    error400: (state) => {
      state.statusError = '400';
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.status = 'pending';
      })
      .addCase(login.fulfilled, (state) => {
        state.status = 'fulfilled';
        state.isLogin = true;
      })
      .addCase(login.rejected, (state) => {
        state.status = 'rejected';
        state.isLogin = false;
      })
      .addCase(loginAD.pending, (state) => {
        state.status = 'pending';
      })
      .addCase(loginAD.fulfilled, (state) => {
        state.status = 'fulfilled';
        state.isLogin = true;
      })
      .addCase(loginAD.rejected, (state) => {
        state.status = 'rejected';
        state.isLogin = false;
      })
      .addCase(loginAzure.pending, (state) => {
        state.status = 'pending';
      })
      .addCase(loginAzure.fulfilled, (state) => {
        state.status = 'fulfilled';
        state.isLogin = true;
      })
      .addCase(loginAzure.rejected, (state) => {
        state.status = 'rejected';
        state.isLogin = false;
      })
      .addCase(getProfile.pending, (state) => {
        state.profile.status = 'pending';
      })
      .addCase(getProfile.fulfilled, (state, action) => {
        state.profile.status = 'fulfilled';
        state.profile.id = action.payload.user.id;
        state.profile.firstName = action.payload.user.firstName;
        state.profile.lastName = action.payload.user.lastName;
        state.profile.email = action.payload.user.email;
        state.profile.group = action.payload.user.group;
        state.profile.azureLicenses = action.payload.assignedLicenses;
      })
      .addCase(getProfile.rejected, (state) => {
        state.profile.status = 'rejected';
      })
      .addCase(getPhoto.pending, (state) => {
        state.profile.status = 'pending';
      })
      .addCase(getPhoto.fulfilled, (state, action) => {
        state.profile.status = 'fulfilled';
        state.profile.photo = action.payload;
      })
      .addCase(getPhoto.rejected, (state) => {
        state.profile.status = 'rejected';
      })
      .addCase(azurePrivilegeUp.pending, (state) => {
        state.status = 'pending';
      })
      .addCase(azurePrivilegeUp.fulfilled, (state) => {
        state.status = 'fulfilled';
      })
      .addCase(azurePrivilegeUp.rejected, (state) => {
        state.status = 'rejected';
      })
      .addCase(azurePrivilegeDown.pending, (state) => {
        state.status = 'pending';
      })
      .addCase(azurePrivilegeDown.fulfilled, (state) => {
        state.status = 'fulfilled';
      })
      .addCase(azurePrivilegeDown.rejected, (state) => {
        state.status = 'rejected';
      })
      .addCase(getPermissions.pending, (state) => {
        state.status = 'pending';
      })
      .addCase(getPermissions.fulfilled, (state, action) => {
        state.status = 'fulfilled';
        state.permissions = action.payload.permissions;
      })
      .addCase(getPermissions.rejected, (state) => {
        state.status = 'rejected';
        state.permissions = [];
      })
      .addCase(deleteAzureUser.fulfilled, (state) => {
        state.status = 'fulfilled';
      })
      .addCase(deleteAzureUser.rejected, (state) => {
        state.status = 'rejected';
      });
  }
});

export const { logout } = userSlice.actions;

export const selectIsLogin = (state) => state.user.isLogin;
export const selectPhoto = (state) => state.user.profile.photo;
export const selectLicenses = (state) => state.user.profile.azureLicenses;
export const selectGroup = (state) => state.user.profile.group;
export const selectProfileId = (state) => state.user.profile.id;
export const selectPermissions = (state) => state.user.permissions;
export const selectStatusError = (state) => state.user.statusError;
export const selectEmail = (state) => state.user.profile.email;
export const selectName = (state) =>
  `${state.user.profile.firstName} ${state.user.profile.lastName}`;

export default userSlice.reducer;
