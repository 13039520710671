export const styleFullWidth = {
  width: '100%'
};

export const styleHalfOpacity = {
  opacity: 0.5
};
export const styleFontWeight = {
  fontWeight: 'bold'
};
export const styleMarginBottom = {
  marginBottom: '10px'
};

export const styleHoverAccordion = () => ({
  '&:hover': {}
});

export const styleMarginLeft = {
  marginLeft: '15px'
};

export const styleFormatText = {
  margin: '20px 0px',
  marginLeft: { sm: '10px', md: '0' },
  fontSize: '20px',
  width: '100%',
  textAlign: { xs: 'start', sm: 'start', md: 'center' }
};
