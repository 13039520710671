import React, { useEffect, useState } from 'react';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Tooltip from '@mui/material/Tooltip';
import {
  Input,
  Table,
  TableBody,
  TableContainer,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
  Typography,
  Grid
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
  requestAllUsersPagination,
  requestSearchedUsersPagination,
  resetUserListState,
  selectTotalItems,
  selectUsers
} from '../../../redux/slices/usersList';
import {
  styleGridUsers,
  styleTableContainerUsers,
  styleTypographyUsers,
  styleSearchBar
} from '../../../assets/style/views/UserPages';
import styleScrollbar from '../../../assets/style/utils/utils';
import errorRedirection from '../../../utils/errorRedirection';

const columns = ['givenName', 'lastName', 'mail'];

export default function ListUser() {
  const dispatch = useDispatch();
  const users = useSelector(selectUsers);
  const totalItems = useSelector(selectTotalItems);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const { t } = useTranslation();
  const [searchTerm, setSearchTerm] = useState('');
  const minChar = 3;
  const navigate = useNavigate();

  const handleChangePage = (next, newPage) => {
    if (searchTerm.length < minChar) {
      setPage(newPage);
      dispatch(requestAllUsersPagination({ page: newPage, rowsPerPage }));
    } else {
      setPage(newPage);
      dispatch(
        requestSearchedUsersPagination({ searchString: searchTerm, page: newPage, rowsPerPage })
      );
    }
  };

  const handleChangeRowsPerPage = (event) => {
    if (searchTerm.length < minChar) {
      setRowsPerPage(event.target.value);
      setPage(0);
      dispatch(requestAllUsersPagination({ page: 0, rowsPerPage: event.target.value }));
    } else {
      setRowsPerPage(event.target.value);
      setPage(0);
      dispatch(
        requestSearchedUsersPagination({
          searchString: searchTerm,
          page: 0,
          rowsPerPage: event.target.value
        })
      );
    }
  };

  const dispatchRequestAllUsersPagination = async () => {
    try {
      await dispatch(requestAllUsersPagination({ page, rowsPerPage })).unwrap();
    } catch (error) {
      errorRedirection(error.code, navigate);
    }
  };

  useEffect(() => {
    dispatchRequestAllUsersPagination();
    return function cleanup() {
      dispatch(resetUserListState);
    };
  }, [dispatch]);

  const handleSearchTerm = (e) => {
    const { value: searchString } = e.target;
    setSearchTerm(searchString);

    if (searchString.length < searchTerm.length && searchString.length < minChar) {
      dispatch(requestAllUsersPagination({ page, rowsPerPage }));
    } else if (searchString.length >= minChar) {
      setPage(0);
      dispatch(requestSearchedUsersPagination({ searchString, page: 0, rowsPerPage }));
    }
  };

  return (
    <Grid container sx={styleGridUsers}>
      <Typography sx={styleTypographyUsers}>{t('users')}</Typography>
      <Grid container sx={styleSearchBar}>
        <Input
          type="text"
          name="searchBar"
          placeholder={t('searchUser')}
          onChange={handleSearchTerm}
          data-testid="searchBarUsers"
        />
        <Tooltip title={t('infoSearchBarPegasus')} placement="bottom-start">
          <InfoOutlinedIcon />
        </Tooltip>
      </Grid>
      <TableContainer component={Paper} sx={[styleTableContainerUsers, styleScrollbar]}>
        <Table aria-label="table products">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell key={column} align="left">
                  {t(column)}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody>
            {users &&
              users.map((user) => (
                <TableRow key={user.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell data-testid={`usersTable${user.firstName}`}>
                    {user.firstName}
                  </TableCell>
                  <TableCell>{user.lastName}</TableCell>
                  <TableCell>{user.email}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 50]}
        component="div"
        count={totalItems}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelRowsPerPage="items/page"
        sx={styleScrollbar}
      />
    </Grid>
  );
}
