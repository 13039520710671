import * as React from 'react';
import { SnackbarProvider, useSnackbar } from 'notistack';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { getNotifications, delNotification } from '../../redux/slices/notificationSlice';

const DURATION_NOTIFICATION = 3000;

function Notification() {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const notificationsList = useSelector(getNotifications);
  const { t } = useTranslation();

  const handleSnackBar = () => {
    if (notificationsList && notificationsList.length > 0) {
      notificationsList.map((notificationItem, index) =>
        enqueueSnackbar(t(notificationItem.key), {
          variant: notificationItem.status,
          autoHideDuration: DURATION_NOTIFICATION,
          'data-testid': `notificationItem${index}`
        })
      );
      dispatch(delNotification());
    }
  };

  React.useEffect(() => {
    handleSnackBar();
  });

  return null;
}

function ApiResponseHandlerReturn() {
  return (
    <SnackbarProvider
      maxSnack={3}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center'
      }}
    >
      <Notification />
    </SnackbarProvider>
  );
}

export default ApiResponseHandlerReturn;
