export const styleButton = (theme) => ({
  justifyContent: 'center',
  display: 'flex',
  alignItems: 'center',
  variant: 'contained',
  borderRadius: '10px',
  width: { xs: '160px', sm: '130px', md: '170px' },
  height: '42px',
  marginTop: '5px',
  marginRight: '5px',
  marginLeft: '5px',
  color: theme.palette.colorMainLight,
  backgroundColor: theme.palette.colorMainSelected,
  transition: 'all 0.5s',
  '&:hover': {
    background: theme.palette.colorMainSelected,
    transform: 'scale(0.95, 0.95)'
  },
  '&:focus': {
    outline: 'none'
  },
  '&:disabled': {
    background: theme.palette.colorMainLight,
    border: `1px solid ${theme.palette.colorSoftBlack}`
  }
});

export const styleTextField = {
  with: '100%',
  varient: 'outlined'
};
