import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getDateTimeFormatted } from '../../utils/Date/DateFunction';
import JiraDevicesService from '../../services/devices/JiraDevicesService';
import { regexpEscaping } from '../../utils/regex';

export const initialState = {
  jiraDevices: [],
  jiraDevicesColumns: [],
  isLoading: true,
  updateAt: '',
  status: 'fulfilled',
  selectedJiraDevices: {
    issueData: {},
    comments: []
  }
};

export const getOneJiraDevice = createAsyncThunk('getOneJiraDevice', async (id) => {
  const res = await JiraDevicesService.getOneJiraDevice(id);
  return res.data;
});

export const addJiraDeviceComment = createAsyncThunk('addJiraDeviceComment', async (payload) => {
  const res = await JiraDevicesService.addJiraDeviceComment(payload.newComment, payload.deviceId);
  return res.data;
});

export const assignReporterToJiraDevice = createAsyncThunk(
  'assignReporterToJiraDevice',
  async (payload) => {
    const res = await JiraDevicesService.assignReporterToJiraDevice(payload.key, payload.body);
    return res.data;
  }
);

export const findJiraDevices = createAsyncThunk('findJiraDevices', () => {
  JiraDevicesService.findJiraDevices();
});

export const refreshJiraDevices = createAsyncThunk('refreshJiraDevices', () => {
  JiraDevicesService.refreshJiraDevices();
});

export const jiraDevicesSlice = createSlice({
  name: 'jiraDevices',
  initialState,
  reducers: {
    addJiraDevicesToList: (state, action) => {
      state.jiraDevices = state.jiraDevices.concat(action.payload.issues);
    },
    resetJiraDevicesList: (state) => {
      state.jiraDevices = [];
    },
    setJiraDevicesListUpdateAt: (state, action) => {
      state.updateAt = getDateTimeFormatted(Number(action.payload));
    },
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setJiraDevicesColumns: (state, action) => {
      state.jiraDevicesColumns = action.payload;
    },
    resetJiraDeviceState: () => {
      return initialState;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getOneJiraDevice.pending, (state) => {
        state.status = 'pending';
        state.isLoading = true;
      })
      .addCase(getOneJiraDevice.fulfilled, (state, action) => {
        state.status = 'fulfilled';
        state.isLoading = false;
        state.jiraDevicesColumns = action.payload.jiraDevice.names;
        state.selectedJiraDevices = {
          issueData: action.payload.jiraDevice.issueData,
          comments: action.payload.jiraDevice.comments
        };
      })
      .addCase(getOneJiraDevice.rejected, (state) => {
        state.status = 'rejected';
        state.isLoading = false;
      });
    builder
      .addCase(addJiraDeviceComment.pending, (state) => {
        state.status = 'pending';
        state.isLoading = true;
      })
      .addCase(addJiraDeviceComment.fulfilled, (state, action) => {
        state.status = 'fulfilled';
        state.isLoading = false;
        state.selectedJiraDevices = {
          ...state.selectedJiraDevices,
          comments: state.selectedJiraDevices.comments.concat([action.payload.comment])
        };
      })
      .addCase(addJiraDeviceComment.rejected, (state) => {
        state.status = 'rejected';
        state.isLoading = false;
      });
    builder
      .addCase(assignReporterToJiraDevice.pending, (state) => {
        state.status = 'pending';
        state.isLoading = true;
      })
      .addCase(assignReporterToJiraDevice.fulfilled, (state, action) => {
        state.status = 'fulfilled';
        state.isLoading = false;
        state.selectedJiraDevices = {
          ...state.selectedJiraDevices,
          issueData: {
            ...state.selectedJiraDevices.issueData,
            reporter: {
              id: action.meta.arg.body.id,
              name: action.meta.arg.body.name
            }
          }
        };
      })
      .addCase(assignReporterToJiraDevice.rejected, (state) => {
        state.status = 'rejected';
        state.isLoading = false;
      });
    builder
      .addCase(findJiraDevices.pending, (state) => {
        state.status = 'pending';
        state.isLoading = true;
        state.jiraDevices = [];
      })
      .addCase(findJiraDevices.fulfilled, (state) => {
        state.status = 'fulfilled';
      })
      .addCase(findJiraDevices.rejected, (state) => {
        state.status = 'rejected';
        state.isLoading = false;
      });
    builder
      .addCase(refreshJiraDevices.pending, (state) => {
        state.status = 'pending';
        state.isLoading = true;
        state.jiraDevices = [];
      })
      .addCase(refreshJiraDevices.fulfilled, (state) => {
        state.status = 'fulfilled';
      })
      .addCase(refreshJiraDevices.rejected, (state) => {
        state.status = 'rejected';
        state.isLoading = false;
      });
  }
});
export const {
  addJiraDevicesToList,
  resetJiraDevicesList,
  setIsLoading,
  setJiraDevicesListUpdateAt,
  setJiraDevicesColumns,
  resetJiraDeviceState
} = jiraDevicesSlice.actions;

export const selectJiraDevices = (searchTerm) => (state) => {
  if (state.jiraDevices.jiraDevices.length > 0 && searchTerm.length > 2) {
    const escapedString = regexpEscaping(searchTerm);
    const regexp = new RegExp(`${escapedString}`, 'gi');

    return state.jiraDevices.jiraDevices.filter(
      (device) =>
        (device.name && device.name.match(regexp)) ||
        (device.summary && device.summary.match(regexp))
    );
  }
  return state.jiraDevices.jiraDevices;
};
export const selectIsLoading = (state) => state.jiraDevices.isLoading;
export const selectUpdateAt = (state) => state.jiraDevices.updateAt;
export const selectJiraDevicesColumns = (state) => state.jiraDevices.jiraDevicesColumns;
export const selectSelectedJiraDevices = (state) => state.jiraDevices.selectedJiraDevices;
export const selectIsTableDisplayable = (state) => {
  if (
    state.jiraDevices.jiraDevicesColumns.length <= 0 ||
    state.jiraDevices.jiraDevices.length <= 0
  ) {
    return false;
  }
  return true;
};

export const selectIsJiraDeviceLoaded = (state) => {
  if (state.jiraDevices.isLoading || !state.jiraDevices.selectedJiraDevices?.issueData?.name) {
    return false;
  }
  return true;
};

export default jiraDevicesSlice.reducer;
