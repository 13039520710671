const fieldActiveDirectory = {
  nameId: 1,
  name: 'ACTIVE_DIRECTORY',
  isAuthConnector: true,
  fields: [
    { fieldname: 'url', type: 'edit', required: true, hideView: true },
    { fieldname: 'isServiceAccountUsed', type: 'isBoolean', required: false },
    { fieldname: 'username', type: 'edit', required: true },
    { fieldname: 'password', type: 'edit', required: true, hideView: true },
    { fieldname: 'userStore', type: 'edit', required: true },
    { fieldname: 'groupsDN', type: 'edit', required: true },
    { fieldname: 'userText', type: 'edit', required: true },
    { fieldname: 'defaultEnableUserText', type: 'edit', required: true },
    { fieldname: 'disableText', type: 'edit', required: true },
    { fieldname: 'certPath', type: 'edit', required: false },
    { fieldname: 'domain', type: 'edit', required: true },
    {
      fieldname: 'mandatoryGroup',
      type: 'isObject',
      fields: [
        { fieldname: 'name', type: 'edit', required: true },
        { fieldname: 'dn', type: 'edit', required: true }
      ]
    },
    {
      fieldname: 'groupsOpts',
      type: 'isObject',
      fields: [
        { fieldname: 'scope', type: 'edit', required: true },
        { fieldname: 'filter', type: 'edit', required: true }
      ]
    },
    { fieldname: 'rootDN', type: 'edit', required: true },
    {
      fieldname: 'loadingOpts',
      type: 'isObject',
      fields: [
        { fieldname: 'scope', type: 'edit', required: true },
        { fieldname: 'filter', type: 'edit', required: true },
        { fieldname: 'attributes', type: 'arrayField', required: true },
        { fieldname: 'paged', type: 'isBoolean', required: false },
        { fieldname: 'sizeLimit', type: 'numberField', required: true }
      ]
    }
  ]
};

const fieldAzureActiveDirectory = {
  nameId: 2,
  name: 'AZURE_ACTIVE_DIRECTORY',
  isAuthConnector: false,
  fields: [
    { fieldname: 'username', type: 'edit', required: true },
    { fieldname: 'password', type: 'edit', required: true, hideView: true },
    { fieldname: 'azureTenantId', type: 'edit', required: true },
    { fieldname: 'azureClientId', type: 'edit', required: true },
    { fieldname: 'azureClientSecret', type: 'edit', required: true, hideView: true },
    { fieldname: 'azureAuthority', type: 'edit', required: true },
    { fieldname: 'reactAppClientId', type: 'edit', required: true }
  ]
};

const fieldBoondmanager = {
  nameId: 3,
  name: 'BOONDMANAGER',
  isAuthConnector: false,
  fields: [
    { fieldname: 'userToken', type: 'edit', required: true },
    { fieldname: 'clientToken', type: 'edit', required: true },
    { fieldname: 'clientKey', type: 'edit', required: true, hideView: true },
    { fieldname: 'mode', type: 'edit', required: true }
  ]
};

const fieldIntune = {
  nameId: 4,
  name: 'INTUNE',
  isAuthConnector: false,
  fields: [
    { fieldname: 'username', type: 'edit', required: true },
    { fieldname: 'password', type: 'edit', required: true, hideView: true },
    { fieldname: 'azureTenantId', type: 'edit', required: true },
    { fieldname: 'azureClientId', type: 'edit', required: true },
    { fieldname: 'azureClientSecret', type: 'edit', required: true, hideView: true },
    { fieldname: 'azureAuthority', type: 'edit', required: true }
  ]
};

const fieldJira = {
  nameId: 5,
  name: 'JIRA',
  isAuthConnector: false,
  fields: [
    { fieldname: 'jiraTenantDomain', type: 'edit', required: true },
    { fieldname: 'username', type: 'edit', required: true },
    { fieldname: 'password', type: 'edit', required: true, hideView: true }
  ]
};

const fieldRcsGoogle = {
  nameId: 6,
  name: 'RCS_GOOGLE',
  isAuthConnector: false,
  fields: [
    { fieldname: 'clientEmail', type: 'edit', required: true },
    { fieldname: 'privateKey', type: 'edit', required: true, hideView: true },
    { fieldname: 'projectId', type: 'edit', required: true },
    { fieldname: 'agentId', type: 'edit', required: true }
  ]
};

const fieldAzureFront = {
  nameId: 7,
  name: 'AZURE_FRONT',
  isAuthConnector: true,
  fields: [
    { fieldname: 'clientId', type: 'edit', required: true },
    { fieldname: 'redirectUri', type: 'edit', required: true },
    { fieldname: 'tenanId', type: 'edit', required: true },
    { fieldname: 'customScope', type: 'edit', required: true }
  ]
};

const providersAttributes = {
  ACTIVE_DIRECTORY: fieldActiveDirectory,
  AZURE_ACTIVE_DIRECTORY: fieldAzureActiveDirectory,
  BOONDMANAGER: fieldBoondmanager,
  INTUNE: fieldIntune,
  JIRA: fieldJira,
  JIRA_DEVICE: fieldJira,
  RCS_GOOGLE: fieldRcsGoogle,
  AZURE_FRONT: fieldAzureFront
};

export const providersOrganizationType = {
  ACTIVE_DIRECTORY: ['group'],
  AZURE_FRONT: ['group', 'role']
};

export default providersAttributes;
