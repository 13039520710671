import axios from 'axios';
import socket from '../../socket/socket';
import API_URL from '../../constants';

const DeviceService = {
  getDevices() {
    return socket.emit('azure-devices:find', 'true');
  },
  refreshIntuneDevices() {
    return socket.emit('azure-devices:refresh', 'true');
  },
  getOneDeviceById(id) {
    return axios.get(`${API_URL}/devices/intune/${id}`);
  },
  getRecoverKeysById(id) {
    return axios.get(`${API_URL}/devices/intune/recoveryKeys/${id}`);
  },
  getRecoverKeyDetailsById(id) {
    return axios.get(`${API_URL}/devices/intune/recoveryKeys/details/${id}`);
  },
  assignUserToIntuneDeviceById(deviceId, body) {
    return axios.post(`${API_URL}/devices/intune/${deviceId}/assignUser`, body);
  },
  removeUserToIntineDeviceById(deviceId) {
    return axios.delete(`${API_URL}/devices/intune/${deviceId}/removeUser`);
  },
  renameIntuneDeviceName(deviceId, body) {
    return axios.post(`${API_URL}/devices/intune/${deviceId}/changeDeviceName`, body);
  },
  rebootIntuneDevice(deviceId) {
    return axios.post(`${API_URL}/devices/intune/${deviceId}/rebootNow`);
  }
};

export default DeviceService;
