import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography, Box, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';

import RefreshIcon from '@mui/icons-material/Refresh';
import IconButton from '@mui/material/IconButton';
import {
  styleGridUsers,
  styleRefreshButton,
  styleToolbar,
  styleRefreshButtonLoading,
  styleRefreshTypo
} from '../../assets/style/views/UserPages';
import DataList from '../../components/datalist/DataList';
import CustomizedPagination from '../../components/datalist/TablePagination';
import HeaderPage from '../../components/HeaderPage';

export default function ItemsDesktop({ desktopConfig }) {
  const { t } = useTranslation();

  const { handleSearchTerm } = desktopConfig.actions;
  const { handleRefresh } = desktopConfig.actions;

  const { items } = desktopConfig.data;
  const { tableSelectedRows } = desktopConfig.data;
  const { setTableSelectedRowsRealData } = desktopConfig.data;

  const { formatedColumns } = desktopConfig.tables;
  const { date } = desktopConfig.tables;
  const { totalItems } = desktopConfig.tables;
  const { page } = desktopConfig.tables;
  const { setPage } = desktopConfig.tables;
  const { handleChangePage } = desktopConfig.tables;
  const { handleChangeRowsPerPage } = desktopConfig.tables;
  const { rowsPerPage } = desktopConfig.tables;
  const { setTotalItems } = desktopConfig.tables;

  const { selectedIndexRows } = desktopConfig.tables;

  const { searchTerm } = desktopConfig.search;
  const { isSlowSearch } = desktopConfig.search;
  const { onFilterChange } = desktopConfig;

  const { itemsListIsLoading } = desktopConfig.loading;
  const { isTableDisplayable } = desktopConfig.loading;

  const { buttons } = desktopConfig;
  const { searchBar } = desktopConfig;

  const { titleText } = desktopConfig.text;
  const { bigTitleText } = desktopConfig.text;
  const { selectedItemsText } = desktopConfig.text;
  const { tagId } = desktopConfig.text;
  const { isDisableCheckBox } = desktopConfig;
  const { searchBarPlaceOlder } = desktopConfig;
  const { hideRefresh } = desktopConfig;
  const { additionalInformationInField } = desktopConfig;
  const headerPageConfig = {
    isSlowSearch,
    text: {
      titleText,
      bigTitleText,
      selectedItemsText,
      tagId
    },
    searchBarPlaceOlder,
    buttons,
    searchBar,
    actions: {
      handleSearchTerm
    },
    loading: {
      itemsListIsLoading
    },
    isPositionFixed: false
  };

  return (
    <Grid
      container
      sx={{ contain: 'inline-size', height: '100%' }}
      display="flex"
      flexDirection="column"
      alignItems="center"
    >
      <HeaderPage headerPageConfig={headerPageConfig} />

      {additionalInformationInField ? (
        <Grid
          item
          sx={(theme) => ({
            display: 'flex',
            justifyContent: 'space-evenly',
            width: '-webkit-fill-available',
            padding: '25px',
            marginBottom: '15px',
            borderBottom: `3px solid ${theme.palette.colorSecondaryLight}`
          })}
        >
          {Object.keys(additionalInformationInField).map((informationLabel) => {
            return (
              <Grid>
                <TextField
                  id={informationLabel}
                  data-testid={informationLabel}
                  label={informationLabel}
                  value={additionalInformationInField[informationLabel] || ''}
                  disabled={!additionalInformationInField[informationLabel]}
                  sx={{ width: '100%', height: '100%' }}
                  InputProps={{ readOnly: true }}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
            );
          })}
        </Grid>
      ) : null}

      <Grid container>
        <Grid item md={2} sx={{ display: 'flex', alignItems: 'center', paddingLeft: '2%' }}>
          <Typography sx={(theme) => ({ fontWeight: 'bold', color: theme.palette.colorBlack })}>
            {selectedItemsText ? tableSelectedRows.length : ''}
            {selectedItemsText}
          </Typography>
        </Grid>
        <Grid item md={10} sx={{ display: 'flex', justifyContent: 'flex-end', paddingRight: '2%' }}>
          <CustomizedPagination
            count={totalItems}
            rowsPerPage={rowsPerPage}
            page={page}
            setPage={setPage}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            sx={styleToolbar}
          />
        </Grid>
      </Grid>
      <Grid container sx={styleGridUsers}>
        <DataList
          columns={formatedColumns}
          rows={items}
          page={page}
          rowsPerPage={rowsPerPage}
          linkedColumns={{ displayName: 'id' }}
          setTableSelectedRows={setTableSelectedRowsRealData}
          selectedIndexRows={selectedIndexRows}
          searchTerm={searchTerm}
          isDisplayable={isTableDisplayable}
          disableCheckBox={isDisableCheckBox}
          setTotalItems={setTotalItems}
          onFilterChange={onFilterChange}
        />
      </Grid>
      {!hideRefresh && (
        <Box
          sx={{
            position: 'fixed',
            bottom: '2%',
            right: '2%',
            display: 'flex',
            justifyContent: 'flex-end',
            flexDirection: 'column'
          }}
        >
          <IconButton
            disabled={!date || itemsListIsLoading}
            color="primary"
            data-testid={`Refresh${tagId}Button`}
            aria-label="refresh cache"
            component="span"
            onClick={handleRefresh}
            sx={styleRefreshButton}
          >
            <RefreshIcon
              sx={
                !date || itemsListIsLoading
                  ? styleRefreshButtonLoading
                  : (theme) => ({ color: theme.palette.colorMainLight })
              }
            />
            <Typography sx={styleRefreshTypo}>{t('refresh')}</Typography>
          </IconButton>
          <Typography
            sx={(theme) => ({
              background: theme.palette.colorSecondaryLight,
              padding: '5px',
              borderRadius: '10px'
            })}
          >
            {(date && `${t('updated')} ${date}`) || t('loading')}
          </Typography>
        </Box>
      )}
    </Grid>
  );
}

ItemsDesktop.propTypes = {
  desktopConfig: PropTypes.shape({
    actions: PropTypes.shape({
      handleRefresh: PropTypes.func,
      handleSearchTerm: PropTypes.func
    }),
    buttons: PropTypes.arrayOf(
      PropTypes.shape({
        disabled: PropTypes.bool,
        datatestid: PropTypes.string,
        isDisplayable: PropTypes.bool,
        onClick: PropTypes.func,
        text: PropTypes.string
      })
    ),
    searchBar: PropTypes.shape({
      isDisplayable: PropTypes.bool,
      disabled: PropTypes.bool
    }),
    data: PropTypes.shape({
      tableSelectedRows: PropTypes.arrayOf(
        PropTypes.objectOf(
          PropTypes.oneOfType([
            PropTypes.object,
            PropTypes.number,
            PropTypes.string,
            PropTypes.bool,
            PropTypes.arrayOf(PropTypes.string)
          ])
        )
      ),
      setTableSelectedRows: PropTypes.func,
      setTableSelectedRowsRealData: PropTypes.func,
      items: PropTypes.arrayOf(
        PropTypes.objectOf(
          PropTypes.oneOfType([
            PropTypes.object,
            PropTypes.number,
            PropTypes.string,
            PropTypes.bool,
            PropTypes.array,
            PropTypes.arrayOf(PropTypes.string)
          ])
        )
      )
    }),
    loading: PropTypes.shape({
      itemsListIsLoading: PropTypes.bool,
      isTableDisplayable: PropTypes.bool
    }),
    tables: PropTypes.shape({
      formatedColumns: PropTypes.arrayOf(
        PropTypes.objectOf(
          PropTypes.oneOfType([PropTypes.object, PropTypes.func, PropTypes.string, PropTypes.bool])
        )
      ),
      date: PropTypes.string,
      totalItems: PropTypes.number,
      page: PropTypes.number,
      setPage: PropTypes.func,
      handleChangePage: PropTypes.func,
      handleChangeRowsPerPage: PropTypes.func,
      rowsPerPage: PropTypes.number,
      selectedIndexRows: PropTypes.arrayOf(PropTypes.number),
      setTotalItems: PropTypes.func
    }),
    search: PropTypes.shape({
      searchTerm: PropTypes.string,
      setSearchTerm: PropTypes.func,
      isSlowSearch: PropTypes.bool
    }),
    text: PropTypes.shape({
      titleText: PropTypes.string,
      bigTitleText: PropTypes.string,
      selectedItemsText: PropTypes.string,
      tagId: PropTypes.string
    }),
    isDisableCheckBox: PropTypes.bool,
    searchBarPlaceOlder: PropTypes.string,
    hideRefresh: PropTypes.bool,
    onFilterChange: PropTypes.func,
    additionalInformationInField: PropTypes.objectOf(PropTypes.string)
  })
};

ItemsDesktop.defaultProps = {
  desktopConfig: { isDisableCheckBox: true }
};
