import { useTranslation } from 'react-i18next';
import {
  TextField,
  Autocomplete,
  Box,
  FormControlLabel,
  Grid,
  Dialog,
  DialogContent,
  DialogActions,
  Typography
} from '@mui/material';
import { PropTypes } from 'prop-types';

import React, { useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import CustomizedButton from '../../../../components/buttons/CustomizedButton';
import errorRedirection from '../../../../utils/errorRedirection';
import styleScrollbar from '../../../../assets/style/utils/utils';
import { styleTitleHeadPageDescript, styledTitle } from '../../../../assets/style/views/User';
import StyledSwitch from '../../../../components/buttons/StyledSwitch';
import { createAppList } from '../../../../redux/slices/AppList';
import CustomFiledDrop from '../../../../components/fileDrop/CustomFileDrop';
import { getAllAuthGroups, selectAuthGroups } from '../../../../redux/slices/AuthGroups';

const styledBooleanField = {
  marginTop: '20px',
  display: 'flex',
  gap: '16px',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginBottom: 3
};

export default function PopupAddAppList({ openPop, onClose }) {
  const { t } = useTranslation();
  const [fileName, setFileName] = useState('');
  const [appListName, setAppListName] = useState('');
  const [appListUrl, setAppListUrl] = useState('');
  const [file, setFile] = useState(null);
  const [appListIsPublic, setAppListIsPublic] = useState(false);
  const [appListAuthGroup, setAppListAuthGroup] = useState([]);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const roles = useSelector(selectAuthGroups);
  const listRoles = roles.map((role) => ({ value: role.id, label: role.name }));

  const dispatchCreateAppList = async () => {
    const formData = new FormData();
    formData.append('name', appListName);
    formData.append('link', appListUrl);
    formData.append('isPublic', appListIsPublic);
    formData.append(
      'authGroup',
      appListAuthGroup.map((elem) => elem.value.toString())
    );
    formData.append('file', file);
    await dispatch(createAppList(formData));
    onClose();
  };

  const dispatchGetAuthGroups = async () => {
    try {
      await dispatch(getAllAuthGroups()).unwrap();
    } catch (error) {
      errorRedirection(error.code, navigate);
    }
  };

  const handleClickCreateAppList = () => {
    if (!appListName || !appListUrl || !file) {
      return;
    }
    try {
      dispatchCreateAppList();
    } catch (error) {
      errorRedirection(error.code, navigate);
    }
  };

  const handleAppListAuthGroup = (event, value) => {
    setAppListAuthGroup(value);
  };

  useEffect(() => {
    setAppListName('');
    setFileName('');
    setAppListUrl('');
    setFile(null);
    setAppListIsPublic(false);
    setAppListAuthGroup([]);
  }, [openPop]);
  useEffect(() => {
    dispatchGetAuthGroups();
  }, [dispatch]);

  return (
    <Dialog open={openPop} onClose={onClose} PaperProps={{ sx: styleScrollbar }}>
      <DialogContent sx={styleScrollbar}>
        <Box sx={styledTitle}>
          <Typography sx={styleTitleHeadPageDescript}>{t('addApp')}</Typography>
        </Box>
        <Grid item xs={8} sx={{ padding: '0px 16px 16px 16px' }} />
        <Box sx={{ marginBottom: 4 }}>
          <TextField
            id="input-name"
            data-testid="CreateAppListName"
            label={t('appName')}
            value={appListName}
            sx={{ width: '100%', height: '100%' }}
            onChange={(event) => setAppListName(event.target.value)}
            type="string"
            variant="outlined"
            required
          />
        </Box>
        <Box sx={{ marginBottom: 4 }}>
          <TextField
            id="input-url"
            label={t('appUrl')}
            data-testid="CreateAppListUrl"
            required
            value={appListUrl}
            sx={{ width: '100%', height: '100%' }}
            onChange={(event) => setAppListUrl(event.target.value)}
            type="url"
            variant="outlined"
          />
        </Box>
        <CustomFiledDrop setFile={setFile} setFileName={setFileName} fileName={fileName} />
        <Box sx={styledBooleanField}>
          <span> {t('isPublic')}</span>
          <FormControlLabel
            control={
              <StyledSwitch
                checked={appListIsPublic}
                onChange={(event) => {
                  setAppListIsPublic(event.target.checked);
                }}
                name="isPublic"
                data-testid="CreateAppListIsPublic"
              />
            }
          />
        </Box>
        <Box sx={styledTitle}>
          <Typography sx={styleTitleHeadPageDescript}>{t('addRoles')}</Typography>
        </Box>
        <Box sx={{ width: 500 }}>
          <Autocomplete
            multiple
            sx={{ width: 500 }}
            id="ad-groups"
            options={listRoles}
            filterSelectedOptions
            data-testid="CreateAppListUserSelect"
            getOptionLabel={({ label }) => label}
            isOptionEqualToValue={(option, value) => {
              return option.value === value.value;
            }}
            value={appListAuthGroup}
            onChange={handleAppListAuthGroup}
            renderInput={(props) => (
              // eslint-disable-next-line react/jsx-props-no-spreading
              <TextField {...props} label="appListAuthGroup" placeholder="Role" />
            )}
          />
        </Box>
        <Grid />
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'center' }}>
        <CustomizedButton
          variant="contained"
          text={t('cancel')}
          datatestid="cancelAddAppList"
          onClick={() => onClose()}
        />
        <CustomizedButton
          variant="contained"
          text={t('save')}
          disabled={
            fileName.length < 1 ||
            appListName.length < 1 ||
            appListUrl.length < 1 ||
            file === null ||
            (!appListIsPublic && !appListAuthGroup.length)
          }
          datatestid="confirmAddAppList"
          onClick={() => handleClickCreateAppList()}
        />
      </DialogActions>
    </Dialog>
  );
}
PopupAddAppList.propTypes = {
  openPop: PropTypes.bool,
  onClose: PropTypes.func
};

PopupAddAppList.defaultProps = {
  openPop: false,
  onClose: () => {}
};
