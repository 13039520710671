import axios from 'axios';
import API_URL from '../../constants';

const ParametersService = {
  getParametersNoConnected() {
    return axios.get(`${API_URL}/auth/parameters`);
  },
  getAllParameters() {
    return axios.get(`${API_URL}/parameters`);
  },
  updateParameterById(id, body) {
    return axios.post(`${API_URL}/parameter/${id}`, body);
  }
};

export default ParametersService;
