import {
  Autocomplete,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  Grid,
  TextField,
  Tooltip,
  Typography
} from '@mui/material';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import styleScrollbar from '../../assets/style/utils/utils';
import { styleDialogActions } from '../../assets/style/views/AzurADUserPopup';
import { styleButton } from '../../assets/style/views/userAdPopup';
import {
  getDC,
  getMandatoryGroup,
  mandatoryGroup,
  postOneAdUser,
  selectADForForm,
  selectedGroups
} from '../../redux/slices/AdUsers';
import BrowseOU from '../adUser/BrowseOU';
import CustomFormField from '../form/CustomFormField';
import { styleTitleHeadPageDescript, styledTitle } from '../../assets/style/views/User';
import CustomizedButton from '../buttons/CustomizedButton';
import { complexityPassword, randomNumberString } from '../../utils/adUsers';
import PasswordField from '../form/PasswordField';

export default function LdapcreateADUserPopup({
  isOpen,
  closePopup,
  isStepChangeOU,
  setIsStepChangeOU
}) {
  const defaultPassword = `${process.env.REACT_APP_DEFAULT_AD_PWD}${randomNumberString(4)}`;
  const { t } = useTranslation();
  const AD = useSelector(selectADForForm);
  const [selectedOU, setSelectedOU] = useState(null);
  const [isSubmit, setIsSubmit] = useState(false);
  const adGroups = useSelector(selectedGroups(''));
  const mGroup = useSelector(mandatoryGroup);
  const dispatch = useDispatch();
  const [createUserAd, setCreateUserAd] = useState({
    displayName: '',
    userPrincipalName: '',
    memberOf: []
  });
  const [groups, setGroups] = useState([]);
  const [newPassword, setNewPassword] = useState(defaultPassword);
  const [newPasswordAtNextLog, setNewPasswordAtNextLog] = useState(true);
  const handleSubmit = async () => {
    if (!isStepChangeOU) setIsStepChangeOU(true);
    else {
      setIsSubmit(true);
      const modifiedUserAd = {
        ...createUserAd,
        userPrincipalName: createUserAd.mail
      };
      const res = await dispatch(
        postOneAdUser({
          ...modifiedUserAd,
          memberOf: modifiedUserAd.memberOf.map(({ dn }) => dn),
          distinguishedName: `CN=${modifiedUserAd.displayName},${selectedOU}`,
          password: newPassword,
          newPasswordAtNextLog
        })
      );
      if (res.payload.success) {
        closePopup();
      }
    }
  };

  const handleSelectGroups = (event, value) => {
    setCreateUserAd((createdUser) => ({ ...createdUser, memberOf: value }));

    if (mGroup && !groups.find((group) => group.name === mGroup.name)) {
      setGroups((prevGroups) => [...prevGroups, mGroup]);
    }

    if (!value.find((member) => member.name === mGroup.name)) {
      setGroups(() => [...adGroups.filter((adGroup) => adGroup.name !== mGroup.name)]);
    }
  };

  const handleClickCancelButton = () => {
    closePopup();
  };
  const [adDomain, setAdDomain] = useState(process.env.REACT_APP_DEFAULT_DOMAIN);
  const propertyAD = [
    {
      columnName: 'displayName',
      input: 'TEXTFIELD',
      isEditable: true,
      isNullable: false
    },
    {
      columnName: 'mail',
      input: 'SEMICOMPLETE',
      isEditable: true,
      isNullable: false,
      domain: adDomain,
      listAllDomain: [process.env.REACT_APP_DEFAULT_DOMAIN]
    }
  ];

  useEffect(() => {
    setCreateUserAd({
      displayName: '',
      userPrincipalName: '',
      mail: `@${process.env.REACT_APP_DEFAULT_DOMAIN}`,
      memberOf: [mGroup]
    });
    setNewPassword(defaultPassword);
    setNewPasswordAtNextLog(true);
  }, [isOpen]);

  useEffect(() => {
    dispatch(getDC({ isForForm: true }));
    dispatch(getMandatoryGroup());
  }, [dispatch]);

  useEffect(() => {
    if (Object.keys(mGroup).length) {
      setCreateUserAd((prev) => ({ ...prev, memberOf: [mGroup] }));
      setGroups(adGroups.filter((adGroup) => adGroup.name !== mGroup.name));
    }
  }, [mGroup]);

  const optionsWithMandatory = () => {
    let updatedOptions = [];
    if (Object.keys(mGroup).length) {
      updatedOptions = [...adGroups.filter((adGroup) => adGroup.name !== mGroup.name), mGroup];
    } else {
      updatedOptions = adGroups;
    }
    return updatedOptions;
  };

  return (
    <Dialog
      open={isOpen}
      onClose={closePopup}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      data-testid="ADUserPopUp"
      PaperProps={{ sx: styleScrollbar }}
    >
      <DialogContent sx={styleScrollbar}>
        <Box sx={styledTitle}>
          <Typography sx={styleTitleHeadPageDescript}>{t('addUser')}</Typography>
        </Box>
        <FormControl
          sx={(theme) => ({ '& label.Mui-focused': { color: theme.palette.colorBlack }, gap: 2 })}
        >
          {!isStepChangeOU &&
            propertyAD &&
            propertyAD.map(
              ({ columnName, input, isEditable, isNullable, domain, listAllDomain = null }) => (
                <Grid key={columnName}>
                  <CustomFormField
                    columnName={columnName}
                    input={input}
                    isEditable={isEditable}
                    isNullable={isNullable}
                    valueObject={createUserAd}
                    listAllDomain={listAllDomain}
                    setDomain={setAdDomain}
                    domain={domain}
                    setValueObject={setCreateUserAd}
                    error={() => {}}
                    isSubmit={isSubmit}
                    isAd
                  />
                </Grid>
              )
            )}
          {!isStepChangeOU && propertyAD && (
            <Grid>
              <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'row' }}>
                <Grid>
                  <Typography sx={styleTitleHeadPageDescript}>{t('password')}</Typography>
                </Grid>
                <Grid sx={{ display: 'flex', alignItems: 'center' }}>
                  <Tooltip title={t('validateAdPassword')} placement="right">
                    <InfoOutlinedIcon color="disabled" />
                  </Tooltip>
                </Grid>
              </Box>
              <PasswordField
                defaultPassword={defaultPassword}
                setNewPassword={setNewPassword}
                newPassword={newPassword}
                newPasswordAtNextLog={newPasswordAtNextLog}
                setNewPasswordAtNextLog={setNewPasswordAtNextLog}
              />
              <Grid>
                <Grid item>
                  <Box sx={styledTitle}>
                    <Typography sx={styleTitleHeadPageDescript}>{t('addGroups')}</Typography>
                  </Box>
                </Grid>
                <Grid item>
                  <Autocomplete
                    multiple
                    limitTags={2}
                    sx={{ padding: '20px' }}
                    id="ad-groups"
                    data-testid="ad-groups"
                    options={optionsWithMandatory()}
                    getOptionLabel={({ name }) => name}
                    value={createUserAd.memberOf || ''}
                    onChange={handleSelectGroups}
                    renderOption={(props, option) => (
                      <Box
                        component="li"
                        data-testid={`ad-groups-option-${option.name}`}
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...props}
                      >
                        {option.name}
                      </Box>
                    )}
                    renderInput={(params) => (
                      <TextField
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...params}
                        variant="standard"
                        placeholder={t('groups')}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>
          )}
          {isStepChangeOU ? (
            <Grid>
              <BrowseOU AD={AD} setSelectedOU={setSelectedOU} isSelectDNUser={false} isForForm />
            </Grid>
          ) : null}
        </FormControl>
      </DialogContent>
      {isStepChangeOU ? (
        <Grid item xs={12}>
          <TextField sx={{ width: '100%' }} type="text" value={selectedOU} variant="standard" />
        </Grid>
      ) : null}
      <DialogActions sx={styleDialogActions}>
        <CustomizedButton
          datatestid="cancelCreateAdUser"
          sx={styleButton}
          onClick={handleClickCancelButton}
          text={t('cancel')}
        >
          {t('cancel')}
        </CustomizedButton>
        <CustomizedButton
          datatestid="saveCreateAdUser"
          sx={styleButton}
          onClick={handleSubmit}
          text={t('save')}
          disabled={
            !createUserAd.memberOf.length ||
            !createUserAd.mail?.split('@')[0].length > 0 ||
            complexityPassword(newPassword) < 70
          }
        >
          {t('save')}
        </CustomizedButton>
      </DialogActions>
    </Dialog>
  );
}
LdapcreateADUserPopup.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closePopup: PropTypes.func.isRequired,
  isStepChangeOU: PropTypes.bool.isRequired,
  setIsStepChangeOU: PropTypes.func.isRequired
};
