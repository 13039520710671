import { createSlice } from '@reduxjs/toolkit';
import { login, loginAD } from './userConnected';
import { loginAzure } from '../../services/authentication/AzureAD/authAzure';
import { updateAllAzureColumns } from './AzureColumns';
import {
  updateAzureUser,
  updateAzureUserAccount,
  createAzureUser,
  changePasswordAzureUser,
  deleteAzureUser
} from './AzureUsersList';
import { addJiraDeviceComment, assignReporterToJiraDevice } from './JiraDevices';
import { setLicensesAzure, deleteLicensesAzure } from './AzureLicenses';
import {
  assignUserToIntuneDevice,
  removeUserToIntuneDevice,
  rebootIntuneDevice,
  renameIntuneDeviceName
} from './IntuneDevices';

import { updateProvider, deleteProvider, createProvider } from './Providers';
import { enableDisableJiraUser, setAssignJiraGroup, removeJiraGroup } from './JiraUsers';
import {
  deleteAdUser,
  postOneAdUser,
  updateAdUser,
  enableDisableAdUser,
  resetUserPassword,
  assignGroupsAdUser,
  deleteGroupsAdUser,
  updateAdUserGroups,
  createAdUsersBulk,
  deleteAdUsersBulk,
  editAdUsersBulk
} from './AdUsers';
import {
  addAzureGroup,
  deleteAzureGroup,
  updateAzureGroups,
  assignAzureGroupsUser,
  removeAzureGroupsUser
} from './AzureGroups';
import { deleteAppList, createAppList, updateAppList } from './AppList';
import {
  createAuthGroups,
  createAuthPermissions,
  deleteAuthGroups,
  deleteAuthPermissions
} from './AuthGroups';
import { updateParameterById } from './Parameters';

import { removePermissionsHistory, addPermissionsHistory } from './PermissionsHistory';

export const initialState = {
  notificationsList: []
};

const Status = Object.freeze({
  Success: 'success',
  Warning: 'warning',
  Fail: 'error'
});

export const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    delNotification: (state) => {
      state.notificationsList.pop();
    },
    addNotification: (state, action) => {
      state.notificationsList.push({
        status: action.payload.status,
        key: action.payload.key
      });
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.fulfilled, (state, action) => {
        state.notificationsList.push({
          status: Status.Success,
          key: action.payload.key
        });
      })
      .addCase(login.rejected, (state, action) => {
        state.notificationsList.push({
          status: Status.Fail,
          key: action.error.message
        });
      });
    builder
      .addCase(deleteAdUser.fulfilled, (state, action) => {
        state.notificationsList.push({
          status: Status.Success,
          key: action.payload.key
        });
      })
      .addCase(deleteAdUser.rejected, (state, action) => {
        state.notificationsList.push({
          status: Status.Fail,
          key: action.error.message
        });
      });
    builder
      .addCase(enableDisableAdUser.fulfilled, (state, action) => {
        state.notificationsList.push({
          status: Status.Success,
          key: action.payload.key
        });
      })
      .addCase(enableDisableAdUser.rejected, (state, action) => {
        state.notificationsList.push({
          status: Status.Fail,
          key: action.error.message
        });
      });
    builder
      .addCase(loginAD.fulfilled, (state, action) => {
        state.notificationsList.push({
          status: Status.Success,
          key: action.payload.key
        });
      })
      .addCase(loginAD.rejected, (state, action) => {
        state.notificationsList.push({
          status: Status.Fail,
          key: action.error.message
        });
      });

    builder
      .addCase(loginAzure.fulfilled, (state, action) => {
        state.notificationsList.push({
          status: Status.Success,
          key: action.payload.key
        });
      })
      .addCase(loginAzure.rejected, (state, action) => {
        state.notificationsList.push({
          status: Status.Fail,
          key: action.error.message
        });
      });
    builder
      .addCase(updateAllAzureColumns.fulfilled, (state, action) => {
        state.notificationsList.push({
          status: Status.Success,
          key: action.payload.key
        });
      })
      .addCase(updateAllAzureColumns.rejected, (state, action) => {
        state.notificationsList.push({
          status: Status.Fail,
          key: action.error.message
        });
      });
    builder
      .addCase(updateAzureUser.fulfilled, (state, action) => {
        state.notificationsList.push({
          status: Status.Success,
          key: action.payload.key
        });
      })
      .addCase(updateAzureUser.rejected, (state, action) => {
        state.notificationsList.push({
          status: Status.Fail,
          key: action.error.message
        });
      });
    builder
      .addCase(changePasswordAzureUser.fulfilled, (state, action) => {
        state.notificationsList.push({
          status: Status.Success,
          key: action.payload.key
        });
      })
      .addCase(changePasswordAzureUser.rejected, (state, action) => {
        state.notificationsList.push({
          status: Status.Fail,
          key: action.error.message
        });
      });
    builder
      .addCase(updateAzureUserAccount.fulfilled, (state, action) => {
        state.notificationsList.push({
          status: Status.Success,
          key: action.payload.key
        });
      })
      .addCase(updateAzureUserAccount.rejected, (state, action) => {
        state.notificationsList.push({
          status: Status.Fail,
          key: action.error.message
        });
      });
    builder
      .addCase(deleteAzureUser.fulfilled, (state, action) => {
        state.notificationsList.push({
          status: Status.Success,
          key: action.payload.key
        });
      })
      .addCase(deleteAzureUser.rejected, (state, action) => {
        state.notificationsList.push({
          status: Status.Fail,
          key: action.error.message
        });
      });
    builder
      .addCase(createAzureUser.fulfilled, (state, action) => {
        state.notificationsList.push({
          status: Status.Success,
          key: action.payload.key
        });
      })
      .addCase(createAzureUser.rejected, (state, action) => {
        state.notificationsList.push({
          status: Status.Fail,
          key: action.error.message
        });
      });
    builder
      .addCase(setLicensesAzure.fulfilled, (state, action) => {
        const batchArray = action.payload.response;
        const badRequest = batchArray.filter((request) => request.status === 400);
        if (badRequest.length === batchArray.length) {
          state.notificationsList.push({
            status: Status.Fail,
            key: 'assignLicenses.fail'
          });
        } else if (badRequest.length > 0) {
          state.notificationsList.push({
            status: Status.Warning,
            key: 'assignLicenses.warning'
          });
        } else {
          state.notificationsList.push({
            status: Status.Success,
            key: 'assignLicenses.success'
          });
        }
      })
      .addCase(setLicensesAzure.rejected, (state, action) => {
        state.notificationsList.push({
          status: Status.Fail,
          key: action.error.message
        });
      });
    builder
      .addCase(deleteLicensesAzure.fulfilled, (state, action) => {
        const batchArray = action.payload.response;
        const badRequest = batchArray.filter((request) => request.status === 400);
        if (badRequest.length === batchArray.length) {
          state.notificationsList.push({
            status: Status.Fail,
            key: 'RemoveLicenses.fail'
          });
        } else if (badRequest.length > 0) {
          state.notificationsList.push({
            status: Status.Warning,
            key: 'RemoveLicenses.warning'
          });
        } else {
          state.notificationsList.push({
            status: Status.Success,
            key: 'RemoveLicenses.success'
          });
        }
      })
      .addCase(deleteLicensesAzure.rejected, (state, action) => {
        state.notificationsList.push({
          status: Status.Fail,
          key: action.error.message
        });
      });
    builder
      .addCase(assignUserToIntuneDevice.fulfilled, (state, action) => {
        state.notificationsList.push({
          status: Status.Success,
          key: action.payload.key
        });
      })
      .addCase(assignUserToIntuneDevice.rejected, (state, action) => {
        state.notificationsList.push({
          status: Status.Fail,
          key: action.error.message
        });
      });
    builder
      .addCase(removeUserToIntuneDevice.fulfilled, (state, action) => {
        state.notificationsList.push({
          status: Status.Success,
          key: action.payload.key
        });
      })
      .addCase(removeUserToIntuneDevice.rejected, (state, action) => {
        state.notificationsList.push({
          status: Status.Fail,
          key: action.error.message
        });
      });
    builder
      .addCase(rebootIntuneDevice.fulfilled, (state, action) => {
        state.notificationsList.push({
          status: Status.Success,
          key: action.payload.key
        });
      })
      .addCase(rebootIntuneDevice.rejected, (state, action) => {
        state.notificationsList.push({
          status: Status.Fail,
          key: action.error.message
        });
      });
    builder
      .addCase(renameIntuneDeviceName.fulfilled, (state, action) => {
        state.notificationsList.push({
          status: Status.Success,
          key: action.payload.key
        });
      })
      .addCase(renameIntuneDeviceName.rejected, (state, action) => {
        state.notificationsList.push({
          status: Status.Fail,
          key: action.error.message
        });
      });
    builder
      .addCase(addJiraDeviceComment.fulfilled, (state, action) => {
        state.notificationsList.push({
          status: Status.Success,
          key: action.payload.key
        });
      })
      .addCase(addJiraDeviceComment.rejected, (state, action) => {
        state.notificationsList.push({
          status: Status.Fail,
          key: action.error.message
        });
      });
    builder
      .addCase(assignReporterToJiraDevice.fulfilled, (state, action) => {
        state.notificationsList.push({
          status: Status.Success,
          key: action.payload.key
        });
      })
      .addCase(assignReporterToJiraDevice.rejected, (state, action) => {
        state.notificationsList.push({
          status: Status.Fail,
          key: action.error.message
        });
      });
    builder
      .addCase(enableDisableJiraUser.fulfilled, (state, action) => {
        state.notificationsList.push({
          status: Status.Success,
          key: action.payload.key
        });
      })
      .addCase(enableDisableJiraUser.rejected, (state, action) => {
        state.notificationsList.push({
          status: Status.Fail,
          key: action.error.message
        });
      });
    builder
      .addCase(setAssignJiraGroup.fulfilled, (state, action) => {
        state.notificationsList.push({
          status: Status.Success,
          key: action.payload.key
        });
      })
      .addCase(setAssignJiraGroup.rejected, (state, action) => {
        state.notificationsList.push({
          status: Status.Fail,
          key: action.error.message
        });
      });
    builder
      .addCase(removeJiraGroup.fulfilled, (state, action) => {
        state.notificationsList.push({
          status: Status.Success,
          key: action.payload.key
        });
      })
      .addCase(removeJiraGroup.rejected, (state, action) => {
        state.notificationsList.push({
          status: Status.Fail,
          key: action.error.message
        });
      });
    builder
      .addCase(updateAdUser.fulfilled, (state, action) => {
        state.notificationsList.push({
          status: Status.Success,
          key: action.payload.key
        });
      })
      .addCase(updateAdUser.rejected, (state, action) => {
        state.notificationsList.push({
          status: Status.Fail,
          key: action.error.message
        });
      });
    builder
      .addCase(updateAdUserGroups.fulfilled, (state, action) => {
        state.notificationsList.push({
          status: Status.Success,
          key: action.payload.key
        });
      })
      .addCase(updateAdUserGroups.rejected, (state, action) => {
        state.notificationsList.push({
          status: Status.Fail,
          key: action.error.message
        });
      });
    builder
      .addCase(postOneAdUser.fulfilled, (state, action) => {
        state.notificationsList.push({
          status: Status.Success,
          key: action.payload.message
        });
      })
      .addCase(postOneAdUser.rejected, (state, action) => {
        state.notificationsList.push({
          status: Status.Fail,
          key: action.error.message
        });
      });
    builder
      .addCase(resetUserPassword.fulfilled, (state, action) => {
        state.notificationsList.push({
          status: Status.Success,
          key: action.payload.message
        });
      })
      .addCase(resetUserPassword.rejected, (state, action) => {
        state.notificationsList.push({
          status: Status.Fail,
          key: action.error.message
        });
      });

    builder
      .addCase(assignGroupsAdUser.fulfilled, (state, action) => {
        state.notificationsList.push({
          status: Status.Success,
          key: action.payload.key
        });
      })
      .addCase(assignGroupsAdUser.rejected, (state, action) => {
        state.notificationsList.push({
          status: Status.Fail,
          key: action.error.message
        });
      });

    builder
      .addCase(deleteGroupsAdUser.fulfilled, (state, action) => {
        state.notificationsList.push({
          status: Status.Success,
          key: action.payload.key
        });
      })
      .addCase(deleteGroupsAdUser.rejected, (state, action) => {
        state.notificationsList.push({
          status: Status.Fail,
          key: action.error.message
        });
      });
    builder
      .addCase(updateProvider.fulfilled, (state, action) => {
        state.notificationsList.push({
          status: Status.Success,
          key: action.payload.key
        });
      })
      .addCase(updateProvider.rejected, (state, action) => {
        state.notificationsList.push({
          status: Status.Fail,
          key: action.error.message
        });
      });
    builder
      .addCase(deleteProvider.fulfilled, (state, action) => {
        state.notificationsList.push({
          status: Status.Success,
          key: action.payload.key
        });
      })
      .addCase(deleteProvider.rejected, (state, action) => {
        state.notificationsList.push({
          status: Status.Fail,
          key: action.error.message
        });
      });
    builder
      .addCase(createProvider.fulfilled, (state, action) => {
        state.notificationsList.push({
          status: Status.Success,
          key: action.payload.key
        });
      })
      .addCase(createProvider.rejected, (state, action) => {
        state.notificationsList.push({
          status: Status.Fail,
          key: action.error.message
        });
      });
    builder
      .addCase(updateAzureGroups.fulfilled, (state, action) => {
        state.notificationsList.push({
          status: Status.Success,
          key: action.payload.key
        });
      })
      .addCase(updateAzureGroups.rejected, (state, action) => {
        state.notificationsList.push({
          status: Status.Fail,
          key: action.error.message
        });
      });

    builder
      .addCase(addAzureGroup.fulfilled, (state, action) => {
        state.notificationsList.push({
          status: Status.Success,
          key: action.payload.key
        });
      })
      .addCase(addAzureGroup.rejected, (state, action) => {
        state.notificationsList.push({
          status: Status.Fail,
          key: action.error.message
        });
      });

    builder
      .addCase(createAppList.fulfilled, (state, action) => {
        state.notificationsList.push({
          status: Status.Success,
          key: action.payload.key
        });
      })
      .addCase(createAppList.rejected, (state, action) => {
        state.notificationsList.push({
          status: Status.Fail,
          key: action.error.message
        });
      });

    builder
      .addCase(deleteAzureGroup.fulfilled, (state, action) => {
        state.notificationsList.push({
          status: Status.Success,
          key: action.payload.key
        });
      })
      .addCase(deleteAzureGroup.rejected, (state, action) => {
        state.notificationsList.push({
          status: Status.Fail,
          key: action.error.message
        });
      });

    builder
      .addCase(deleteAppList.fulfilled, (state, action) => {
        state.notificationsList.push({
          status: Status.Success,
          key: action.payload.key
        });
      })
      .addCase(deleteAppList.rejected, (state, action) => {
        state.notificationsList.push({
          status: Status.Fail,
          key: action.error.message
        });
      });

    builder
      .addCase(createAdUsersBulk.fulfilled, (state, action) => {
        state.notificationsList.push({
          status: Status.Success,
          key: action.payload.key
        });
      })
      .addCase(createAdUsersBulk.rejected, (state, action) => {
        state.notificationsList.push({
          status: Status.Fail,
          key: action.error.message
        });
      });

    builder
      .addCase(deleteAdUsersBulk.fulfilled, (state, action) => {
        state.notificationsList.push({
          status: Status.Success,
          key: action.payload.key
        });
      })
      .addCase(deleteAdUsersBulk.rejected, (state, action) => {
        state.notificationsList.push({
          status: Status.Fail,
          key: action.error.message
        });
      });

    builder
      .addCase(updateAppList.fulfilled, (state, action) => {
        state.notificationsList.push({
          status: Status.Success,
          key: action.payload.key
        });
      })
      .addCase(updateAppList.rejected, (state, action) => {
        state.notificationsList.push({
          status: Status.Fail,
          key: action.error.message
        });
      });

    builder
      .addCase(createAuthPermissions.fulfilled, (state, action) => {
        state.notificationsList.push({
          status: Status.Success,
          key: action.payload.key
        });
      })
      .addCase(createAuthPermissions.rejected, (state, action) => {
        state.notificationsList.push({
          status: Status.Fail,
          key: action.error.message
        });
      });

    builder
      .addCase(deleteAuthPermissions.fulfilled, (state, action) => {
        state.notificationsList.push({
          status: Status.Success,
          key: action.payload.key
        });
      })
      .addCase(deleteAuthPermissions.rejected, (state, action) => {
        state.notificationsList.push({
          status: Status.Fail,
          key: action.error.message
        });
      });

    builder
      .addCase(deleteAuthGroups.fulfilled, (state, action) => {
        state.notificationsList.push({
          status: Status.Success,
          key: action.payload.key
        });
      })
      .addCase(deleteAuthGroups.rejected, (state, action) => {
        state.notificationsList.push({
          status: Status.Fail,
          key: action.error.message
        });
      });

    builder
      .addCase(createAuthGroups.fulfilled, (state, action) => {
        state.notificationsList.push({
          status: Status.Success,
          key: action.payload.key
        });
      })
      .addCase(createAuthGroups.rejected, (state, action) => {
        state.notificationsList.push({
          status: Status.Fail,
          key: action.error.message
        });
      });

    builder
      .addCase(editAdUsersBulk.fulfilled, (state, action) => {
        state.notificationsList.push({
          status: Status.Success,
          key: action.payload.key
        });
      })
      .addCase(editAdUsersBulk.rejected, (state, action) => {
        state.notificationsList.push({
          status: Status.Fail,
          key: action.error.message
        });
      });

    builder
      .addCase(assignAzureGroupsUser.fulfilled, (state, action) => {
        state.notificationsList.push({
          status: Status.Success,
          key: action.payload.key
        });
      })

      .addCase(assignAzureGroupsUser.rejected, (state, action) => {
        state.notificationsList.push({
          status: Status.Fail,
          key: action.error.message
        });
      });

    builder
      .addCase(removeAzureGroupsUser.fulfilled, (state, action) => {
        state.notificationsList.push({
          status: Status.Success,
          key: action.payload.key
        });
      })
      .addCase(removeAzureGroupsUser.rejected, (state, action) => {
        state.notificationsList.push({
          status: Status.Fail,
          key: action.error.message
        });
      });

    builder
      .addCase(removePermissionsHistory.fulfilled, (state, action) => {
        state.notificationsList.push({
          status: Status.Success,
          key: action.payload.key
        });
      })
      .addCase(removePermissionsHistory.rejected, (state, action) => {
        state.notificationsList.push({
          status: Status.Fail,
          key: action.error.message
        });
      });

    builder
      .addCase(addPermissionsHistory.fulfilled, (state, action) => {
        state.notificationsList.push({
          status: Status.Success,
          key: action.payload.key
        });
      })
      .addCase(addPermissionsHistory.rejected, (state, action) => {
        state.notificationsList.push({
          status: Status.Fail,
          key: action.error.message
        });
      });
    builder
      .addCase(updateParameterById.fulfilled, (state, action) => {
        state.notificationsList.push({
          status: Status.Success,
          key: action.payload.key
        });
      })

      .addCase(updateParameterById.rejected, (state, action) => {
        state.notificationsList.push({
          status: Status.Fail,
          key: action.error.message
        });
      });
  }
});

export const { delNotification, addNotification } = notificationsSlice.actions;

export default notificationsSlice.reducer;

export const getNotifications = (state) => state.notifications.notificationsList;
