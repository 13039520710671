import React from 'react';
import { Dialog } from '@mui/material';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import AzureRemoveLicenses from '../azureLicenses/AzureRemoveLicenses';
import AzureSetLicenses from '../azureLicenses/AzureSetLicenses';
import { deleteLicensesAzure, setLicensesAzure } from '../../redux/slices/AzureLicenses';
import styleScrollbar from '../../assets/style/utils/utils';

export default function AzureLicensesPopup({
  selectedUsers,
  isRemove,
  popup,
  closePopup,
  userLicensesList,
  resetSelectedRows
}) {
  const dispatch = useDispatch();

  const handleAssignLicense = async (selectedLicenses, unassignServiceMap) => {
    const licenses = selectedLicenses.map((license) => {
      let unassignServices = unassignServiceMap.get(license);
      if (unassignServices === undefined) unassignServices = [];
      return {
        skuId: license,
        disabledPlans: unassignServices
      };
    });
    await dispatch(
      setLicensesAzure({
        users: selectedUsers,
        licenses
      })
    );
    resetSelectedRows();
    return closePopup();
  };

  const handleRemoveLicense = async (licenses) => {
    await dispatch(
      deleteLicensesAzure({
        users: selectedUsers,
        removeLicenses: licenses
      })
    );
    resetSelectedRows();
    return closePopup();
  };

  return (
    <Dialog
      onClose={closePopup}
      open={popup}
      fullWidth
      maxWidth="xl"
      PaperProps={{ sx: styleScrollbar }}
    >
      {isRemove ? (
        <AzureRemoveLicenses
          removeLicense={handleRemoveLicense}
          closePopup={closePopup}
          userLicensesList={userLicensesList}
        />
      ) : (
        <AzureSetLicenses
          closePopup={closePopup}
          assignLicense={handleAssignLicense}
          numberOfUsers={selectedUsers.length}
        />
      )}
    </Dialog>
  );
}

AzureLicensesPopup.propTypes = {
  popup: PropTypes.bool.isRequired,
  closePopup: PropTypes.func.isRequired,
  selectedUsers: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number]))
    .isRequired,
  isRemove: PropTypes.bool,
  userLicensesList: PropTypes.arrayOf(PropTypes.string),
  resetSelectedRows: PropTypes.func
};
AzureLicensesPopup.defaultProps = {
  isRemove: false,
  userLicensesList: [],
  resetSelectedRows: () => {}
};
