import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import {
  getDisableText,
  getOneAdUser,
  selectIsAdUserLoading,
  selectedUser
} from '../../../redux/slices/AdUsers';
import LoadingWrapper from '../../../components/layout/LoadingWrapper';
import AdUserDescription from '../../../components/adUser/AdUserDescription';
import UserAdGroupDescription from '../../../components/user/UserAdGroupsDescription';
import HeaderPage from '../../../components/HeaderPage';

export default function AdUser({ DN }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isAdUserLoading = useSelector(selectIsAdUserLoading);

  const user = useSelector(selectedUser);

  const dispatchGetOneAdUser = async () => {
    dispatch(getOneAdUser(DN));
  };

  useEffect(() => {
    dispatch(getDisableText());
  }, [dispatch]);

  useEffect(() => {
    if (DN !== null) dispatchGetOneAdUser();
  }, [dispatch, DN]);

  const navigate = useNavigate();

  const headerPageConfig = {
    text: {
      titleText: t('AdEntity'),
      bigTitleText: `${user?.name}`,
      selectedItemsText: '',
      tagId: 'AdUser'
    },
    buttonReturn: {
      isDisplayable: true,
      onClick: () => navigate('/adUsers')
    }
  };

  return (
    <LoadingWrapper isChildDisplayable={!isAdUserLoading}>
      {user.distinguishedName ? (
        <Grid>
          <HeaderPage headerPageConfig={headerPageConfig} />
          <Grid sx={{ width: '100%', marginTop: '20px' }}>
            <AdUserDescription />
            <UserAdGroupDescription />
          </Grid>
        </Grid>
      ) : null}
    </LoadingWrapper>
  );
}

AdUser.propTypes = {
  DN: PropTypes.string
};

AdUser.defaultProps = {
  DN: null
};
