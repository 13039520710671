import axios from 'axios';
import API_URL from '../../constants';

const NotificationAdminService = {
  requestLicenses(page, rowsPerPage) {
    return axios.get(`${API_URL}/notifications/licensePerPage/${page}/${rowsPerPage}`);
  },
  setLicenseRead(id) {
    return axios.get(`${API_URL}/notifications/licenseRead/${id}`);
  }
};
export default NotificationAdminService;
