import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Grid, useTheme, Tooltip, Input } from '@mui/material';
import PropTypes from 'prop-types';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useTranslation } from 'react-i18next';

import DataList from '../datalist/DataList';
import {
  styleGridUsers,
  styleToolbar,
  styleSearchBar,
  styleMarginBottom
} from '../../assets/style/views/UserPages';
import { selectJiraGroups, selectGroupsIsLoading } from '../../redux/slices/JiraUsers';
import { formatColumnsForTable } from '../datalist/ComponentsInTable';
import CustomizedPagination from '../datalist/TablePagination';

export default function JiraGroupsTab({
  page,
  setPage,
  checkboxKey,
  setCheckBoxArray,
  activeCheckBox
}) {
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState('');

  const { t } = useTranslation();
  const theme = useTheme();
  const [totalItems, setTotalItems] = useState(0);

  const isLoading = useSelector(selectGroupsIsLoading);
  const allJiraGroups = useSelector(selectJiraGroups(''));
  const columns = ['name'];
  const handleChangePage = (next, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
  };
  const handleSearchTerm = (e) => {
    const { value } = e.target;
    setSearchTerm(() => value);
    setPage(0);
  };
  const setTableSelectedRowsRealData = (rows) => {
    const copyRows = [...rows];
    const tempRows = copyRows.map(
      (rowIndex) => allJiraGroups[rowIndex === null ? 0 : rowIndex]?.id
    );
    setCheckBoxArray(tempRows);
  };

  return (
    <Grid container sx={styleGridUsers}>
      <Grid container item md={6} sm={12} xs={12} sx={[styleMarginBottom, styleSearchBar]}>
        <Input
          id="jiraGroupsSearchBar"
          type="text"
          name="searchBar"
          placeholder={t('searchJiraGroups')}
          onChange={handleSearchTerm}
          data-testid="searchJiraGroupsId"
          sx={{
            '&:after ': {
              borderBottom: `2px solid ${theme.palette.colorMain}`
            }
          }}
        />
        <Tooltip title={t('infoSearchBarJiraGroup')} placement="bottom-start">
          <InfoOutlinedIcon />
        </Tooltip>
      </Grid>
      <DataList
        columns={formatColumnsForTable(
          columns,
          allJiraGroups,
          [{ name: 'name', display: true, filter: true }],
          t
        )}
        rows={allJiraGroups}
        page={page}
        rowsPerPage={rowsPerPage}
        columnDisplayedInCollapse={columns[0]}
        activeCheckBox={activeCheckBox}
        checkboxKey={checkboxKey}
        isTableLoading={isLoading}
        setTableSelectedRows={setTableSelectedRowsRealData}
        data-testid="datalistJiraGroups"
        isDisplayable={!isLoading}
        searchTerm={searchTerm}
        setTotalItems={setTotalItems}
      />
      <CustomizedPagination
        count={totalItems}
        rowsPerPage={rowsPerPage}
        page={page}
        setPage={setPage}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        sx={styleToolbar}
      />
    </Grid>
  );
}

JiraGroupsTab.propTypes = {
  page: PropTypes.number.isRequired,
  setPage: PropTypes.func.isRequired,
  setCheckBoxArray: PropTypes.func,
  checkboxKey: PropTypes.string,
  activeCheckBox: PropTypes.bool
};
JiraGroupsTab.defaultProps = {
  setCheckBoxArray: undefined,
  checkboxKey: undefined,
  activeCheckBox: false
};
