import axios from 'axios';
import API_URL from '../../constants';

const ProvidersService = {
  getProviders() {
    return axios.get(`${API_URL}/providers`);
  },
  getProvider(id) {
    return axios.get(`${API_URL}/provider/${id}`);
  },
  deleteProvider(id) {
    return axios.delete(`${API_URL}/provider/${id}`);
  },
  updateProvider(id, body) {
    return axios.put(`${API_URL}/provider/${id}`, body);
  },
  createProvider(providerNameId, body, isAuth) {
    return axios.post(`${API_URL}/provider/${providerNameId}`, {
      providerAuth: body,
      isAuth
    });
  },
  getProvidersNames() {
    return axios.get(`${API_URL}/providersNames`);
  }
};

export default ProvidersService;
