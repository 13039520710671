import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import StandardBox from '../StandardBox';
import InformationList from '../InformationList';
import { selectedUser, enableDisableJiraUser } from '../../redux/slices/JiraUsers';
import hasPermissions from '../../utils/Permissions/permissions';
import { selectPermissions } from '../../redux/slices/userConnected';

export default function JiraUserDescription() {
  const user = useSelector(selectedUser);
  const [displayColumns, setDisplayColumns] = useState([]);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const permissions = useSelector(selectPermissions);

  useEffect(() => {
    if (Object.keys(user).length !== 0) {
      const columnsDisplay = Object.keys(user)
        .filter((key) => key !== 'key' && key !== 'groups')
        .map((key) => {
          switch (key) {
            case 'active':
            case 'deleted':
              return { name: key, key, type: 'icon' };
            default:
              return { name: key, key, type: 'string' };
          }
        });
      setDisplayColumns(columnsDisplay);
    }
  }, [user]);

  const listButton = [
    {
      hasPermissions: hasPermissions(permissions, 'SET_JIRA_USER_ENABLE'),
      disabled: !hasPermissions(permissions, 'SET_JIRA_USER_ENABLE'),
      text: user.active === true ? `${t('disable')}` : `${t('enable')}`,
      datatestid: 'enableDisableJiraDevice',
      onClick: () => dispatch(enableDisableJiraUser(user.key))
    }
  ];

  return (
    <Grid>
      <StandardBox
        title="Jira"
        buttons={listButton}
        ChildComponent={<InformationList row={user} columns={displayColumns} />}
      />
    </Grid>
  );
}
