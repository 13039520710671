const styleScrollbar = (theme) => ({
  '::-webkit-scrollbar': {
    width: '8px',
    height: '8px'
  },
  '::-webkit-scrollbar-track': {
    background: theme.palette.colorMainHover,
    borderRadius: '5px'
  },
  '::-webkit-scrollbar-thumb': {
    background: theme.palette.colorMainDark,
    borderRadius: '5px'
  },
  '::-webkit-scrollbar-thumb:hover': {
    background: theme.palette.colorMain
  },
  '::-webkit-scrollbar-corner': {
    background: 'transparent'
  }
});

export default styleScrollbar;
