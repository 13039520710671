import React from 'react';
import PropTypes from 'prop-types';
import { Select, TablePagination, MenuItem, Grid, FormControl, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  styleFormControl,
  styleToolbar,
  styleToolbarMobile,
  styledPaginationDesktop,
  typographyPaginationDesktop
} from '../../assets/style/views/UserPages';
import fillMenuItemList from '../../utils/Pagination/fillSelect';

export default function CustomizedPagination({
  page,
  setPage,
  count,
  rowsPerPage,
  onPageChange,
  onRowsPerPageChange,
  isMobile
}) {
  const { t } = useTranslation();
  const menuItems = fillMenuItemList(Math.ceil(count / rowsPerPage));
  const handleJumpPageChange = (event) => {
    const newPage = parseInt(event.target.value, 10);
    if (newPage > 0 && newPage <= Math.ceil(count / rowsPerPage)) {
      setPage(newPage);
    }
  };
  const handlePageChange = (event) => {
    setPage(event.target.value);
  };
  const labelPaginationDisplay = () => {
    const from = page * rowsPerPage;
    const to = from + rowsPerPage;
    return `${from}–${to} ${t('paginationPageLabel')} ${count}`;
  };

  const menuJumpPageButton = (
    <FormControl size="small" sx={styleFormControl}>
      <Select value={page} onChange={handlePageChange} onBlur={handleJumpPageChange}>
        {menuItems.map((menuItem) => (
          <MenuItem value={menuItem} key={menuItem}>
            {(menuItem + 1).toString()}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );

  return (
    <Grid sx={styledPaginationDesktop}>
      {!isMobile ? (
        <>
          <Typography sx={typographyPaginationDesktop}>{t('paginationJump')}</Typography>
          {menuJumpPageButton}
        </>
      ) : null}

      <TablePagination
        rowsPerPageOptions={[10, 25, 50]}
        component="div"
        count={count}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={onPageChange}
        onRowsPerPageChange={onRowsPerPageChange}
        labelRowsPerPage={isMobile ? '' : t('paginationLabel')}
        labelDisplayedRows={labelPaginationDisplay}
        sx={!isMobile ? styleToolbar : styleToolbarMobile}
      />
      {!isMobile ? null : menuJumpPageButton}
    </Grid>
  );
}

CustomizedPagination.propTypes = {
  page: PropTypes.number.isRequired,
  setPage: PropTypes.func.isRequired,
  count: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  onRowsPerPageChange: PropTypes.func.isRequired,
  isMobile: PropTypes.bool
};

CustomizedPagination.defaultProps = {
  isMobile: false
};
