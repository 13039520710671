const selectAdUserWithAzureUsers = (store) => {
  const AdUsers = store?.adUsers?.adUsers;
  const AzureList = store?.azureUsers?.azureUsers;

  const mergedListMatchingUser = [];
  const unmatchingAzureUsers = [];

  // eslint-disable-next-line no-restricted-syntax
  for (const azureUser of AzureList) {
    const matchingAdUser = AdUsers.find(
      (adUser) => adUser?.distinguishedName === azureUser?.onPremisesDistinguishedName
    );

    if (azureUser?.onPremisesDistinguishedName) {
      const mergedUser = {
        ...matchingAdUser,
        idAzure: azureUser?.id,
        userPrincipalNameSynchro: azureUser?.userPrincipalName,
        accountEnabledAzure: azureUser?.accountEnabled,
        isSynchronize: 'synchronized',
        onPremisesDistinguishedNameAzure: azureUser?.onPremisesDistinguishedName,
        onPremisesLastSyncDateTimeAzure: `${new Date(
          azureUser?.onPremisesLastSyncDateTime
        ).toLocaleDateString()} ${new Date(
          azureUser?.onPremisesLastSyncDateTime
        ).toLocaleTimeString()}`,
        businessPhonesAzure: azureUser?.businessPhones,
        memberOfAzure: azureUser?.memberOf,
        departmentAzure: azureUser?.department,
        displayNameAzure: azureUser?.displayName,
        employeeIdAzure: azureUser?.employeeId,
        givenNameAzure: azureUser?.givenName,
        jobTitleAzure: azureUser?.jobTitle,
        mailAzure: azureUser?.mail,
        mailNicknameAzure: azureUser?.mailNickname,
        mobilePhoneAzure: azureUser?.mobilePhone,
        officeLocationAzure: azureUser?.officeLocation,
        preferredLanguageAzure: azureUser?.preferredLanguage,
        surnameAzure: azureUser?.surname,
        usageLocationAzure: azureUser?.usageLocation,
        userPrincipalNameAzure: azureUser?.userPrincipalName
      };
      mergedListMatchingUser.push(mergedUser);
    } else {
      unmatchingAzureUsers.push({
        idAzure: azureUser?.id,
        userPrincipalNameSynchro: azureUser?.userPrincipalName,
        isSynchronize: 'noAd',
        onPremisesDistinguishedNameAzure: azureUser?.onPremisesDistinguishedName,
        onPremisesLastSyncDateTimeAzure: azureUser?.onPremisesLastSyncDateTime,
        accountEnabledAzure: azureUser?.accountEnabled,
        businessPhonesAzure: azureUser?.businessPhones,
        memberOfAzure: azureUser?.memberOf,
        departmentAzure: azureUser?.department,
        displayNameAzure: azureUser?.displayName,
        employeeIdAzure: azureUser?.employeeId,
        givenNameAzure: azureUser?.givenName,
        jobTitleAzure: azureUser?.jobTitle,
        mailAzure: azureUser?.mail,
        mailNicknameAzure: azureUser?.mailNickname,
        mobilePhoneAzure: azureUser?.mobilePhone,
        officeLocationAzure: azureUser?.officeLocation,
        preferredLanguageAzure: azureUser?.preferredLanguage,
        surnameAzure: azureUser?.surname,
        usageLocationAzure: azureUser?.usageLocation,
        userPrincipalNameAzure: azureUser?.userPrincipalName
      });
    }
  }

  const unmatchingAdUsers = AdUsers.filter(
    (adUser) =>
      !mergedListMatchingUser.some((user) => user.userPrincipalName === adUser.userPrincipalName)
  );

  const data = [
    ...mergedListMatchingUser,
    ...unmatchingAzureUsers,
    ...unmatchingAdUsers.map((adUser) => ({
      ...adUser,
      idAzure: undefined,
      userPrincipalNameSynchro: adUser?.userPrincipalName,
      isSynchronize: 'noAzure',
      accountEnabledAzure: undefined,
      businessPhonesAzure: undefined,
      memberOfAzure: [],
      departmentAzure: undefined,
      displayNameAzure: undefined,
      employeeIdAzure: undefined,
      givenNameAzure: undefined,
      jobTitleAzure: undefined,
      mailAzure: undefined,
      mailNicknameAzure: undefined,
      mobilePhoneAzure: undefined,
      officeLocationAzure: undefined,
      preferredLanguageAzure: undefined,
      surnameAzure: undefined,
      usageLocationAzure: undefined,
      userPrincipalNameAzure: undefined
    }))
  ];

  return data;
};

export default selectAdUserWithAzureUsers;
