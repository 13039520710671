import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Grid } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';

import { getAllAzureColumns } from '../../../redux/slices/AzureColumns';
import UserStandardDescription from '../../../components/user/UserStandardDescription';
import UserLicensesDescription from '../../../components/user/UserLicensesDescription';
import {
  getAzureUser,
  getAzureUserAvailableServices,
  selectedUser,
  resetSelectedUser,
  requestAzureUserDomains,
  findAzureUsers,
  resetAzureUserState,
  selectIsUserLoaded
} from '../../../redux/slices/AzureUsersList';
import { getLicensesAzure } from '../../../redux/slices/AzureLicenses';
import errorRedirection from '../../../utils/errorRedirection';
import LoadingWrapper from '../../../components/layout/LoadingWrapper';
import HeaderPage from '../../../components/HeaderPage';
import UserAzureGroupDescription from '../../../components/user/UserAzureGroupsDescription';

export default function User() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isUserLoaded = useSelector(selectIsUserLoaded);
  const user = useSelector(selectedUser);
  const { id } = useParams();
  const navigate = useNavigate();

  const dispatchGetAzureUserAndColumns = async () => {
    try {
      await dispatch(getAzureUser(id)).unwrap();
      await dispatch(getAllAzureColumns()).unwrap();
    } catch (error) {
      errorRedirection(error.code, navigate);
    }
  };

  const headerPageConfig = {
    text: {
      titleText: t('azureUser'),
      bigTitleText: `${user.displayName}`,
      selectedItemsText: '',
      tagId: 'titleHeadPage'
    },
    buttonReturn: {
      isDisplayable: true,
      onClick: () => navigate('/azureUsers')
    },
    isPositionFixed: true
  };

  useEffect(() => {
    dispatchGetAzureUserAndColumns();
    dispatch(getLicensesAzure());
    dispatch(requestAzureUserDomains());
    dispatch(getAzureUserAvailableServices());
    dispatch(findAzureUsers());
    return function cleanup() {
      dispatch(resetSelectedUser());
      dispatch(resetAzureUserState());
    };
  }, [dispatch]);

  return (
    <LoadingWrapper isChildDisplayable={isUserLoaded}>
      <HeaderPage headerPageConfig={headerPageConfig} />
      <Grid sx={{ width: '100%' }} marginTop={{ xs: '100px', sm: '130px', md: '175px' }}>
        <UserStandardDescription />
        <UserAzureGroupDescription />
        <UserLicensesDescription />
      </Grid>
    </LoadingWrapper>
  );
}
